import { useIntl } from 'react-intl';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { useContext } from 'react';
import { UtilityContext } from '../../providers/UtilityProvider';

const EmojiPickerComponent = ({ emojiClickCallBack }) => {
  const { theme } = useContext(UtilityContext);
  return (
    <div>
      <Picker
        theme={theme ? 'dark' : 'light'}
        locale='tr'
        previewPosition='none'
        data={data}
        onEmojiSelect={emojiClickCallBack}
      />
    </div>
  );
};

export default EmojiPickerComponent;
