import React, { useContext, useState } from 'react';
import { Badge, Calendar, Drawer, Tooltip, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import ListUsers from '../feed/ListUsers';
import UserEvents from '../events/UserEvents';
import EventDetail from '../events/EventDetail';
import useWindowSize from '../../hooks/useWindowSize';
import { UtilityContext } from '../../providers/UtilityProvider';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;

const getMonthData = (value, eventData, type = 'year') => {
  let month = value.month() + 1;
  let day = value.date();
  if (type === 'year') {
    for (let data of eventData) {
      if (data.month === month) {
        return data.event;
      }
    }
  } else if (type === 'month') {
    for (let data of eventData) {
      if (data.day === day && data.month === month) {
        return data.event;
      }
    }
  }
};

const CalendarWithNotes = ({
  calendarMode = 'month',
  customHeader,
  yearDisplayData,
  onPanelChange,
  pureEventData,
}) => {
  const { theme } = useContext(UtilityContext);
  const { width } = useWindowSize();
  const [eventDetailDrawer, setEventDetailDrawer] = useState(null);
  const translator = useIntl();

  const getStatusOneByOne = (index) => {
    const statusColors = [
      'pink',
      'red',
      'yellow',
      'orange',
      'cyan',
      'green',
      'blue',
      'purple',
      'geekblue',
      'magenta',
      'volcano',
      'gold',
      'lime',
    ];

    index = index % statusColors.length;

    if (index < 0) {
      index = statusColors.length + index;
    }

    return statusColors[index];
  };

  const yearDisplayRender = (value) => {
    const event = getMonthData(value, yearDisplayData, 'year');
    if (event?.length > 0) {
      return (
        <div className='notes-month '>
          {event.map(({ name, id }, index) => (
            <Tooltip key={index} placement='top' title={name}>
              <Badge
                onClick={() => setEventDetailDrawer(pureEventData.find((data) => data.id === id))}
                style={{ height: 8, width: 8 }}
                color={getStatusOneByOne(index)}
              />
            </Tooltip>
          ))}
        </div>
      );
    }
  };

  const dateCellRender = (value) => {
    const event = getMonthData(value, yearDisplayData, 'month');
    return (
      <div className='notes-month'>
        {event?.map(({ name, id }, index) => (
          <Tooltip key={index} placement='top' title={name}>
            <Badge
              onClick={() => setEventDetailDrawer(pureEventData.find((data) => data.id === id))}
              style={{ height: 8, width: 8 }}
              color={getStatusOneByOne(index)}
            />
          </Tooltip>
        ))}
      </div>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return yearDisplayRender(current);
    return info.originNode;
  };

  return (
    <div>
      <Calendar
        onPanelChange={onPanelChange}
        mode={calendarMode}
        headerRender={
          customHeader
            ? () => (
                <Text className='text-center '>
                  <FormattedMessage id={customHeader} />
                </Text>
              )
            : null
        }
        cellRender={cellRender}
      />
      <Drawer
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        width={width < 480 ? '100vw' : 440}
        destroyOnClose={true}
        onClose={() => setEventDetailDrawer(null)}
        open={!!eventDetailDrawer}
        placement={width < 480 ? 'bottom' : 'right'}
        title={translator.formatMessage({ id: 'eventDetail' })}
      >
        <EventDetail isDrawer={true} detail={eventDetailDrawer} />
      </Drawer>
    </div>
  );
};
export default CalendarWithNotes;
