import { Col, Divider, Flex, Image, Row, Typography } from 'antd';
import { InlineEmojiInput } from '../form/filters/Inputs';
import Comment from './Comment';
import { useContext } from 'react';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { AuthContext } from '../../providers/AuthProvider';
import { useGetComments, useGetLikes } from '../../hooks/queries/PostActionQueries';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import EmptyWrapper from '../utility/wrappers/EmptyWrapper';
import { FormattedMessage, useIntl } from 'react-intl';
import { faBan } from '@fortawesome/free-solid-svg-icons';

const CreateComment = ({ margined = false, postDetails, commentId, isReply = false }) => {
  const { user } = useContext(AuthContext);
  const translator = useIntl();
  const { Text } = Typography;
  const profileAvatar = user?.userPhotos[0]?.profile_photo;

  const { isLoading, data } = useGetComments(postDetails?.id, 1, 10);

  if (!user) {
    return <EmptyWrapper description={translator.formatMessage({ id: 'loginToSeeComments' })} />;
  }

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <Row style={{ marginTop: margined ? '1rem' : '0' }} gutter={[8, 0]}>
      <>
        {!isReply &&
          (data?.rows?.length === 0 ? (
            <EmptyWrapper
              description={<Text>{translator.formatMessage({ id: 'noComment' })}</Text>}
            />
          ) : (
            data?.rows?.map((item) => (
              <Col key={item.id} span={24}>
                <Comment owner={postDetails?.userId} details={item} />
              </Col>
            ))
          ))}
      </>
      <Divider />
      <Col span={24}>
        <Flex gap='1rem'>
          <Image
            className='rounded-[8px] object-cover'
            height={45}
            width={55}
            src={profileAvatar ? profileAvatar : defaultProfilePhoto}
            preview={false}
          />
          <InlineEmojiInput
            placeholder='commentTextareaPlaceHolder'
            toolbar={false}
            widthFull={true}
            postId={postDetails?.id}
            commentId={commentId}
          />
        </Flex>
      </Col>
    </Row>
  );
};

export default CreateComment;
