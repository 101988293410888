import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createOrganizationPost,
  createPost,
  deleteFeedMethod,
  getFeed,
  getFeedDetails,
  editPost,
} from '../../requests/FeedManager';

export const usePostOrganizationFeed = (organizationId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ content, attachmentArray }) =>
      createOrganizationPost(content, attachmentArray, organizationId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`getOrganizationFeed-${organizationId}`],
      });
    },
  });
};

export const usePostFeed = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ content, attachmentArray, relatedFeedId }) =>
      createPost(content, attachmentArray, relatedFeedId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getFeed'],
      });
    },
  });
};

export const useEditFeed = (organizationId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ content, relatedFeedId }) =>
      editPost(content, relatedFeedId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getFeed'],
      });
      queryClient.invalidateQueries({
        queryKey: [`getOrganizationFeed-${organizationId}`],
      });
    },
  });
}; 

export const useDeleteFeed = (type = 'feed') => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (feedId) => deleteFeedMethod(feedId),
    retry: 2,
    onSuccess: () => {
      if (type === 'feed') {
        queryClient.invalidateQueries({
          queryKey: ['getFeed'],
        });
      } else{
        queryClient.invalidateQueries({
          queryKey: ['getCommentHandler'],
        });
        queryClient.invalidateQueries({
          queryKey: ['getReplyHandler'],
        });
      }
    },
  });
};

export const useDeleteOrganizationFeed = (organizationId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (feedId) => deleteFeedMethod(feedId),
    retry: 2,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`getOrganizationFeed-${organizationId}`],
      });
    },
  });
};

export const useGetFeedDetails = (postId, share) => {
  return useQuery({
    queryKey: [`getFeedDetails-${postId}`, share],
    queryFn: () => getFeedDetails(postId, share),
    enabled: !!(postId || share),
    retry: 2,
  });
};

export const useGetFeedHandler = (userId, hashtag, feedType) => {
  return useInfiniteQuery({
    queryKey: ['getFeed', userId, hashtag, feedType],
    queryFn: ({ pageParam = 0 }) => getFeed(pageParam, userId, hashtag, feedType),
    getNextPageParam: (res) => {
      return res.page + 1;
    },
    retry: 2,
  });
};
