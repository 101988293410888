import styles from '../../../style/chat.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import { Image, Typography } from 'antd';
import { useIntl } from 'react-intl';
import UserMessage from './UserMessage';
import { useState, useEffect, useContext, useRef } from 'react';
import { useChannelMessages, useReadMessage } from '../../../hooks/queries/UserQueries';
import { AuthContext } from '../../../providers/AuthProvider';
import defaultProfilePhoto from '../../../assets/images/defaultProfilePhoto.jpeg';
import { InlineEmojiInput } from '../../form/filters/Inputs';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const ChatMessage = ({ onClick, activeChannel, otherUser, chatChannelList, updateMessageList }) => {
  const { user } = useContext(AuthContext);
  const { data: channelMessages = [] } = useChannelMessages(activeChannel?.chatchannel?.id);
  const { mutate } = useReadMessage();

  const [ws, setWs] = useState(null);
  const [chatData, setChatData] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState(null);

  const fullChat = useRef([]);
  const chatAnchorRef = useRef();
  const translator = useIntl();

  useEffect(() => {
    if (activeChannel?.id > 0) {
      setWs(new WebSocket(`wss://chat.game.actor/ws?id=${activeChannel?.chatchannel?.id}`));
      mutate(activeChannel?.chatchannel?.id);
    }
  }, [activeChannel]);

  useEffect(() => {
    if (otherUser?.userPhotos && otherUser?.userPhotos.length > 0) {
      setProfilePhoto(otherUser.userPhotos[0].profile_photo);
    } else {
      setProfilePhoto(null);
    }
  }, [otherUser]);

  useEffect(() => {
    if (ws !== null) {
      ws.onmessage = (event) => {
        let data = event.data;

        let newMessage = {
          userId: JSON.parse(data).user,
          contentMessage: data,
        };

        updateMessageList(
          chatChannelList.find((i) => i.chatchannel.id === activeChannel?.chatchannel?.id),
          data,
        );

        updateChatRoomData(newMessage);
      };

      ws.onclose = function (e) {
        setTimeout(function () {
          setWs(new WebSocket(`wss://chat.game.actor/ws?id=${activeChannel?.chatchannel?.id}`));
        }, 1000);
      };

      ws.onerror = function (err) {
        ws.close();
      };
    }
  }, [ws]);

  const updateChatRoomData = (newMessages) => {
    let updatedMessages = [...fullChat.current];
    updatedMessages.push(newMessages);
    setChatData([...updatedMessages]);
    fullChat.current = [...updatedMessages];
  };

  useEffect(() => {
    if (channelMessages) {
      setChatData([...channelMessages]);
      fullChat.current = [...channelMessages];
    }
  }, [channelMessages]);

  useEffect(() => {
    chatAnchorRef.current?.scrollIntoView({ behavior: 'instant' });
  }, [chatData]);

  return (
    <div className={`${styles['chatMessageDetail']} bg-white dark:bg-[#242424]`}>
      <div
        className={`${styles['chatMessageDetailHeader']} border border-t-0 border-l-0 border-r-0 border-solid border-primary-color dark:border-dark-mode-primary-color `}
      >
        <Link to={`/profile/${otherUser.username}`} className={styles['chatOwner']}>
          <Image
            className='rounded-[8px] object-cover'
            width={55}
            height={45}
            preview={false}
            src={profilePhoto ? profilePhoto : defaultProfilePhoto}
          />
          <Text className='ml-4'>{otherUser.username}</Text>
        </Link>
        <CloseOutlined
          onClick={onClick}
          className={`${styles['close']} text-delete-color dark:text-dark-mode-text-color duration-300 dark:hover:text-white`}
        />
      </div>
      <div id='scroller' className={styles['chatMessageDetailContent']}>
        {chatData.length ? (
          chatData.map((item, index) => (
            <UserMessage
              key={index}
              content={JSON.parse(item?.contentMessage)?.content}
              owner={user?.id === item?.userId}
            />
          ))
        ) : (
          <div className='item'>{translator.formatMessage({ id: 'sayHi' })}</div>
        )}
        <div ref={chatAnchorRef} id='anchor'></div>
      </div>
      <div id='chatTipTapWrapper' className={styles['chatMessageDetailFooter']}>
        <InlineEmojiInput
          placeholder='sayHi'
          chat={true}
          userId={user?.id}
          channelId={activeChannel?.chatchannel?.id}
          toolbar={false}
          widthFull={true}
        />
      </div>
    </div>
  );
};

export default ChatMessage;
