import RequestNotification from './RequestNotification';
import { Empty, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const Requests = ({ requestList }) => {
  const { Text } = Typography;
  const translator = useIntl();
  return (
    <div className='absolute top-[60px] left-[-186px] max-h-[400px] min-w-[380px] p-4 overflow-x-hidden bg-white dark:bg-dark-mode-bg-pop-up rounded-[8px] cursor-default'>
      {requestList?.length > 0 ? (
        requestList.map((item, index) => <RequestNotification key={index} notification={item} />)
      ) : (
        <Empty
          description={
            <Text className='!text-base'>
              <FormattedMessage id='noRequests' />
            </Text>
          }
        />
      )}
    </div>
  );
};

export default Requests;
