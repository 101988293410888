import { Drawer, Image, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../providers/AuthProvider';
import ActiveMobileChat from '../components/message/mobile/ActiveMobileChat';
import MobileChatListItem from '../components/message/mobile/MobileChatListItem';
import styles from '../style/chat.module.scss';
import { Link } from 'react-router-dom';
import defaultProfilePhoto from '../assets/images/defaultProfilePhoto.jpeg';
import { UtilityContext } from '../providers/UtilityProvider';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;

const Messages = () => {
  const { user, hasUnreadMessages = [], chatChannels = [] } = useContext(AuthContext);
  const { theme } = useContext(UtilityContext);
  const [chatChannelState, setChatChannelState] = useState(chatChannels);
  const [activeChannel, setActiveChannel] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);

  const otherUser = activeChannel?.user?.find((x) => x.id !== user?.id);

  useEffect(() => {
    setChatChannelState(chatChannels);
  }, [chatChannels]);

  useEffect(() => {
    if (otherUser?.userPhotos && otherUser?.userPhotos.length > 0) {
      setProfilePhoto(otherUser.userPhotos[0].profile_photo);
    } else {
      setProfilePhoto(null);
    }
  }, [otherUser]);

  return (
    <div className='p-4'>
      <div className='flex flex-col gap-4'>
        <>
          {chatChannelState.length > 0 ? (
            chatChannelState.map((item) => (
              <MobileChatListItem
                onClick={setActiveChannel}
                unread={hasUnreadMessages.includes(item.id)}
                channel={item}
                key={item.id}
                otherUser={item?.user?.find((x) => x.id !== user?.id)}
              />
            ))
          ) : (
            <div className='item'>
              Bir mesajlaşma başlatmak için bağlantınızın profiline gidebilirsiniz!
            </div>
          )}
        </>
      </div>
      <Drawer
        styles={{
          body: { overflow: 'hidden' },
          content: {
            backgroundColor: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={
          <div className={styles['chatMessageDetailHeader']}>
            <Link
              to={`/profile/${otherUser?.username}`}
              className='flex w-full items-center justify-between'
            >
              <Text className='ml-4'>{`${otherUser?.first_name} "${otherUser?.username}" ${otherUser?.last_name}`}</Text>
              <Image
                className='rounded-[8px] object-cover ml-auto'
                width={55}
                height={45}
                preview={false}
                src={profilePhoto ? profilePhoto : defaultProfilePhoto}
              />
            </Link>
          </div>
        }
        open={!!activeChannel}
        onClose={() => setActiveChannel(null)}
        destroyOnClose={true}
        footer={false}
        placement='bottom'
        height='100vh'
      >
        <ActiveMobileChat otherUser={otherUser} activeChannel={activeChannel} />
      </Drawer>
    </div>
  );
};

export default Messages;
