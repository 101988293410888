import { Button, Form, Input, Typography } from 'antd';
import layoutStyles from '../../../style/layout.module.scss';
import { faBolt, faMemory, faMicrochip, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';
import { GpuIcon } from '../../../helpers/Icons';
import { useContext } from 'react';
import styles from '../../../style/form.module.scss';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useUpdateSystemInformation } from '../../../hooks/queries/UserQueries';
import { AuthContext } from '../../../providers/AuthProvider';
import PropChecker from '../../../helpers/utils/PropChecker';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { color } from 'framer-motion';

const { Text } = Typography;

const SystemPropertiesForm = ({ systemProperties, modalHandler }) => {
  const { user } = useContext(AuthContext);
  const { mutate } = useUpdateSystemInformation(user?.id);
  const { openNotification } = useContext(UtilityContext);
  const [systemPropertiesForm] = Form.useForm();
  const translator = useIntl();

  const systemInformationFormHandler = (formValues) => {
    if (PropChecker(formValues)) {
      formValues.userId = user?.id;
      mutate(formValues);
      modalHandler();
    } else {
      return openNotification('noBlankSpaceAllowed', 'error');
    }
  };

  return (
    <Form
      onFinish={systemInformationFormHandler}
      layout='vertical'
      initialValues={systemProperties}
      form={systemPropertiesForm}
    >
      <Form.Item name='cpu' label={translator.formatMessage({ id: 'processorFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'processorPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faMicrochip}
              style={{ color: '#1e57d5', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='router' label={translator.formatMessage({ id: 'routerFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'routerPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faWifi}
              style={{ color: '#1e57d5', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='memory' label={translator.formatMessage({ id: 'ramFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'ramPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faMemory}
              style={{ color: '#1e57d5', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item
        name='power_supply'
        label={translator.formatMessage({ id: 'powerSupplyFormLabel' })}
      >
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'powerSupplyPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faBolt}
              style={{ color: '#1e57d5', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='gpu' label={translator.formatMessage({ id: 'gpuFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'gpuPlaceHolder' })}
          prefix={<GpuIcon />}
        />
      </Form.Item>
      <div className={styles['actionWrapper']}>
        <Button
          onClick={modalHandler}
          icon={<CloseCircleFilled style={{ color: '#D61C1C', marginRight: '.5rem' }} />}
        >
          <Text className='text-inherit'>
            <FormattedMessage id='cancel' />
          </Text>
        </Button>
        <Button
          htmlType='submit'
          type='primary'
          icon={<CheckCircleFilled style={{ color: '#fff', marginRight: '.5rem' }} />}
        >
          <Text className='text-inherit'>
            <FormattedMessage id='ok' />
          </Text>
        </Button>
      </div>
    </Form>
  );
};

export default SystemPropertiesForm;
