import ReactPlayer from 'react-player';
import { useRef } from 'react';

const VideoPlayerGallery = ({ videoUrl, height }) => {
  const videoRef = useRef();
  return (
    <div>
        <ReactPlayer
            url={videoUrl}
            playing={false}
            loop={true}
            controls={false}
            width='100%'
            height={height}
            volume={1}
            muted={true}
            playsinline={true}
            onLoad={() => {videoRef.current.seek(0)}}
        />
    </div>
  );
};

export default VideoPlayerGallery;
