import { Empty, Typography } from 'antd';
import styles from '../../../style/layout.module.scss';
import { FormattedMessage } from 'react-intl';

const EmptyWrapper = ({ description, imgStyle, image }) => {
  const { Text } = Typography;
  return (
    <div className='p-4 w-full flex items-center text-white justify-center'>
      <Empty
        imageStyle={imgStyle}
        description={
          <Text>
            <FormattedMessage id='noData' />
          </Text>
        }
      />
    </div>
  );
};

export default EmptyWrapper;
