import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Waypoint } from 'react-waypoint';

const VideoPlayer = ({ videoUrl, height }) => {
    const [shouldPlay, updatePlayState] = useState(false);
    const videoRef = useRef();

    const handleEnterViewport = () => {
        updatePlayState(true);
    };

    const handleExitViewport = () => {
        updatePlayState(false);
    };

    return (
        <Waypoint
            onEnter={handleEnterViewport}
            onLeave={handleExitViewport}
        >
            <div>
                <ReactPlayer
                    url={videoUrl}
                    playing={shouldPlay}
                    loop={true}
                    controls={true}
                    width='100%'
                    height={height}
                    volume={1}
                    muted={true}
                    playsinline={true}
                    onLoad={() => {videoRef.current.seek(0)}}
                />
            </div>
        </Waypoint>
    );
};

export default VideoPlayer;
