import Hero from '../components/preregister/Hero';
import { styles } from '../styles';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PreRegister = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      navigate('/feed');
    }
  }, []);

  return (
    <div className='relative z-0'>
      <div className={styles.padding}>
        <Hero />
      </div>
    </div>
  );
};

export default PreRegister;
