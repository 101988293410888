import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getFeaturedSteam,
  getGameAchievements,
  getSteamLastActivity,
  getSteamLibrary,
  getUserSteamGenres,
  getMutualSteamGames,
  saveSteamId,
  syncAchievements,
  syncSteam,
} from '../../requests/IntegrationManager';

export const useSaveSteamId = (username) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formValues = '') => saveSteamId(formValues),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUserDetails', username],
      });
    },
    retry: 2,
  });
};

export const useSyncSteam = (code, user_id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => syncSteam(code, user_id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUserDetails', 'userSteamLibraryQuery', code, user_id],
      });
      queryClient.refetchQueries(['getUserDetails', 'userSteamLibraryQuery', code, user_id]);
    },
    retry: 2,
  });
};

export const useGetUserSteamLibrary = (userId) => {
  return useQuery({
    queryKey: ['userSteamLibraryQuery', userId],
    queryFn: () => getSteamLibrary(userId),
    retry: 2,
    enabled: !!userId,
  });
};

export const useSyncSteamAchievement = (steamAppId, userId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (steamAppId) => syncAchievements(steamAppId, userId),
    retry: 2,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userGameAchievement', userId, steamAppId],
      });
    },
  });
};

export const useGetUserGameAchievements = (steamAppId, userId) => {
  return useQuery({
    queryKey: ['userGameAchievement', userId, steamAppId],
    queryFn: () => getGameAchievements(steamAppId, userId),
    retry: 2,
    enabled: !!userId,
  });
};

export const useGetFeaturedSteam = () => {
  return useQuery({
    queryKey: ['getFeaturedSteamQuery'],
    queryFn: () => getFeaturedSteam(),
    retry: 2,
  });
};

export const useGetLastSteamActivity = (userId) => {
  return useQuery({
    queryKey: ['getLastSteamActivityQuery', userId],
    queryFn: () => getSteamLastActivity(userId),
    retry: 2,
  });
};

export const useGetUserSteamGenres = (userId) => {
  return useQuery({
    queryKey: ['getUserSteamGenresQuery', userId],
    queryFn: () => getUserSteamGenres(userId),
    retry: 2,
  });
};

export const useGetMutualSteamGames = (userId) => {
  return useQuery({
    queryKey: ['getMutualSteamGames', userId],
    queryFn: () => getMutualSteamGames(userId),
    retry: 2,
  });
};
