import { useMutation, useQuery } from '@tanstack/react-query';
import { changeRequestHandler, getEventTags, getTrendTags } from '../../requests/UtilityManager';

export const useGetTrendTags = () => {
  return useQuery({
    queryKey: ['trendTags'],
    queryFn: () => getTrendTags(),
    retry: 2,
  });
};

export const useChangeRequest = () => {
  return useMutation({
    mutationFn: (payload) => changeRequestHandler(payload),
    retry: 2,
  });
};

export const useGetEventTags = () => {
  return useQuery({
    queryKey: ['getEventTagsQuery'],
    queryFn: () => getEventTags(),
    retry: 2,
  });
};
