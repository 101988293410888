import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const useFormattedDate = (date) => {
  const formatDate = (date) => {
    return date ? dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '';
  };

  return formatDate(date);
};

export default useFormattedDate;
