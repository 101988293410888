import { useGetFeedDetails } from '../../hooks/queries/FeedQueries';
import { Button, Col, Drawer, Dropdown, Flex, Image, Typography, Popover, Modal } from 'antd';
import PostCard from './PostCard';
import PrimaryCard from '../cards/PrimaryCard';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDeleteFeed, useDeleteOrganizationFeed } from '../../hooks/queries/FeedQueries';
import styles from '../../style/postpage.module.scss';
import {
  LinkOutlined,
  MessageOutlined,
  SendOutlined,
  RightOutlined,
  LeftOutlined,
  SettingTwoTone,
  DeleteTwoTone,
  CloseOutlined,
  EditTwoTone,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import layoutStyles from '../../style/layout.module.scss';
import { faBomb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from 'react-share';
import { UtilityContext } from '../../providers/UtilityProvider';
import Carousel from 'nuka-carousel';
import CreateComment from './CreateComment';
import { useGetLikes, useLikeHandler } from '../../hooks/queries/PostActionQueries';
import { AuthContext } from '../../providers/AuthProvider';
import { getRelativeTime } from '../../helpers/utils/TimeFormatters';
import ListUsers from '../feed/ListUsers';
import VideoPlayer from './VideoPlayer';
import useWindowSize from '../../hooks/useWindowSize';
import BoostedStamp from '../utility/BoostedStamp';
import variables from '../../style/_variables.module.scss';
import { useClientError } from '../../hooks/useClientError';
import EditPostForm from '../form/post/EditPostForm';
import { differenceInMinutes } from 'date-fns';

const { Paragraph, Text } = Typography;
const { focusedPrimaryColor } = variables;

const Repost = ({ postDetail, postId, boosted, share }) => {
  const { mutate } = useLikeHandler();
  const { user, userOwnedPages } = useContext(AuthContext);
  const { data: likes, fetchNextPage, isLoading: likeListLoading } = useGetLikes(postId, user?.id);
  const { openNotification, theme } = useContext(UtilityContext);
  const { data: repostDetail, isLoading: repostDetailLoading } = useGetFeedDetails(
    postDetail?.relatedFeedId,
  );
  const { mutateAsync: deleteFeed, isLoading } = useDeleteFeed();
  const { mutateAsync: deleteOrganizationFeed, isOrganizationLoading } = useDeleteOrganizationFeed(
    postDetail?.organizationId,
  );
  const [hitList, setHitList] = useState(false);
  const [commentSection, setCommentSection] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const onClientError = useClientError();

  const { width, height } = useWindowSize();

  const isOwner =
    postDetail?.userId === user?.id ||
    userOwnedPages.find((page) => page.id === postDetail?.organizationId);

  const contentRef = useRef();

  const copyToClipboardHandler = (shareUid) => {
    navigator.clipboard.writeText(`https://www.game.actor/shared/${shareUid}`);
    openNotification('shareLinkCopied', 'success');
  };

  const commentSectionHandler = () => {
    setCommentSection(!commentSection);
  };

  const likeHandler = () => {
    mutate(postId);
  };

  const items = [
    {
      key: '1',
      label: (
        <Flex align='center' gap='1rem'>
          <Button
            className='rounded-full'
            onClick={() => copyToClipboardHandler(postDetail?.share)}
            icon={<LinkOutlined />}
          />
          <FacebookShareButton url={`https://www.game.actor/shared/${postDetail?.share}`}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton url={`https://www.game.actor/shared/${postDetail?.share}`}>
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <TelegramShareButton url={`https://www.game.actor/shared/${postDetail?.share}`}>
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>
          <TwitterShareButton url={`https://www.game.actor/shared/${postDetail?.share}`}>
            <XIcon size={32} round={true} />
          </TwitterShareButton>
          <WhatsappShareButton url={`https://www.game.actor/shared/${postDetail?.share}`}>
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    let message = postDetail?.content;
    if (message) {
      message = message.replaceAll('<p>', '');
      message = message.replaceAll('</p>', '');
      let contentArray = message.split(' ');

      contentArray.forEach((word) => {
        if (word.startsWith('#') && !word.includes(';') && !word.includes('"')) {
          message = message.replaceAll(
            word,
            `<a href='/hash/${word.replaceAll('#', '$')}' class='hashtag_link''>${word}</a>`,
          );
        }
      });

      contentRef.current.innerHTML = message;
    }
  }, [postDetail]);

  const deletePostHandler = () => {
    if (postDetail?.organizationId > 0) {
      deleteOrganizationFeed(postId)
        .then((response) => {
          if (response.status === 202) {
            openNotification('feedDeleted', 'success');
          } else {
            openNotification('feedDeleteFailed', 'error');
          }
        })
        .catch((err) => {
          onClientError(err);
        });
    } else {
      deleteFeed(postId)
        .then((response) => {
          if (response.status === 202) {
            openNotification('feedDeleted', 'success');
          } else {
            openNotification('feedDeleteFailed', 'error');
          }
        })
        .catch((err) => {
          onClientError(err);
        });
    }
    setDeleteModal(false);
  };

  const popOverContent = (
    <div className='flex flex-col'>
      <Button
        onClick={() => setDeleteModal(true)}
        className='p-0 font-thin'
        type='link'
        icon={<DeleteTwoTone twoToneColor='#D61C1CFF' />}
      >
        <Text className='text-inherit'>
          <FormattedMessage id='remove' />
        </Text>
      </Button>
      <Button
        onClick={() => setEditModal(true)}
        className='p-0 font-thin'
        type='link'
        icon={<EditTwoTone twoToneColor={focusedPrimaryColor} />}
      >
        <Text className='text-inherit'>
          <FormattedMessage id='edit' />
        </Text>
      </Button>
    </div>
  );

  const closeEditModal = () => {
    setEditModal(false);
  };

  if (postId) {
    return (
      <PrimaryCard grid={false}>
        <Flex className='mb-3' gap='0.5rem' align='center'>
          <Image
            className='rounded-[8px] object-cover'
            width={40}
            height={30}
            preview={false}
            src={
              postDetail?.user?.userPhotos[0].profile_photo
                ? postDetail?.user?.userPhotos[0].profile_photo
                : defaultProfilePhoto
            }
          />
          <Paragraph className='!m-0 text-sm !text-gray-600 !font-normal'>
            <Link
              className='mr-2 text-sm !font-normal'
              to={`/profile/${postDetail?.user?.username}`}
            >
              {postDetail?.user?.username}
              <Text className='text-xs ml-2 font-normal !text-gray'>
                • {postDetail?.user?.first_name} {postDetail?.user?.last_name}
              </Text>
            </Link>
            <FormattedMessage id='reposted' />
            <div className='flex'>
              <Text className='block text-xs'>{getRelativeTime(postDetail?.created_at)}</Text>
              {differenceInMinutes(postDetail?.updated_at, postDetail?.created_at) >= 1 && (
                <span className='!text-xs !text-gray-600 ml-2'>
                  {' '}
                  {'('}<FormattedMessage id='edited'/>{')'}
                </span>
              )}
            </div>
          </Paragraph>
          <div className={`${styles['postStampAndActions']} ml-auto`}>
            {boosted && <BoostedStamp />}
            {isOwner && (
              <Popover placement='right' content={popOverContent}>
                <SettingTwoTone className='ml-4' twoToneColor={focusedPrimaryColor} />
              </Popover>
            )}
          </div>
        </Flex>
        <Paragraph ref={contentRef}></Paragraph>
        {postDetail?.feedAttachments?.length > 1 ? (
          <div>
            <Carousel
              defaultControlsConfig={{
                nextButtonText: <RightOutlined />,
                prevButtonText: <LeftOutlined />,
              }}
            >
              {postDetail?.feedAttachments.map((attachment, index) => {
                if (attachment.type === 'video') {
                  return (
                    <div key={index}>
                      <VideoPlayer videoUrl={attachment?.attachmentUrl} height={500} />
                    </div>
                  );
                } else if (attachment.type === 'image') {
                  return (
                    <div className={styles['postImageWrapper']} key={index}>
                      <Image preview={false} src={attachment?.attachmentUrl} />
                    </div>
                  );
                }
              })}
            </Carousel>
          </div>
        ) : (
          <div>
            {postDetail?.feedAttachments &&
              postDetail?.feedAttachments.map((attachment, index) => {
                if (attachment.type === 'video') {
                  return (
                    <div key={index}>
                      <VideoPlayer videoUrl={attachment?.attachmentUrl} height={500} />
                    </div>
                  );
                } else if (attachment.type === 'image') {
                  return (
                    <div className={styles['postImageWrapper']} key={index}>
                      <Image preview={false} src={attachment?.attachmentUrl} />
                    </div>
                  );
                }
              })}
          </div>
        )}
        <PostCard
          reposted={true}
          repostLoading={repostDetailLoading}
          postDetail={repostDetail}
          postId={repostDetail?.id}
        />
        <div className={`${styles['postInfo']} mt-4`}>
          <div>
            {likes?.pages[0].like_count > 0 && (
              <Text className='cursor-pointer' onClick={() => setHitList(true)}>
                {likes?.pages[0].like_count} <FormattedMessage id='likeCountMessage' />
              </Text>
            )}
          </div>
          <div>
            {postDetail?.comment_count > 0 && (
              <Text className='cursor-pointer' onClick={commentSectionHandler}>
                {postDetail?.comment_count} <FormattedMessage id='comment' />
              </Text>
            )}
          </div>
        </div>
        <Flex className={`${styles['postActions']} !mt-4`} justify='space-between'>
          <Flex gap='1rem'>
            <Button
              onClick={likeHandler}
              className={styles['hitIconButton']}
              icon={
                <FontAwesomeIcon
                  className={layoutStyles['fontAwesomeIcon']}
                  icon={faBomb}
                  style={{
                    color: likes?.pages[0].is_liked ? '#c31d1d' : '#959595',
                    marginRight: '.5rem',
                  }}
                />
              }
            >
              <Text className='text-inherit'>Hit</Text>
            </Button>
            <Button
              type={commentSection ? 'primary' : 'default'}
              onClick={commentSectionHandler}
              icon={<MessageOutlined />}
            >
              <Text className='text-inherit'>
                <FormattedMessage id='comment' />
              </Text>
            </Button>
          </Flex>
          <div className={styles['shareButtonWrapper']}>
            <Dropdown
              menu={{
                items,
              }}
            >
              <Button icon={<SendOutlined />}>
                <Text className='text-inherit'>
                  <FormattedMessage id='share' />
                </Text>
              </Button>
            </Dropdown>
          </div>
        </Flex>
        {commentSection && (
          <div className={styles['commentSection']}>
            <CreateComment postDetails={postDetail} />
          </div>
        )}
        <Drawer
          styles={{
            content: {
              background: theme ? '#2A2A2A' : 'white',
            },
            header: { background: theme ? '#2A2A2A' : 'white' },
          }}
          closeIcon={
            <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
          }
          width={320}
          destroyOnClose={true}
          onClose={() => setHitList(false)}
          open={hitList}
          placement={width < 480 ? 'bottom' : 'right'}
        >
          <ListUsers fetchHandler={fetchNextPage} isLoading={likeListLoading} list={likes} />
        </Drawer>
        <Modal
          styles={{
            content: {
              background: theme ? '#2A2A2A' : 'white',
            },
            header: { background: theme ? '#2A2A2A' : 'white' },
          }}
          closeIcon={
            <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
          }
          title={<FormattedMessage id='attention' />}
          open={deleteModal}
          onOk={deletePostHandler}
          onCancel={() => setDeleteModal(false)}
          okType='danger'
          okText={<FormattedMessage id='delete' />}
          okButtonProps={{ loading: isLoading || isOrganizationLoading }}
          cancelText={<FormattedMessage id='cancel' />}
        >
          <FormattedMessage id='postDeleteWarn' />
        </Modal>
        <Modal
          styles={{
            content: {
              background: theme ? '#181818' : '',
            },
            header: { background: theme ? '#181818' : '' },
          }}
          closeIcon={
            <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300' />
          }
          title={<FormattedMessage id='edit' />}
          open={editModal}
          onCancel={() => setEditModal(false)}
          footer={false}
          width={840}
          destroyOnClose={true}
        >
          <EditPostForm postDetail={postDetail} setEditModal={closeEditModal} />
        </Modal>
      </PrimaryCard>
    );
  }
};

export default Repost;
