import React, { useContext, useState } from 'react';
import {
  AppstoreAddOutlined,
  AppstoreOutlined,
  DollarCircleOutlined,
  DownOutlined,
  HomeOutlined,
  LoginOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  QrcodeOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, Space, theme } from 'antd';
import { Link } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';

const { Header, Sider, Content } = Layout;

const items = [
  {
    key: '1',
    icon: <HomeOutlined />,
    label: <Link to='/'>Home</Link>,
  },
  {
    key: '2',
    icon: <MenuOutlined />,
    label: 'Menus',
    children: [
      {
        key: '3',
        icon: <AppstoreAddOutlined />,
        label: <Link to='/menu'>New Menu</Link>,
      },
      {
        key: '4',
        icon: <UploadOutlined />,
        label: <Link to='/menu/1'>Menu 1</Link>,
      },
    ],
  },
  {
    key: '5',
    icon: <UploadOutlined />,
    label: <Link to='/dashboard'>Dashboard</Link>,
  },
  {
    key: '7',
    icon: <DollarCircleOutlined />,
    label: <Link to='/pricing'>Pricing</Link>,
  },
  {
    key: '8',
    icon: <QrcodeOutlined />,
    label: <Link to='/qr-list'>QR List</Link>,
  },
  {
    key: '9',
    icon: <AppstoreOutlined />,
    label: <Link to='/categories'>Categories</Link>,
  },
];

const DashboardLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { user, logout } = useContext(AuthContext);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout>
      <Sider className='sidebar-menu' trigger={null} collapsible collapsed={collapsed}>
        <div className='logo' />
        <Menu mode='inline' defaultSelectedKeys={['7']} items={items} className='sidebar-menu' />
      </Sider>
      <Layout className='site-layout'>
        <Header
          style={{
            padding: '0 1rem',
            background: colorBgContainer,
          }}
          className='header'
        >
          <Button onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <Dropdown
            trigger={['click']}
            dropdownRender={() => (
              <div className='userMenuDropdown'>
                <Button className='danger' onClick={logout}>
                  <LogoutOutlined />
                  Logout!
                </Button>
              </div>
            )}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <UserOutlined />
                {user.fullname}
              </Space>
            </a>
          </Dropdown>
        </Header>
        <Content
          style={{
            margin: '1.5rem 1rem',
            padding: 24,
            minHeight: 'calc(100vh - 112px)',
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default DashboardLayout;
