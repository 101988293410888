import React, { useContext } from 'react';
import { Drawer, Row } from 'antd';
import ImageWrapper from '../utility/wrappers/ImageWrapper';
import { useEffect, useState } from 'react';
import PostDetail from '../post/PostDetail';
import { useGetOrganizationGallery } from '../../hooks/queries/OrganizationQueries';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { useInView } from 'react-intersection-observer';
import styles from '../../style/layout.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import { UtilityContext } from '../../providers/UtilityProvider';
const OrganizationGallery = ({ organizationId }) => {
  const { ref, inView } = useInView();
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetOrganizationGallery(organizationId, 0);
  const [postId, setPostId] = useState(0);
  const { theme } = useContext(UtilityContext);
  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage]);

  const imageClickHandler = (url) => {
    setPostId(url);
  };

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <>
      <Row justify='center'>
        {data?.pages?.map((page, pageIndex) =>
          page?.rows?.map(({ attachmentUrl, feedId, type }, index) => (
            <div key={`${pageIndex}-${index}`} ref={ref} className={styles['imageLayoutWrapper']}>
              <ImageWrapper
                onImageClick={() => imageClickHandler(feedId)}
                attachmentUrl={attachmentUrl}
                type={type}
              />
            </div>
          )),
        )}
      </Row>
      <Drawer
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        onClose={() => setPostId(0)}
        open={postId > 0}
        destroyOnClose={true}
        centered={true}
        width={window.screen.width < 768 ? '100%' : '60%'}
        height='100%'
        footer={null}
      >
        {postId && <PostDetail postId={postId} />}
      </Drawer>
    </>
  );
};

export default OrganizationGallery;
