import locale_en from '../../lib/translations/en.json';
import locale_tr from '../../lib/translations/tr.json';
import locale_es from '../../lib/translations/es.json';

export const intlLanguageList = {
  en: locale_en,
  es: locale_es,
  tr: locale_tr,
};

export const intlLanguageFlags = {
  en: '//upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg',
  es: '//upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg',
  tr: '//upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg',
};