import { Button, Result, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const { Text } = Typography;

const PageNotFound = () => {
  const translator = useIntl();

  return (
    <div className='flex items-center justify-center h-screen'>
      <Result
        status='404'
        title='404'
        subTitle={translator.formatMessage({ id: 'youSeemLost' })}
        extra={
          <Button type='primary' onClick={() => (window.location.href = '/feed')}>
            <Text className='text-inherit'>
              <FormattedMessage id='home' />
            </Text>
          </Button>
        }
        className='text-white'
      />
    </div>
  );
};

export default PageNotFound;
