import { Col, Flex, Image, Row } from 'antd';
import { InlineEmojiInput } from '../form/filters/Inputs';
import { useContext } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';

const CreateReply = ({ commentId }) => {
  const { user } = useContext(AuthContext);

  return (
    <Flex className='mt-4' gap='1rem'>
      <Image
        className='rounded-[8px] object-cover'
        height={45}
        width={55}
        src={
          user.userPhotos[0]?.profile_photo
            ? user.userPhotos[0]?.profile_photo
            : defaultProfilePhoto
        }
        preview={false}
      />
      <InlineEmojiInput
        placeholder='commentTextareaPlaceHolder'
        widthFull={true}
        toolbar={false}
        commentId={commentId}
      />
    </Flex>
  );
};

export default CreateReply;
