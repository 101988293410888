import Icon, {
  BoldOutlined,
  HighlightOutlined,
  ItalicOutlined,
  LeftSquareOutlined,
  OrderedListOutlined,
  RedoOutlined,
  RightSquareOutlined,
  StrikethroughOutlined,
  UndoOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import Typography from '@tiptap/extension-typography';
import StarterKit from '@tiptap/starter-kit';

import {
  /* Typography, */ LowerCase,
  UpperCase,
  CustomExtension,
  TipTapExtensionTableCell,
  TipTapExtensionTableHeader,
  CustomBulletList,
} from './CustomExtensions';
import { Youtube } from '@tiptap/extension-youtube';

export const extensions = [
  StarterKit.configure({
    blockquote: {
      HTMLAttributes: {
        style: 'padding-left: 2rem; margin: 0;',
      },
    },
    bulletList: false,
  }),
  CustomBulletList,
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TipTapExtensionTableCell,
  TipTapExtensionTableHeader,
  Typography,
  UpperCase,
  LowerCase,
  Highlight.configure({
    multicolor: true,
  }),
  CustomExtension,
];
//asd
export const BUTTON_LIST = [
  {
    icon: <UndoOutlined />,
    funcName: 'undo',
    checkType: 'undo',
    params: {},
  },
  {
    icon: <RedoOutlined />,
    funcName: 'redo',
    checkType: 'redo',
    params: {},
  },
  {
    icon: <BoldOutlined />,
    funcName: 'toggleBold',
    checkType: 'bold',
    params: {},
  },
  {
    icon: <ItalicOutlined />,
    funcName: 'toggleItalic',
    checkType: 'italic',
    params: {},
  },
  {
    icon: <StrikethroughOutlined />,
    funcName: 'toggleStrike',
    checkType: 'strike',
    params: {},
  },
  {
    icon: <HighlightOutlined />,
    funcName: 'toggleHighlight',
    checkType: 'highlight',
    params: {},
  },
];

export const TABLE_LIST = [
  {
    name: 'Add Column Before',
    funcName: 'addColumnBefore',
  },
  {
    name: 'Add Column After',
    funcName: 'addColumnAfter',
  },
  {
    name: 'Delete Column',
    funcName: 'deleteColumn',
  },
  {
    name: 'Add Row Before',
    funcName: 'addRowBefore',
  },
  {
    name: 'Add Row After',
    funcName: 'addRowAfter',
  },
  {
    name: 'Delete Row',
    funcName: 'deleteRow',
  },
  {
    name: 'Toggle Header Column',
    funcName: 'toggleHeaderColumn',
  },
  {
    name: 'Delete Table',
    funcName: 'deleteTable',
  },
];
