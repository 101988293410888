import axiosInstance from '../lib/service/axios';

export const postPreRegister = async (formValues) => {
  return await axiosInstance.post('preregister', formValues);
};

export const userLogin = async (formValues) => {
  let formObject = {
    action: 'auth',
    auth: formValues,
  };
  return await axiosInstance.post('handle', formObject);
};

export const userRegister = async (formValues) => {
  let formObject = {
    action: 'register',
    register: formValues,
  };
  return await axiosInstance.post('handle', formObject);
};

export const getLoggedInUserInformation = async () => {
  let formObject = {
    action: 'user',
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const changeUserPassword = async (payload) => {
  let formObject = {
    action: 'changePassword',
    changePasswordPayload: {
      new_password: payload.new_password,
      change_token: payload.change_token,
    },
  };
  return await axiosInstance.post('handle', formObject);
};
