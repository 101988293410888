import styles from '../../../style/chat.module.scss';
import { Flex, Image, Typography } from 'antd';
import profileAvatar from '../../../assets/images/defaultProfilePhoto.jpeg';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const { Paragraph } = Typography;

const UserMessage = ({ owner, content, isMobile = false, otherUser, user }) => {
  const sanitizedHtml = parse(DOMPurify.sanitize(content));

  if (isMobile) {
    return (
      <Flex
        gap='.5rem'
        className={
          owner
            ? 'max-w-[240px] p-[6px] rounded-lg bg-primary-color !text-white dark:bg-dark-mode-primary-color mt-3 mr-0 mb-3 ml-auto'
            : 'max-w-[240px] p-[6px] rounded-lg bg-chat-message-box dark:bg-dark-mode-bg-pop-up my-3 mx-0'
        }
      >
        {!owner && (
          <Image
            preview={false}
            className='rounded-[8px] min-w-[40px]'
            width={40}
            src={
              otherUser?.userPhotos[0]?.profile_photo
                ? otherUser?.userPhotos[0]?.profile_photo
                : profileAvatar
            }
          />
        )}
        <div className={styles['messageContent']}>
          <Paragraph className={owner ? '[&>p]:mb-0 !mb-0 text-white' : '[&>p]:mb-0 !mb-0 '}>
            {sanitizedHtml}
          </Paragraph>
        </div>
      </Flex>
    );
  } else {
    return (
      <div
        className={
          owner
            ? `${styles['chatWrapperOwner']} bg-dark-white dark:!text-white dark:bg-dark-mode-primary-color`
            : `${styles['chatWrapper']} bg-dark-white-toned  dark:bg-dark-mode-dark-white-toned `
        }
      >
        <Paragraph className='[&>p]:mb-0 !mb-0  dark:text-white'>{sanitizedHtml}</Paragraph>
      </div>
    );
  }
};

export default UserMessage;
