import { Link, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { steamIntegrateUrl } from '../../../helpers/constants/constantHelpers';
import { Button, Col, Row, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import layoutStyles from '../../../style/layout.module.scss';
import { faSteam } from '@fortawesome/free-brands-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useSaveSteamId, useSyncSteam } from '../../../hooks/queries/IntegrationQueries';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { useClientError } from '../../../hooks/useClientError';
import { AuthContext } from '../../../providers/AuthProvider';
import { SyncOutlined } from '@ant-design/icons';
import useWindowSize from '../../../hooks/useWindowSize';

const { Text } = Typography;

const SteamLibrary = ({ profileDetails }) => {
  const { user } = useContext(AuthContext);
  const { width } = useWindowSize();
  const { openNotification } = useContext(UtilityContext);
  const { mutateAsync } = useSaveSteamId(profileDetails?.username);
  const {
    mutateAsync: syncSteamHandler,
    isPending,
    isSuccess,
  } = useSyncSteam(user?.username, user?.id);
  const [isSteamIntegrated, setIsSteamIntegrated] = useState(false);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const onClientError = useClientError();

  useEffect(() => {
    if (user) {
      if (user.steam_id?.length > 0) {
        setIsSteamIntegrated(true);
      }
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!user?.steam_id) {
      const userSteamId = searchParams.get('openid.claimed_id')?.split('/id/')[1];
      if (userSteamId) {
        mutateAsync(userSteamId)
          .then((response) => {
            if (response.status === 202) {
              openNotification('steamIntegrationSuccess', 'success');
              syncSteamHandler().then((syncResponse) => {
                if (syncResponse.status === 202) {
                  setIsSteamIntegrated(true);
                }
              });
            }
          })
          .catch((err) => onClientError(err));
      }
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      openNotification('steamSynced', 'success');
    }
  }, [isSuccess]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {isSteamIntegrated ? (
          <Button
            onClick={syncSteamHandler}
            loading={isPending}
            type='primary'
            icon={<SyncOutlined />}
          >
            {width > 900 ? (
              <Text className='text-inherit'>
                <FormattedMessage id='sync' />
              </Text>
            ) : (
              ''
            )}
          </Button>
        ) : (
          <Link to={steamIntegrateUrl(profileDetails?.username)}>
            <Button
              type='primary'
              icon={
                <FontAwesomeIcon
                  className={layoutStyles['fontAwesomeIcon']}
                  icon={faSteam}
                  style={{ color: '#FFF' }}
                />
              }
            >
              <Text className='text-inherit'>
                {width > 900 ? <FormattedMessage id='integrate' /> : ''}
              </Text>
            </Button>
          </Link>
        )}
      </Col>
    </Row>
  );
};

export default SteamLibrary;
