import PrimaryCard from '../cards/PrimaryCard';
import CalendarWithNotes from '../utility/CalendarWithNotes';
import { useEffect, useState } from 'react';
import { useGetEventCalendar } from '../../hooks/queries/EventQueries';
import SpinWrapper from '../utility/wrappers/SpinWrapper';

const EventCalendar = () => {
  const { data, isLoading } = useGetEventCalendar();
  const [events, setEvents] = useState([]);
  const [calendarType, setCalendarType] = useState('month');

  useEffect(() => {
    if (data?.data.length > 0) {
      transformEvents(data?.data);
    }
  }, [data]);

  if (isLoading) {
    return <SpinWrapper />;
  }

  function transformEvents(events) {
    const eventArray = [];

    events?.forEach((event) => {
      const date = new Date(event.event_date);
      const month = date.getUTCMonth() + 1; // Ayları 1-12 arasında almak için 1 ekliyoruz
      const day = date.getUTCDate(); // Günü alıyoruz
      const eventName = event.name;
      const eventId = event.id;

      eventArray.push({
        month: month,
        day: day,
        event: [
          {
            name: eventName,
            id: eventId,
          },
        ],
      });
    });
    setEvents(eventArray);
  }

  return (
    <PrimaryCard className='overflow-auto'>
      <CalendarWithNotes
        onPanelChange={(date, type) => setCalendarType(type)}
        calendarMode={calendarType}
        yearDisplayData={events}
        pureEventData={data?.data}
      />
    </PrimaryCard>
  );
};

export default EventCalendar;
