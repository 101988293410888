import axiosInstance from '../lib/service/axios';

export const uploadFile = async (formValues) => {
  let formObject = {
    action: 'uploadFile',
    uploadPayload: formValues,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getSearchedPage = async (searchValue) => {
  let formObject = {
    action: 'searchUser',
    name: {
      name: searchValue,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getTrendTags = async () => {
  let formObject = {
    action: 'retrieveTrends',
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const changeRequestHandler = async (formValues) => {
  let formObject = {
    action: 'changeRequest',
    changerequestPayload: formValues,
  };

  return await axiosInstance.post('handle', formObject);
};

export const getEventTags = async () => {
  let formObject = {
    action: 'getTags',
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data;
};
