import { SelectComponent } from '../filters/Inputs';
import { Button, Checkbox, Form, Input, notification, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useCreateOrganizationMember,
  useGetOrganizationFollowersDetail,
} from '../../../hooks/queries/OrganizationQueries';
import React, { useContext, useEffect } from 'react';
import { UtilityContext } from '../../../providers/UtilityProvider';

const { Text } = Typography;

const OrganizationMemberForm = ({ organizationId, followers, memberIds, modalCallBack }) => {
  const { openNotification } = useContext(UtilityContext);
  const {
    mutate: createMember,
    isPending,
    isSuccess,
    isError,
  } = useCreateOrganizationMember(organizationId);
  const { data: followerDetails = [] } = useGetOrganizationFollowersDetail(
    followers?.filter((id) => !memberIds.includes(id)),
    organizationId,
  );
  const translator = useIntl();

  const [organizationMembersForm] = Form.useForm();

  const submitMembers = (formValues) => {
    formValues['isMember'] = true;
    if (formValues.userIds?.length > 0 && formValues.role?.replaceAll(' ', '').length > 0) {
      createMember(formValues);
    }
    if (formValues.userIds?.length === 0 || formValues.role?.replaceAll(' ', '').length === 0) {
      openNotification('memberAddFailed', 'error');
    }
  };

  useEffect(() => {
    if (isSuccess) {
      openNotification('memberAdded', 'success');
      modalCallBack();
    }
  }, [isSuccess]);

  return (
    <Form
      initialValues={{
        userIds: [],
        role: '',
        isAdmin: false,
      }}
      layout='vertical'
      onFinish={submitMembers}
      form={organizationMembersForm}
    >
      <SelectComponent
        labelId='members'
        options={followerDetails}
        formName='userIds'
        multi={true}
        translationId='chooseMembers'
        fieldNames={{
          label: 'fullname',
          value: 'id',
        }}
        filterOption={(input, option) =>
          (option.fullname.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
        }
      />
      <Form.Item label={translator.formatMessage({ id: 'userRole' })} name='role'>
        <Input placeholder={translator.formatMessage({ id: 'enterUserRole' })} />
      </Form.Item>
      <Form.Item valuePropName='checked' name='isManager'>
        <Checkbox>
          <FormattedMessage id='isAdmin' />
        </Checkbox>
      </Form.Item>
      <Button loading={isPending} htmlType='submit' type='primary'>
        <Text className='text-inherit'>
          <FormattedMessage id='save' />
        </Text>
      </Button>
    </Form>
  );
};

export default OrganizationMemberForm;
