import { Col, Row, Image, Typography, Tooltip, Button } from 'antd';
import AchievementItem from './AchievementItem';
import { FormattedMessage, useIntl } from 'react-intl';
import { SyncOutlined, WarningOutlined, WarningTwoTone } from '@ant-design/icons';
import {
  useGetUserGameAchievements,
  useSyncSteamAchievement,
} from '../../../hooks/queries/IntegrationQueries';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useClientError } from '../../../hooks/useClientError';
import { UtilityContext } from '../../../providers/UtilityProvider';
import SpinWrapper from '../../utility/wrappers/SpinWrapper';
import EmptyWrapper from '../../utility/wrappers/EmptyWrapper';

const { Text } = Typography;

const SteamAchievements = ({ image, id, userId }) => {
  const { mutateAsync, isPending } = useSyncSteamAchievement(id, userId);
  const { data, isLoading: achievementsLoading } = useGetUserGameAchievements(id, userId);

  const translator = useIntl();
  const onClientError = useClientError();

  useEffect(() => {
    syncAchievements();
  }, []);

  const syncAchievements = useCallback(() => {
    mutateAsync(id).catch((err) => onClientError(err));
  }, []);

  if (isPending) {
    return <SpinWrapper />;
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Text className='text-sm mb-2 block'>
          <WarningTwoTone className='mr-1' twoToneColor='red' />
          <FormattedMessage id='steamPublicWarn' />
        </Text>
        <Image className='object-cover rounded-[8px]' preview={false} src={image} />
        <>
          {data?.length > 0 && (
            <Tooltip title={translator.formatMessage({ id: 'rarityDescription' })}>
              <Text className='block w-full text-end mt-4 -mb-4 text-sm'>
                <FormattedMessage id='rarity' />
              </Text>
            </Tooltip>
          )}
        </>
      </Col>
      {achievementsLoading ? (
        <SpinWrapper />
      ) : data?.length > 0 ? (
        data?.map(({ achieved, unlock_time, steamachievement }, index) => (
          <Col key={index} span={24}>
            <AchievementItem
              achieved={achieved}
              rate={steamachievement.percentage}
              achievementName={steamachievement.display_name}
              achievementDate={unlock_time}
              achievementDescription={steamachievement.description}
              achievedImage={steamachievement.icon}
              nonAchievedImage={steamachievement.icon_gray}
            />
          </Col>
        ))
      ) : (
        <EmptyWrapper description={translator.formatMessage({ id: 'noAchievementsInDisplay' })} />
      )}
    </Row>
  );
};

export default SteamAchievements;
