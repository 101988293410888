import { Modal } from 'antd';
import { useContext } from 'react';
import { UtilityContext } from '../../providers/UtilityProvider';
import { CloseOutlined } from '@ant-design/icons';
const ConfirmModal = ({ title, open, onOk, onCancel, okText, okButtonProps, children, footer }) => {
  const { theme } = useContext(UtilityContext);
  return (
    <Modal
      styles={{
        content: {
          background: theme ? '#2A2A2A' : 'white',
        },
        header: { background: theme ? '#2A2A2A' : 'white' },
      }}
      closeIcon={
        <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300' />
      }
      title={title}
      open={open}
      onOk={onOk}
      okText={okText}
      okButtonProps={okButtonProps}
      onCancel={onCancel}
      footer={footer}
      destroyOnClose={true}
    >
      {children}
    </Modal>
  );
};

export default ConfirmModal;
