import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getOrganizationDetailByCode,
  getOrganizationFollowerDetail,
  getOrganizationFollowers,
  getOrganizationMembers,
  getOrganizationMembersDetail,
  getOrganizationsDetail,
  handleOrganizationMember,
  updateOrganization,
  getOrganizationCategories,
  createOrganization,
  followOrganization,
  deleteOrganization,
  getUserOrganizationsHandler,
  getOrganizationGallery,
} from '../../requests/OrganizationManager';
import { getOrganizationFeed } from '../../requests/FeedManager';
import { useNavigate } from 'react-router-dom';

export const useGetOrganizationFollowersDetail = (userArray = [], organizationId) => {
  return useQuery({
    queryKey: [`getOrganizationFollowerDetails-${organizationId}`, userArray, organizationId],
    queryFn: () => getOrganizationFollowerDetail(userArray),
    enabled: !!organizationId && userArray.length > 0,
    retry: 2,
  });
};

export const useGetOrganizationDetailByCodeHandler = (code) => {
  return useQuery({
    queryKey: [`getOrganizationDetailByCode-${code}`],
    queryFn: () => getOrganizationDetailByCode(code),
    retry: 2,
  });
};

export const useGetOrganizationFollowers = (organizationId) => {
  return useQuery({
    queryKey: ['getOrganizationFollowers', organizationId],
    queryFn: () => getOrganizationFollowers(organizationId),
    enabled: !!organizationId,
    retry: 2,
  });
};

export const useGetOrganizationMembers = (organizationId) => {
  return useQuery({
    queryKey: ['getOrganizationMembers', organizationId],
    queryFn: () => getOrganizationMembers(organizationId),
    enabled: !!organizationId,
    retry: 2,
  });
};

export const useGetOrganizationMembersDetail = (userArray = [], organizationId) => {
  return useQuery({
    queryKey: ['getOrganizationMemberDetails', userArray, organizationId],
    queryFn: () => getOrganizationMembersDetail(userArray, organizationId),
    enabled: !!organizationId && userArray.length > 0,
    retry: 2,
  });
};

export const useCreateOrganizationMember = (organizationId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userIds, role, isManager, isMember }) =>
      handleOrganizationMember(userIds, organizationId, role, isManager, isMember),
    enabled: !!organizationId,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getOrganizationMembers'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getOrganizationMemberDetails'],
      });
    },
  });
};

export const useDeleteOrganizationMember = (organizationId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ userIds, role }) =>
      handleOrganizationMember(userIds, organizationId, role, false, false),
    enabled: !!organizationId,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getOrganizationMembers'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getOrganizationMemberDetails'],
      });
    },
  });
};

export const useUpdateOrganization = (code) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formValues) => updateOrganization(formValues),
    enabled: !!code,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`getOrganizationDetailByCode-${code}`],
      });
    },
  });
};

export const useGetOrganizationFeedHandler = (organizationId) => {
  return useInfiniteQuery({
    queryKey: [`getOrganizationFeed-${organizationId}`],
    queryFn: ({ pageParam = 0 }) => getOrganizationFeed(pageParam, organizationId),
    getNextPageParam: (res) => {
      return res.page + 1;
    },
    enabled: !!organizationId,
    retry: 2,
  });
};

export const useGetOrganizationDetails = (organizations, userId) => {
  return useInfiniteQuery({
    queryKey: [`getOrganizationDetails-${userId}`, organizations],
    queryFn: ({ pageParam = 1 }) => getOrganizationsDetail(organizations[pageParam - 1], pageParam),
    getNextPageParam: (res) => {
      if (organizations.length > res.page) {
        return res.page + 1;
      }
    },
    enabled: organizations.length > 0,
    retry: 2,
  });
};

export const useGetOrganizationCategories = (user) => {
  return useQuery({
    queryKey: ['getOrganizationCategories'],
    queryFn: () => getOrganizationCategories(),
    enabled: !!user,
    retry: 2,
  });
};

export const useCreateOrganizations = (userId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formValues) => createOrganization(formValues),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUserOrganizations', userId],
      });
    },
  });
};

export const useDeleteOrganization = (userId) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (formValues) => deleteOrganization(formValues),
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: ['getUserOrganizations', userId],
        })
        .then(() => navigate('/feed'));
    },
  });
};

export const useGetUserOrganizations = (userId) => {
  return useQuery({
    queryKey: ['getUserOrganizations', userId],
    queryFn: () => getUserOrganizationsHandler(userId),
    retry: 2,
    enabled: !!localStorage.getItem('access_token'),
  });
};

export const useFollowOrganization = (userId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formValues) => followOrganization(formValues),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getOrganizationFollowers'],
      });
      queryClient.invalidateQueries({
        queryKey: [`getUserFollowedOrganizations-${userId}`],
      });
    },
  });
};

export const useGetOrganizationGallery = (organizationId) => {
  return useInfiniteQuery({
    queryKey: ['getOrganizationGallery', organizationId],
    queryFn: ({ pageParam = 1 }) => getOrganizationGallery(organizationId, pageParam),
    getNextPageParam: (res) => {
      return res.page + 1;
    },
    enabled: !!organizationId,
    retry: 2,
  });
};
