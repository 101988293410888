import { Flex, Image, Progress, Tooltip, Typography } from 'antd';
import { timeStampToDate } from '../../../helpers/utils/DateFormatter';

const { Text } = Typography;

const AchievementItem = ({
  rate,
  achievementName,
  achievementDate,
  achievementDescription,
  achievedImage,
  nonAchievedImage,
  achieved,
}) => {
  return (
    <Tooltip title={achievementDescription}>
      <Flex align='center' gap='.5rem'>
        <Image
          className={`object-cover rounded-[8px] min-w-[52px] ${!achieved && 'grayscale'}`}
          preview={false}
          width={52}
          src={achievedImage}
        />
        <Flex vertical={true} className='w-full'>
          <Text>{achievementName}</Text>
          <Text className='text-sm'>{achieved ? timeStampToDate(achievementDate) : '-'}</Text>
          <Progress
            percent={rate}
            status='active'
            strokeColor={
              achieved
                ? {
                    from: '#b2248b',
                    to: '#403490',
                  }
                : {
                    from: '#b1b1b1',
                    to: '#2a2a2a',
                  }
            }
          />
        </Flex>
      </Flex>
    </Tooltip>
  );
};

export default AchievementItem;
