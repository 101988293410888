import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { Flex, Form, Input, Image, Typography, Tooltip, Drawer } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useContext, useEffect, useState } from 'react';
import PrimaryCard from '../cards/PrimaryCard';
import useGetOwnerProfilePhoto from '../../hooks/useGetOwnerProfilePhoto';
import {
  ClockCircleTwoTone,
  DeleteOutlined,
  EditOutlined,
  PushpinTwoTone,
  CloseOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useDeleteEvent, useGetUserEvents } from '../../hooks/queries/EventQueries';
import { UtilityContext } from '../../providers/UtilityProvider';
import { useClientError } from '../../hooks/useClientError';
import { ModalProviderContext } from '../../providers/ModalProvider';
import EventCreateForm from '../form/events/EventCreateForm';
import useWindowSize from '../../hooks/useWindowSize';

const { Text, Paragraph } = Typography;

const UserEvents = ({ eventTags }) => {
  const { width } = useWindowSize();
  const { openModal, closeModal } = useContext(ModalProviderContext);
  const { openNotification, theme } = useContext(UtilityContext);

  const { mutateAsync: deleteEvent } = useDeleteEvent();
  const { data, isLoading } = useGetUserEvents();
  const [filteredData, setFilteredData] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editEvent, setEditEvent] = useState(null);
  const onClientError = useClientError();

  useEffect(() => {
    if (data) {
      setFilteredData(data?.data);
    }
  }, [data]);

  const [userEventsForm] = Form.useForm();
  const translator = useIntl();

  const formChangeHandler = (values) => {
    setFilteredData(
      data.data.filter((event) =>
        event.name.toLocaleLowerCase().includes(values.name.toLocaleLowerCase()),
      ),
    );
  };

  const deleteEventHandler = (id) => {
    openModal({
      title: translator.formatMessage({ id: 'eventDeletion' }),
      content: (
        <Paragraph>
          <FormattedMessage id='eventDeleteWarning' />
        </Paragraph>
      ),
      onOk: () => {
        setDeleteLoading(true);
        deleteEvent(id)
          .then((response) => {
            if (response.status === 202) {
              openNotification('eventDeleteSuccess', 'success');
              closeModal();
            }
          })
          .catch((err) => {
            onClientError(err);
          })
          .finally(() => setDeleteLoading(false));
      },
      okText: translator.formatMessage({ id: 'yes' }),
      okButtonProps: { danger: true, loading: deleteLoading },
      onCancel: closeModal,
    });
  };

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <Flex gap='1rem' vertical={true}>
      <Form form={userEventsForm} onValuesChange={formChangeHandler} layout='vertical'>
        <Form.Item name='name' label={translator.formatMessage({ id: 'eventName' })}>
          <Input placeholder={translator.formatMessage({ id: 'eventName' })} />
        </Form.Item>
      </Form>
      <>
        {filteredData?.map(
          (
            {
              event_attachments,
              name,
              id,
              organizationId,
              user,
              organization,
              event_date,
              address,
            },
            index,
          ) => (
            <PrimaryCard grid={false} key={id}>
              <Flex className='relative' vertical={true} gap='1rem'>
                {event_attachments.length > 0 && (
                  <Flex className='w-full' align='center' justify='center'>
                    <Image
                      preview={true}
                      height={180}
                      width={260}
                      className='rounded-[8px] object-cover'
                      src={event_attachments[0]?.attachmentUrl}
                    />
                  </Flex>
                )}
                <Flex vertical={true} className='text-center'>
                  <Text>{name}</Text>
                  <Text>
                    <ClockCircleTwoTone twoToneColor='#41308f' className='mr-1' />{' '}
                    {dayjs(event_date).format('DD.MM.YYYY')}
                  </Text>
                  <Text>
                    <PushpinTwoTone twoToneColor='#41308f' className='mr-1' /> {address}
                  </Text>
                </Flex>
                <div className='absolute top-0 left-0'>
                  <Tooltip title={translator.formatMessage({ id: 'eventOwner' })} placement='top'>
                    <Image
                      width={40}
                      height={40}
                      className='object-cover rounded-[8px]'
                      src={useGetOwnerProfilePhoto(organizationId, user, organization)}
                      preview={false}
                    />
                  </Tooltip>
                </div>
                <Flex className='absolute right-0 top-0' vertical={true}>
                  <EditOutlined
                    onClick={() => setEditEvent(filteredData[index])}
                    className='cursor-pointer text-yellow-600 hover:bg-yellow-200 p-2 rounded-full'
                  />
                  <DeleteOutlined
                    disabled={deleteLoading}
                    onClick={() => deleteEventHandler(id)}
                    className='cursor-pointer !text-red-600 hover:bg-red-200 p-2 rounded-full'
                  />
                </Flex>
              </Flex>
            </PrimaryCard>
          ),
        )}
      </>
      <Drawer
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        width={width < 480 ? '100vw' : 640}
        destroyOnClose={true}
        onClose={() => setEditEvent(null)}
        open={!!editEvent}
        placement={width < 480 ? 'bottom' : 'right'}
        title={translator.formatMessage({ id: 'eventForm' })}
      >
        <EventCreateForm
          eventDetails={editEvent}
          eventTags={eventTags}
          closer={() => setEditEvent(null)}
        />
      </Drawer>
    </Flex>
  );
};

export default UserEvents;
