import { Spin } from 'antd';
import styles from '../../../style/layout.module.scss';

const SpinWrapper = () => {
  return (
    <div className='p-4 w-full flex items-center justify-center'>
      <Spin />
    </div>
  );
};

export default SpinWrapper;
