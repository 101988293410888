import { Mark, mergeAttributes, textInputRule, markInputRule } from '@tiptap/core';
import Blockquote from '@tiptap/extension-blockquote';
import TypographyTiptap from '@tiptap/extension-typography';
import { Extension } from '@tiptap/core';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import BulletList from '@tiptap/extension-bullet-list';

export const CustomExtension = Extension.create({
  addKeyboardShortcuts() {
    return {
      // ↓ your new keyboard shortcut
      Tab: () => this.editor.commands.insertContent('                '),
      'Alt-p': () => {
        return true;
      },
      'Alt-w': () => {
        return true;
      },
    };
  },
});

const inputRegex = /(?:^|\s)((?:~)((?:[^~]+))(?:~))$/;

export const CustomBulletList = BulletList.extend({
  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: this.type,
      }),
    ];
  },
});

export const TipTapExtensionTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.backgroundColor) {
            return {};
          }

          return {
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
        parseHTML: (element) => {
          return element.style.backgroundColor.replace(/['"]+/g, '');
        },
      },
    };
  },
});

export const TipTapExtensionTableHeader = TableHeader.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      backgroundColor: {
        default: null,
        renderHTML: (attributes) => {
          if (!attributes.backgroundColor) {
            return {};
          }

          return {
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
        parseHTML: (element) => {
          return element.style.backgroundColor.replace(/['"]+/g, '');
        },
      },
    };
  },
});

const lowerThan = textInputRule({
  find: /<=/,
  replace: '≤',
});

const greaterThan = textInputRule({
  find: />=/,
  replace: '≥',
});

export const Typography = TypographyTiptap.extend({
  addInputRules() {
    // eslint-disable-next-line
    return [...this.parent?.(), lowerThan, greaterThan];
  },
});

export const UpperCase = Mark.create({
  name: 'uppercase',

  addOptions() {
    return {
      HTMLAttributes: {
        style: 'text-transform: uppercase',
      },
    };
  },

  parseHTML() {
    return [
      {
        style: 'text-transform=uppercase',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setUpperCase:
        () =>
        ({ commands }) => {
          commands.unsetMark('lowercase');
          return commands.setMark(this.name);
        },
      toggleUpperCase:
        () =>
        ({ commands }) => {
          commands.unsetMark('lowercase');
          return commands.toggleMark(this.name);
        },
      unsetUpperCase:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },
});

export const LowerCase = Mark.create({
  name: 'lowercase',

  addOptions() {
    return {
      HTMLAttributes: {
        style: 'text-transform: lowercase',
      },
    };
  },

  parseHTML() {
    return [
      {
        style: 'text-transform=lowercase',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setLowerCase:
        () =>
        ({ commands }) => {
          commands.unsetMark('uppercase');
          return commands.setMark(this.name);
        },
      toggleLowerCase:
        () =>
        ({ commands }) => {
          commands.unsetMark('uppercase');
          return commands.toggleMark(this.name);
        },
      unsetLowerCase:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },
});

export const Indent = Blockquote.extend({
  content: 'paragraph*',
  name: 'indent',
});

export const TabKeyEnable = Blockquote.extend({
  content: 'paragraph*',
  name: 'indent',
});
