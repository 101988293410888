import React, { createContext, useState, useCallback } from 'react';
import { styles } from '../styles';

export const ModalProviderContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    open: false,
    title: '',
    content: null,
    onOk: () => {},
    onCancel: () => {},
    okText: 'OK',
    okButtonProps: {},
    destroyOnClose: true,
  });

  const openModal = useCallback((config) => {
    setModalState({
      open: true,
      ...config,
    });
  }, []);

  const closeModal = useCallback(() => {
    setModalState({
      open: false,
      title: '',
      content: null,
      onOk: () => {},
      onCancel: () => {},
      okText: 'OK',
      okButtonProps: {},
      destroyOnClose: true,
    });
  }, []);

  return (
    <ModalProviderContext.Provider value={{ modalState, openModal, closeModal }}>
      {children}
    </ModalProviderContext.Provider>
  );
};

export default ModalProvider;
