import { Button, Col, Flex, Form, Image, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { usePostFeed } from '../../hooks/queries/FeedQueries';
import { useClientError } from '../../hooks/useClientError';
import React, { useContext, useState } from 'react';
import { UtilityContext } from '../../providers/UtilityProvider';
import TipTap from '../editor/TipTap';
import styles from '../../style/postCreate.module.scss';
import {
  FileImageTwoTone,
  GifOutlined,
  LeftOutlined,
  RightOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import OutsideClickDetector from '../hoc/OutsideClickDetector';
import PrimaryCard from '../cards/PrimaryCard';
import VideoPlayer from '../post/VideoPlayer';
import Carousel from 'nuka-carousel';

const { Paragraph } = Typography;

const ShareEvent = ({ eventDetail, callBack }) => {
  const { openNotification } = useContext(UtilityContext);
  const { mutateAsync: postFeed } = usePostFeed();
  const [tipTapContent, setTipTapContent] = useState('');
  const [emojiPickerDisplay, setEmojiPickerDisplay] = useState(false);
  const [gifPickerDisplay, setGifPickerDisplay] = useState(false);

  const sanitizedHtml = parse(DOMPurify.sanitize(eventDetail?.description));
  const onClientError = useClientError();

  const shareEventHandler = () => {
    let attachmentArray = [];
    let description = tipTapContent;
    description =
      description +
      `<br><a href='https://www.game.actor/events?eventId=${eventDetail.id}' class='block animate-blink-colors w-full text-center d-flex items-center justify-center'>${eventDetail?.name}</a>`;

    eventDetail?.event_attachments.forEach((attachment) => {
      attachmentArray.push({
        url: attachment.attachmentUrl,
        type: attachment.type,
      });
    });

    postFeed({
      content: description,
      attachmentArray: attachmentArray,
    })
      .then((response) => {
        if (response.status === 202) {
          openNotification('eventShared', 'success');
          callBack();
        }
      })
      .catch((err) => onClientError(err));
  };

  const tipTapOnChangeHandler = (value) => {
    setTipTapContent(value);
  };

  const backFireOfEmojiArea = () => {
    if (emojiPickerDisplay) {
      setEmojiPickerDisplay(!emojiPickerDisplay);
    }
  };

  return (
    <Flex vertical={true} gap='1rem'>
      <OutsideClickDetector onOutsideClick={backFireOfEmojiArea}>
        <Col span={24}>
          <TipTap
            isEmojiVisible={emojiPickerDisplay}
            isGifVisible={gifPickerDisplay}
            emojiViewHandler={() => setEmojiPickerDisplay(!emojiPickerDisplay)}
            gifViewHandler={() => setGifPickerDisplay(!gifPickerDisplay)}
            forwardOnChange={tipTapOnChangeHandler}
            toolbar={false}
          />
        </Col>
        <Col className={styles['actionWrapper']} span={24}>
          <Flex className='mt-4 w-full' gap='1rem'>
            <Button
              onClick={() => {
                setEmojiPickerDisplay(!emojiPickerDisplay);
                setGifPickerDisplay(false);
              }}
              icon={<SmileOutlined />}
            />
            <Button
              onClick={() => {
                setGifPickerDisplay(!gifPickerDisplay);
                setEmojiPickerDisplay(false);
              }}
              icon={<GifOutlined />}
            />
            <Button className='block' onClick={shareEventHandler} type='primary'>
              <FormattedMessage id='create' />
            </Button>
          </Flex>
        </Col>
      </OutsideClickDetector>
      <PrimaryCard grid={false}>
        <Flex gap='1rem' vertical={true} align='center'>
          <Paragraph className='[&>p]:!mb-0 !mb-0'>{sanitizedHtml}</Paragraph>
          <Link
            className='block animate-blink-colors'
            to={`https:/www.game.actor/events?eventId=${eventDetail.id}`}
          >
            <FormattedMessage id='showEvent' />
          </Link>

          {eventDetail?.event_attachments?.length > 1 ? (
            <Carousel
              defaultControlsConfig={{
                nextButtonText: <RightOutlined />,
                prevButtonText: <LeftOutlined />,
              }}
            >
              {eventDetail?.event_attachments.map((attachment, index) => {
                if (attachment.type === 'video') {
                  return (
                    <div key={index}>
                      <VideoPlayer videoUrl={attachment?.attachmentUrl} height={500} />
                    </div>
                  );
                } else if (attachment.type === 'image') {
                  return (
                    <div className='d-flex items-center justify-center rounded-[8px]' key={index}>
                      <Image
                        className='rounded-[8px] max-h-[400px] object-contain'
                        preview={true}
                        src={attachment.attachmentUrl}
                      />
                    </div>
                  );
                }
              })}
            </Carousel>
          ) : (
            <div>
              {eventDetail?.event_attachments &&
                eventDetail?.event_attachments.map((attachment, index) => {
                  if (attachment.type === 'video') {
                    return (
                      <div key={index}>
                        <VideoPlayer videoUrl={attachment.attachmentUrl} height={500} />
                      </div>
                    );
                  } else if (attachment.type === 'image') {
                    return (
                      <div className='d-flex items-center justify-center rounded-[8px]' key={index}>
                        <Image
                          className='rounded-[8px] max-h-[500px]'
                          preview={true}
                          src={attachment.attachmentUrl}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          )}
        </Flex>
      </PrimaryCard>
    </Flex>
  );
};

export default ShareEvent;
