import { useEffect } from 'react';

const RiotFile = () => {
  useEffect(() => {
    const link = document.createElement('a');
    link.href = `${process.env.PUBLIC_URL}/riot.txt`;
    link.download = 'riot.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return (
    <div>
      <h2>
        Your download should start automatically. If not,{' '}
        <a href={`${process.env.PUBLIC_URL}/riot.txt`} download='example.txt'>
          click here
        </a>
        .
      </h2>
    </div>
  );
};

export default RiotFile;
