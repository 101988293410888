import { Col, Flex, Image, Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../style/notifications.module.scss';
import { useUserDetails } from '../../hooks/queries/UserQueries';
import { dateFormatter } from '../../helpers/utils/DateFormatter';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';

const AcceptNotification = ({ notification }) => {
  const { data: profileDetails } = useUserDetails(notification.related_parameter);
  const Text = Typography;

  let timeStamp = dateFormatter(notification.created_at);

  return (
    <div className='w-full bg-white dark:bg-dark-mode-bg-pop-up dark:hover:bg-dark-mode-cream p-4 border-0 border-b border-solid border-white-gray dark:border-dark-mode-notifications-border-color margin-0 cursor-default'>
      <Flex gap='1rem'>
        <Link to={`/profile/${profileDetails?.username}`}>
          <Image
            className='rounded-[8px] object-cover'
            width={55}
            height={45}
            preview={false}
            src={
              profileDetails?.userPhotos[0]?.profile_photo
                ? profileDetails?.userPhotos[0]?.profile_photo
                : defaultProfilePhoto
            }
          />
        </Link>
        <Link to={`/profile/${profileDetails?.username}`}>
          <Text>
            {profileDetails?.first_name} {profileDetails?.last_name}{' '}
            <FormattedMessage id='acceptNotification' />
          </Text>
        </Link>
      </Flex>
      <div className={styles['timeStamp']}>
        <Text className={styles['timeStampText']}>
          <FieldTimeOutlined /> {timeStamp[0]} <FormattedMessage id={timeStamp[1]} />
        </Text>
      </div>
    </div>
  );
};

export default AcceptNotification;
