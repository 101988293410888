import { useInView } from 'react-intersection-observer';
import React, { Fragment, useEffect } from 'react';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { Flex, Image, Typography } from 'antd';
import defaultProfileImage from '../../assets/images/defaultProfilePhoto.jpeg';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';

const { Text, Title } = Typography;

const ListUsers = ({ list, fetchHandler, isLoading, isInfinite = true }) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchHandler();
    }
  }, [inView]);

  if (isInfinite) {
    return (
      <div>
        <Title level={5}>
          <FormattedMessage id='hitList' />
        </Title>
        {list?.pages.map((page, pageIndex) => (
          <Fragment key={pageIndex}>
            {page.rows.map((user, index) => {
              return isLoading ? (
                <SpinWrapper />
              ) : (
                <Link to={`/profile/${user?.username}`}>
                  <Flex
                    ref={index + 1 === page.rows.length ? ref : null}
                    gap='1rem'
                    className='mt-4'
                  >
                    <Image
                      src={user?.photoUrl ? user?.photoUrl : defaultProfileImage}
                      preview={true}
                      width={55}
                      height={45}
                      className='rounded-[8px] object-cover'
                    />
                    <div>
                      <Text>{user.username}</Text>
                      <Text className='text-xs font-normal !text-gray block'>{user.fullname}</Text>
                    </div>
                  </Flex>
                </Link>
              );
            })}
          </Fragment>
        ))}
      </div>
    );
  } else {
    return (
      <Flex vertical={true} gap='1rem'>
        {list.map((user) => {
          return (
            <Link key={user.id} to={`/profile/${user.username}`}>
              <Flex gap='1rem'>
                <Image
                  src={user?.photoUrl ? user?.photoUrl : defaultProfileImage}
                  preview={true}
                  width={55}
                  height={45}
                  className='rounded-[8px] object-cover'
                />
                <div>
                  <Text>{user.username}</Text>
                  <Text className='text-xs font-normal !text-gray block'>{user.fullname}</Text>
                </div>
                {user.type === 1 ? (
                  <CheckOutlined className='ml-auto text-green-700' />
                ) : user.type === 2 ? (
                  <QuestionCircleOutlined className='text-yellow-500' />
                ) : (
                  ''
                )}
              </Flex>
            </Link>
          );
        })}
      </Flex>
    );
  }
};

export default ListUsers;
