import { REQUIRED_RULE } from '../../../helpers/constants/constantHelpers';
import {
  Form,
  Select,
  Space,
  Input,
  Button,
  DatePicker,
  TimePicker,
  Flex,
  Typography,
  Divider,
} from 'antd';
import formStyles from '../../../style/form.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useContext, useRef, useState } from 'react';
import { countries } from '../../../helpers/HelperJSON';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { EnterOutlined, SmileOutlined, GifOutlined } from '@ant-design/icons';
import {
  useCreateComment,
  useCreateReply,
  useEditComment,
} from '../../../hooks/queries/PostActionQueries';
import TipTap from '../../editor/TipTap';
import OutsideClickDetector from '../../hoc/OutsideClickDetector';
import { postMessage } from '../../../requests/UserManager';
import localeEN from 'antd/es/date-picker/locale/en_US';
import locale from 'antd/es/date-picker/locale/tr_TR';
import dayjs from 'dayjs';
import useFormattedDate from '../../../hooks/useFormattedDate';
import { intlLanguageList, intlLanguageFlags } from '../../../helpers/constants/localization';

const { TextArea } = Input;
const { Text } = Typography;

export const SelectComponent = ({
  options = [],
  onChangeRemote,
  labelId,
  translationId,
  required,
  multi,
  formName,
  fieldNames,
  fieldValue,
  filterOption = (input, option) =>
    (option.label?.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase()),
}) => {
  const translator = useIntl();

  if (formName) {
    return (
      <Form.Item
        rules={required && REQUIRED_RULE}
        label={labelId && translator.formatMessage({ id: labelId })}
        name={formName}
      >
        <Select
          className={formStyles['gameSelector']}
          style={{
            width: '100%',
          }}
          filterOption={filterOption}
          placeholder={translator.formatMessage({ id: translationId })}
          onChange={(selectedValue) => (onChangeRemote ? onChangeRemote(selectedValue) : null)}
          options={options}
          fieldNames={
            fieldNames
              ? fieldNames
              : {
                  label: 'label',
                  value: 'value',
                }
          }
          mode={multi ? 'multiple' : ''}
          maxTagCount='responsive'
          allowClear
          showSearch
        />
      </Form.Item>
    );
  } else {
    return (
      <Select
        className={formStyles['gameSelector']}
        style={{
          width: '100%',
        }}
        filterOption={filterOption}
        placeholder={<Text>{translator.formatMessage({ id: translationId })}</Text>}
        onChange={(selectedValue) => (onChangeRemote ? onChangeRemote(selectedValue) : null)}
        options={options}
        fieldNames={
          fieldNames
            ? fieldNames
            : {
                label: 'label',
                value: 'value',
              }
        }
        mode={multi ? 'multiple' : ''}
        maxTagCount='responsive'
        value={fieldValue}
        allowClear
        showSearch
      />
    );
  }
};

export const CountrySelector = ({ withLabel = true, isRequired = false, formName }) => {
  const translator = useIntl();

  if (formName) {
    return (
      <Form.Item
        rules={isRequired && REQUIRED_RULE}
        label={withLabel && translator.formatMessage({ id: 'countryPlaceHolder' })}
        name={formName}
      >
        <Select
          style={{ color: 'white !important' }}
          filterOption={(input, option) =>
            (option.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
          }
          placeholder={translator.formatMessage({ id: 'countryPlaceHolder' })}
        >
          {countries.map((country) => (
            <Select.Option key={country.value} value={country.value} label={country.label}>
              <Space className='text-black dark:text-secondary-color'>
                <span role='img' aria-label='China'>
                  <img className={formStyles['selectorIcon']} src={country.file_url} alt='' />
                </span>
                {country.label}
              </Space>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  } else {
    return (
      <Select
        style={{
          width: '100%',
        }}
        showSearch
        filterOption={(input, option) =>
          (option.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
        }
        placeholder={translator.formatMessage({ id: 'countryPlaceHolder' })}
      >
        <>
          {countries.map((country) => (
            <Select.Option key={country.value} value={country.value} label={country.label}>
              <Space>
                <span role='img' aria-label='China'>
                  <img className={formStyles['selectorIcon']} src={country.file_url} alt='' />
                </span>
                {country.label}
              </Space>
            </Select.Option>
          ))}
        </>
      </Select>
    );
  }
};

export const LanguageSelector = ({ onLanguageChange, value }) => {
  const translator = useIntl();

  const languages = Object.keys(intlLanguageList).map((key) => ({
    value: key,
    label: translator.formatMessage({ id: `language.${key}` }),
    file_url: intlLanguageFlags[key],
  }));

  const handleLanguageChange = (selectedValue) => {
    onLanguageChange(selectedValue);
  };

  return (
    <Select
      style={{ width: '100%' }}
      value={value}
      showSearch
      filterOption={(input, option) =>
        (option.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
      }
      placeholder={translator.formatMessage({ id: 'languagePlaceHolder' })}
      onChange={handleLanguageChange}
    >
      {languages.map((language) => (
        <Select.Option key={language.value} value={language.value} label={language.label}>
          <Space>
            <span role='img' aria-label={language.label}>
              <img className={formStyles['selectorIcon']} src={language.file_url} alt='' />
            </span>
            {language.label}
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

export const InlineEmojiInput = ({
  postId,
  commentId,
  widthFull,
  toolbar = true,
  placeholder,
  chat = false,
  channelId,
  userId,
  defaultValue = '',
  closeModal,
}) => {
  const { openNotification } = useContext(UtilityContext);
  const [emojiPickerDisplay, setEmojiPickerDisplay] = useState(false);
  const [gifPickerDisplay, setGifPickerDisplay] = useState(false);
  const {
    mutateAsync: createComment,
    isPending: createPending,
    reset: createReset,
  } = useCreateComment();
  const { mutateAsync: createReply, isPending: replyPending, reset: replyReset } = useCreateReply();
  const { mutateAsync: editComment, isPending: editPending, reset: editReset } = useEditComment();
  const [commentForm] = Form.useForm();
  const [chatFocus, setChatFocus] = useState(1);

  const chatHandler = async (values) => {
    const message = { user: userId, content: values.content };

    await postMessage(channelId, JSON.stringify(message)).then((response) => {
      commentForm.resetFields();
      setChatFocus((prevState) => prevState + 1);
    });
  };

  const commentHandler = (values) => {
    if (!values.content && defaultValue !== '') {
      return;
    }
    if (!values.content || values.content === '<p></p>') {
      return openNotification('commentHasNoLength', 'warning');
    }
    if (defaultValue && values.content === defaultValue) {
      return;
    }

    if (postId) {
      createComment({
        content: values.content,
        attachments: [],
        relatedFeedId: postId,
      }).then((response) => {
        if (response.status === 202) {
          openNotification('commentSaved', 'success');
          commentForm.resetFields();
          createReset();
        } else {
          openNotification('unknownError', 'error');
          createReset();
        }
      });
    } else if (commentId && defaultValue !== '') {
      editComment({
        content: values.content,
        relatedFeedId: commentId,
      }).then((response) => {
        if (response.status === 202) {
          openNotification('commentEdited', 'success');
          commentForm.resetFields();
          editReset();
        } else {
          openNotification('unknownError', 'error');
          editReset();
        }
        closeModal();
      });
    } else if (commentId) {
      createReply({
        content: values.content,
        attachments: [],
        relatedFeedId: commentId,
      }).then((response) => {
        if (response.status === 202) {
          openNotification('commentSaved', 'success');
          commentForm.resetFields();
          replyReset();
        } else {
          openNotification('unknownError', 'error');
          replyReset();
        }
      });
    }
  };

  const backFireOfEmojiArea = () => {
    if (emojiPickerDisplay) {
      setEmojiPickerDisplay(!emojiPickerDisplay);
    }
  };

  const tipTapOnChangeHandler = (tipTapValue) => {
    commentForm.setFieldValue('content', tipTapValue);
  };

  if (!chat) {
    return (
      <div className={widthFull ? 'w-full' : ''} id='inlineEmojiInput'>
        <Form className='w-full' form={commentForm} onFinish={commentHandler}>
          <OutsideClickDetector onOutsideClick={backFireOfEmojiArea}>
            <Flex gap='1rem' justify='space-between' className='w-full'>
              <Form.Item name='content' className='w-full'>
                <TipTap
                  formFinish={() => commentForm.submit()}
                  comment={postId || commentId}
                  chat={chatFocus}
                  placeholder={placeholder}
                  toolbar={toolbar}
                  isEmojiVisible={emojiPickerDisplay}
                  isGifVisible={gifPickerDisplay}
                  emojiViewHandler={() => {
                    // setEmojiPickerDisplay(!emojiPickerDisplay);
                  }}
                  gifViewHandler={() => {
                    setGifPickerDisplay(!gifPickerDisplay);
                  }}
                  forwardOnChange={tipTapOnChangeHandler}
                  defaultValue={defaultValue}
                />
              </Form.Item>
              <Flex gap='1rem' vertical={true}>
                <Button
                  onClick={() => {
                    setGifPickerDisplay(!gifPickerDisplay);
                    setEmojiPickerDisplay(false);
                  }}
                  icon={<GifOutlined />}
                />
                <Button
                  onClick={() => {
                    setEmojiPickerDisplay(!emojiPickerDisplay);
                    setGifPickerDisplay(false);
                  }}
                  icon={<SmileOutlined />}
                />
                <Button
                  loading={createPending || replyPending || editPending}
                  type='primary'
                  htmlType='submit'
                  icon={<EnterOutlined />}
                />
              </Flex>
            </Flex>
          </OutsideClickDetector>
        </Form>
      </div>
    );
  } else {
    return (
      <div className={widthFull ? 'w-full' : ''} id='inlineEmojiInput'>
        <Form className='w-full' form={commentForm} onFinish={chatHandler}>
          <OutsideClickDetector onOutsideClick={backFireOfEmojiArea}>
            <Flex
              id='chatTipTap'
              vertical={true}
              gap='1rem'
              justify='space-between'
              className='w-full'
            >
              <Form.Item name='content' className='w-full mb-0'>
                <TipTap
                  formFinish={() => commentForm.submit()}
                  comment={postId || commentId}
                  chat={chatFocus}
                  placeholder={placeholder}
                  toolbar={toolbar}
                  isEmojiVisible={emojiPickerDisplay}
                  isGifVisible={gifPickerDisplay}
                  emojiViewHandler={() => {
                    // setEmojiPickerDisplay(!emojiPickerDisplay);
                  }}
                  gifViewHandler={() => {
                    setGifPickerDisplay(!gifPickerDisplay);
                  }}
                  forwardOnChange={tipTapOnChangeHandler}
                />
              </Form.Item>
              <Divider className='my-0' />
              <Flex vertical={false} align='center'>
                <GifOutlined
                  className='p-2 hover:bg-primary dark:hover:bg-primary dark:hover:text-white dark:bg-dark-mode-text-color dark:text-black hover:text-white rounded-full transition-all'
                  onClick={() => {
                    setGifPickerDisplay(!gifPickerDisplay);
                    setEmojiPickerDisplay(false);
                  }}
                />
                <SmileOutlined
                  className='ml-2 p-2 hover:bg-primary hover:text-white rounded-full transition-all'
                  onClick={() => {
                    setEmojiPickerDisplay(!emojiPickerDisplay);
                    setGifPickerDisplay(false);
                  }}
                />

                <Text className='!text-white ml-auto text-sm py-2 px-4 rounded-md bg-primary-color dark:bg-dark-mode-primary-color'>
                  <FormattedMessage id='pressEnterToSend' />
                </Text>
              </Flex>
            </Flex>
          </OutsideClickDetector>
        </Form>
      </div>
    );
  }
};

export const CustomDatePicker = ({ value, onChange, disabledDate, time = false }) => {
  /*locale={user?.language === 'tr' ? locale : localeEN}*/

  return (
    <DatePicker
      disabledDate={disabledDate}
      value={value ? dayjs(value).utc() : null}
      onChange={(value) => onChange(useFormattedDate(value))}
      className='w-full'
      locale={locale}
      showTime={
        time
          ? {
              format: 'HH:mm',
              defaultValue: dayjs('00:00:00', 'HH:mm:ss'),
            }
          : false
      }
      format={time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'}
    />
  );
};
