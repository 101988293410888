import GifPicker from 'gif-picker-react';
import { useContext } from 'react';
import { UtilityContext } from '../../providers/UtilityProvider';

const GifPickerComponent = ({ gifClickCallback }) => {
  const { theme } = useContext(UtilityContext);
  return (
    <div>
      <GifPicker
        locale='tr'
        previewPosition='none'
        theme={theme ? 'dark' : 'light'}
        tenorApiKey='AIzaSyADu_VJoyzCxKaSgj-YTCah_wu9dNEnNt8'
        onGifClick={(gif) => gifClickCallback(gif)}
      />
    </div>
  );
};

export default GifPickerComponent;
