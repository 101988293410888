import {
  Button,
  Col,
  Divider,
  Drawer,
  Empty,
  Flex,
  Image,
  Input,
  Row,
  Space,
  Modal,
  Typography,
} from 'antd';

import {
  LeftOutlined,
  MessageOutlined,
  RightOutlined,
  SmileOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useContext, useEffect, useRef, useState } from 'react';
import { faBomb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { useGetFeedDetails } from '../../hooks/queries/FeedQueries';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import Carousel from 'nuka-carousel';
import { getRelativeTime } from '../../helpers/utils/TimeFormatters';
import EmptyWrapper from '../utility/wrappers/EmptyWrapper';
import CreateComment from './CreateComment';
import styles from '../../style/gallery.module.scss';
import layoutStyles from '../../style/layout.module.scss';
import typographyStyles from '../../style/typography.module.scss';
import { AuthContext } from '../../providers/AuthProvider';
import { useGetLikes, useLikeHandler } from '../../hooks/queries/PostActionQueries';
import ListUsers from '../feed/ListUsers';
import VideoPlayer from './VideoPlayer';
import { Link } from 'react-router-dom';
import { UtilityContext } from '../../providers/UtilityProvider';
import gameActorLogo from '../../assets/images/game-actor-logo.png';
import useWindowSize from '../../hooks/useWindowSize';

const { Paragraph, Text } = Typography;

const PostDetail = ({ postId, share }) => {
  const { width } = useWindowSize();

  const { user } = useContext(AuthContext);
  const { data, isLoading } = useGetFeedDetails(postId, share);
  const { data: likes, fetchNextPage, isLoading: likeListLoading } = useGetLikes(data?.id, user?.id);
  const translator = useIntl();
  const contentRef = useRef();
  const { mutate: likeAndMutate } = useLikeHandler();

  const [loginModal, setLoginModal] = useState(false);
  const [hitList, setHitList] = useState(false);
  const [commentSection, setCommentSection] = useState(false);

  const { organizationCategories, theme } = useContext(UtilityContext);

  const profileAvatar =
    data?.user?.id !== 0 ? data?.user?.userPhotos[0]?.profile_photo : data?.organization?.logo_url;

  useEffect(() => {
    if (!isLoading && data) {
      let message = data?.content;
      if (message) {
        message = message.replaceAll('<p>', '<p> ');
        message = message.replaceAll('</p>', ' </p>');
        let contentArray = message.split(' ');

        contentArray.forEach((word) => {
          if (word.startsWith('#') && !word.includes(';') && !word.includes('"')) {
            message = message.replaceAll(
              word,
              `<a href='/hash/${word.replaceAll('#', '$')}' class='hashtag_link''>${word}</a>`,
            );
          }
        });

        contentRef.current.innerHTML = message;
      }
    }
  }, [data]);

  const likeHandler = () => {
    likeAndMutate(data?.id);
  };

  const commentSectionHandler = () => {
    setCommentSection(!commentSection);
  };

  let hitIcon = (
    <FontAwesomeIcon
      className={layoutStyles['fontAwesomeIcon']}
      icon={faBomb}
      style={{ color: likes?.pages[0].is_liked ? '#c31d1d' : '#959595', marginRight: '.5rem' }}
    />
  );

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <div className={`${styles['imageDetailRow']} ${share && 'w-full'}`}>
      <Flex vertical={true} className={`${styles['postInteractionWrapper']} !mt-0`}>
        <div className={styles['imageOwner']}>
          {!user ? (
            <Image
              className='rounded-[8px] object-cover'
              width={55}
              height={45}
              src={profileAvatar ? profileAvatar : defaultProfilePhoto}
              preview={false}
            />
          ) : (
            <Link
              to={
                data?.user?.id !== 0
                  ? `/profile/${data?.user?.username}`
                  : `/organization/${data?.organization?.code}`
              }
            >
              <Image
                className='rounded-[8px] object-cover'
                width={55}
                height={45}
                src={profileAvatar ? profileAvatar : defaultProfilePhoto}
                preview={false}
              />
            </Link>
          )}
          {!user ? (
            <div className={styles['ownerInfo']}>
              <Text className={styles['userName']}>
                {data?.user?.id !== 0 ? data?.user?.username : data?.organization.name}
                {data?.user?.id !== 0 ? (
                  <Text className={`${typographyStyles['blueFont']} !text-xs !text-gray `}>
                    {' '}
                    • {data?.user?.first_name + ' ' + data?.user?.last_name}
                  </Text>
                ) : null}
              </Text>
              <Text className={`${typographyStyles['blueFont']} !text-xs !text-gray `}>
                {getRelativeTime(data?.created_at)}
              </Text>
            </div>
          ) : (
            <div className={styles['ownerInfo']}>
              <Link
                to={
                  data?.user?.id !== 0
                    ? `/profile/${data?.user?.username}`
                    : `/organization/${data?.organization?.code}`
                }
              >
                <Text className={styles['userName']}>
                  {data?.user?.id !== 0 ? data?.user?.username : data?.organization.name}
                  <Text className={`${typographyStyles['blueFont']} !text-xs !text-gray `}>
                    {' '}
                    •{' '}
                    {data?.user?.id !== 0
                      ? data?.user?.first_name + ' ' + data?.user?.last_name
                      : organizationCategories?.find(
                          (category) => category.id === data?.organization?.categoryId,
                        )?.name}
                  </Text>
                </Text>
              </Link>
              <Text className={`${typographyStyles['blueFont']} !text-xs !text-gray `}>
                {getRelativeTime(data?.created_at)}
              </Text>
            </div>
          )}
        </div>
        <div className={styles['postDescription']}>
          <Paragraph
            ref={contentRef}
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: <FormattedMessage id='more' />,
            }}
          />
        </div>
        <Flex className='mb-4' align='center' justify='center'>
          {data?.feedAttachments?.length > 1 ? (
            <Carousel
              defaultControlsConfig={{
                nextButtonText: <RightOutlined />,
                prevButtonText: <LeftOutlined />,
              }}
            >
              {data?.feedAttachments.map((attachment, index) => {
                if (attachment.type === 'video') {
                  return (
                    <div key={index}>
                      <VideoPlayer
                        videoUrl={attachment.attachmentUrl}
                        height={width > 760 ? 500 : 200}
                      />
                    </div>
                  );
                } else if (attachment.type === 'image') {
                  return (
                    <div key={index} className={styles['postImageWrapper']}>
                      <Image preview={true} src={attachment.attachmentUrl} />
                    </div>
                  );
                }
              })}
            </Carousel>
          ) : (
            data?.feedAttachments &&
            data?.feedAttachments.map((attachment, index) => {
              if (attachment.type === 'video') {
                return (
                  <div key={index}>
                    <VideoPlayer
                      videoUrl={attachment.attachmentUrl}
                      height={width > 760 ? 500 : 200}
                    />
                  </div>
                );
              } else if (attachment.type === 'image') {
                return (
                  <div key={index} className={styles['postImageWrapper']}>
                    <Image preview={true} src={attachment.attachmentUrl} />
                  </div>
                );
              }
            })
          )}
        </Flex>
        <div className={styles['postInfo']}>
          {!user ? (
            <>
              <div>
                {data?.like_count > 0 && (
                  <Text className='cursor-pointer' onClick={() => setLoginModal(true)}>
                    {data?.like_count} <FormattedMessage id='likeCountMessage' />
                  </Text>
                )}
              </div>
              <div>
                {data?.comment_count > 0 && (
                  <Text className='cursor-pointer' onClick={() => setLoginModal(true)}>
                    {data?.comment_count} <FormattedMessage id='comment' />
                  </Text>
                )}
              </div>
            </>
          ) : (
            <>
              <div>
                {likes?.pages[0]?.like_count > 0 && (
                  <Text className='cursor-pointer' onClick={() => setHitList(true)}>
                    {likes?.pages[0].like_count} <FormattedMessage id='likeCountMessage' />
                  </Text>
                )}
              </div>
              <div>
                {data?.comment_count > 0 && (
                  <Text className='cursor-pointer' onClick={commentSectionHandler}>
                    {data?.comment_count} <FormattedMessage id='comment' />
                  </Text>
                )}
              </div>
            </>
          )}
        </div>
        {user && (
          <Flex gap='1rem' className='mt-4'>
            <Button onClick={likeHandler} icon={hitIcon}>
              <Text className='text-inherit'>Hit</Text>
            </Button>
            <Button
              type={commentSection ? 'primary' : 'default'}
              onClick={commentSectionHandler}
              icon={<MessageOutlined />}
            >
              <Text className='text-inherit'>
                <FormattedMessage id='comment' />
              </Text>
            </Button>
          </Flex>
        )}
      </Flex>
      <Divider />
      <div className={styles['postInteractionWrapper']}>
        <Flex vertical={true} className={styles['postActions']}>
          <div className='mx-auto max-h-[750px] pr-4'>
            {commentSection && (
              <div className={styles['commentSection']}>
                <CreateComment postDetails={data} />
              </div>
            )}
          </div>
        </Flex>
      </div>
      <Drawer
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        width={320}
        destroyOnClose={true}
        onClose={() => setHitList(false)}
        open={hitList}
        placement={width < 480 ? 'bottom' : 'right'}
      >
        <ListUsers fetchHandler={fetchNextPage} isLoading={likeListLoading} list={likes} />
      </Drawer>
      <Modal
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        centered
        open={loginModal}
        onCancel={() => setLoginModal(false)}
        footer={() => null}
      >
        <div className='flex flex-col'>
          <Image className='max-w-[135px]' preview={false} src={gameActorLogo} alt='Game Actor' />
          <div style={{ marginTop: '13px' }}>
            {' '}
            <FormattedMessage id='loginModalInformation' />
          </div>
        </div>
        <div className='flex justify-end mt-4'>
          <Button className='!bg-primary !text-white ' type='primary'>
            <Link to='/'>
              <Text className='text-inherit'>
                <FormattedMessage id='login' />
              </Text>
            </Link>
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default PostDetail;
