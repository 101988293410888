import {
  Button,
  Col,
  Form,
  Input,
  Row,
  notification,
  InputNumber,
  Typography,
  Checkbox,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from '../../../style/form.module.scss';
import { Twitch } from '../../../helpers/Icons';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  FacebookFilled,
  InstagramFilled,
  MailFilled,
  PhoneFilled,
  ThunderboltOutlined,
  UserOutlined,
  XOutlined,
  YoutubeFilled,
} from '@ant-design/icons';
import { CountrySelector } from '../filters/Inputs';
import { setPersonalInformationRequest } from '../../../requests/UserManager';
import { useClientError } from '../../../hooks/useClientError';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../providers/AuthProvider';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { REQUIRED_RULE, usernameRegex } from '../../../helpers/constants/constantHelpers';
import layoutStyles from '../../../style/layout.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKickstarterK } from '@fortawesome/free-brands-svg-icons';

const { Text } = Typography;

const PersonalInformationForm = ({ modalHandler, userPageInformation, callBackAndFetch }) => {
  const [loading, setLoading] = useState(false);
  const [personalInformationForm] = Form.useForm();
  const { openNotification } = useContext(UtilityContext);
  const { user } = useContext(AuthContext);
  const { TextArea } = Input;
  const translator = useIntl();
  const onClientError = useClientError();

  const personalInformationFormHandler = (formValues) => {
    setLoading(true);
    let formObject = {
      userId: user.id,
      ...formValues,
    };

    setPersonalInformationRequest(formObject)
      .then((response) => {
        if (response.status === 202) {
          setLoading(false);
          openNotification('infoSaved', 'success');
          callBackAndFetch('pi');
        } else {
          openNotification('infoSaveError', 'error');
          setLoading(false);
        }
      })
      .catch((err) => {
        onClientError(err);
        setLoading(false);
      });
  };

  const validateUsername = (_, value) => {
    if (!usernameRegex.test(value) && value) {
      return Promise.reject(translator.formatMessage({ id: 'usernameNotValid' }));
    }
    return Promise.resolve();
  };

  return (
    <Form
      initialValues={{
        country: userPageInformation?.country?.length > 0 ? userPageInformation.country : null,
        description:
          userPageInformation.description?.length > 0 ? userPageInformation.description : null,
        facebook: userPageInformation.facebook?.length > 0 ? userPageInformation.facebook : null,
        instagram: userPageInformation.instagram?.length > 0 ? userPageInformation.instagram : null,
        phoneNumber:
          userPageInformation.phoneNumber?.length > 0 ? userPageInformation.phoneNumber : null,
        twitch: userPageInformation.twitch?.length > 0 ? userPageInformation.twitch : null,
        twitter: userPageInformation.twitter?.length > 0 ? userPageInformation.twitter : null,
        userId: 0,
        youtube: userPageInformation.youtube?.length > 0 ? userPageInformation.youtube : null,
        firstName: userPageInformation.firstName?.length > 0 ? userPageInformation.firstName : null,
        lastName: userPageInformation.lastName?.length > 0 ? userPageInformation.lastName : null,
        username: user?.username,
        kick: userPageInformation.kick?.length > 0 ? userPageInformation.kick : null,
        badge_active: user?.badge_active,
      }}
      onFinish={personalInformationFormHandler}
      form={personalInformationForm}
    >
      <Form.Item
        name='description'
        rules={[{ max: 250, message: translator.formatMessage({ id: 'formLenghtMessageLong' }) }]}
      >
        <TextArea
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          maxLength={308}
          rows={4}
          placeholder={translator.formatMessage({ id: 'aboutPlaceHolder' })}
        />
      </Form.Item>
      <CountrySelector withLabel={false} formName='country' />
      <Form.Item
        name='firstName'
        rules={[
          { required: true, message: '' },
          { max: 30, message: translator.formatMessage({ id: 'formLenghtMessageShort' }) },
        ]}
      >
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          prefix={<UserOutlined style={{ color: '#f01d1d' }} />}
          placeholder={translator.formatMessage({ id: 'enterFirstName' })}
        />
      </Form.Item>
      <Form.Item
        name='lastName'
        rules={[
          { required: true, message: '' },
          { max: 30, message: translator.formatMessage({ id: 'formLenghtMessageShort' }) },
        ]}
      >
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          prefix={<UserOutlined style={{ color: '#f01d1d' }} />}
          placeholder={translator.formatMessage({ id: 'enterLastName' })}
        />
      </Form.Item>
      <Form.Item
        rules={[
          { required: true, message: '' },
          { max: 30, message: translator.formatMessage({ id: 'formLenghtMessageShort' }) },
          { validator: validateUsername },
        ]}
        name='username'
      >
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          prefix={<ThunderboltOutlined style={{ color: '#24b9d3' }} />}
          placeholder={translator.formatMessage({ id: 'username' })}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            pattern: new RegExp('^[0-9+]*$'),
            message: translator.formatMessage({ id: 'phoneFormatError' }),
          },
        ]}
        name='phoneNumber'
      >
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          controls={false}
          prefix={<PhoneFilled style={{ color: '#24d366' }} />}
          placeholder={translator.formatMessage({ id: 'phone' })}
        />
      </Form.Item>
      <Form.Item
        name='twitter'
        rules={[
          {
            pattern: /x.com/,
            message: translator.formatMessage({ id: 'xLinkInputFieldInvalid' }),
          },
        ]}
      >
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          prefix={<XOutlined style={{ color: '#000000' }} />}
          placeholder={translator.formatMessage({ id: 'twitterPlaceHolder' })}
        />
      </Form.Item>
      <Form.Item
        name='facebook'
        rules={[
          {
            pattern: /facebook.com/,
            message: translator.formatMessage({ id: 'facebookLinkInputFieldInvalid' }),
          },
        ]}
      >
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          prefix={<FacebookFilled style={{ color: '#344e86' }} />}
          placeholder={translator.formatMessage({ id: 'facebookPlaceHolder' })}
        />
      </Form.Item>
      <Form.Item
        name='instagram'
        rules={[
          {
            pattern: /instagram.com/,
            message: translator.formatMessage({ id: 'instagramLinkInputFieldInvalid' }),
          },
        ]}
      >
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          prefix={<InstagramFilled style={{ color: '#e1306c' }} />}
          placeholder={translator.formatMessage({ id: 'instagramPlaceHolder' })}
        />
      </Form.Item>
      <Form.Item
        name='youtube'
        rules={[
          {
            pattern: /youtube.com/,
            message: translator.formatMessage({ id: 'youtubeLinkInputFieldInvalid' }),
          },
        ]}
      >
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          prefix={<YoutubeFilled style={{ color: '#ff0000' }} />}
          placeholder={translator.formatMessage({ id: 'youtubePlaceHolder' })}
        />
      </Form.Item>
      <Form.Item
        name='twitch'
        rules={[
          {
            pattern: /twitch.tv/,
            message: translator.formatMessage({ id: 'twitchLinkInputFieldInvalid' }),
          },
        ]}
      >
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          prefix={<Twitch width='14px' height='24px' viewBox='15 0 10 40' />}
          placeholder={translator.formatMessage({ id: 'twitchPlaceHolder' })}
        />
      </Form.Item>
      <Form.Item
        name='kick'
        rules={[
          {
            pattern: /kick.com/,
            message: translator.formatMessage({ id: 'kickLinkInputFieldInvalid' }),
          },
        ]}
      >
        <Input
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faKickstarterK}
              style={{
                color: '#53fc18',
              }}
            />
          }
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'kickPlaceHolder' })}
        />
      </Form.Item>
      <Form.Item name='badge_active' valuePropName='checked'>
        <Checkbox>
          <FormattedMessage id='steamBadgeStatus' />
        </Checkbox>
      </Form.Item>
      <div className={styles['actionWrapper']}>
        <Button
          onClick={modalHandler}
          icon={<CloseCircleFilled style={{ color: '#D61C1C', marginRight: '.5rem' }} />}
        >
          <Text className='text-inherit'>
            <FormattedMessage id='cancel' />
          </Text>
        </Button>
        <Button
          loading={loading}
          htmlType='submit'
          type='primary'
          icon={<CheckCircleFilled style={{ color: '#fff', marginRight: '.5rem' }} />}
        >
          <Text className='text-inherit'>
            <FormattedMessage id='ok' />
          </Text>
        </Button>
      </div>
    </Form>
  );
};

export default PersonalInformationForm;
