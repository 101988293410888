import { Col, Image, Row, Typography } from 'antd';
const { Text } = Typography;
import styles from '../../style/streamers.module.scss';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';

const Streamers = ({ callBackHandler }) => {
  return (
    <Row gutter={[8, 8]} className={styles['streamersRow']}>
      <Col
        onClick={() => callBackHandler(1)}
        key={1}
        className={`${styles['streamerWrapper']} border  border-t-0 border-r-0 border-l-0 border-solid border-background-color dark:border-dark-mode-edit-hover-color-background`}
        span={24}
      >
        <Image
          className={`${styles['streamerOnline']} !shadow-[0_0_6px]   !shadow-[#0d910d]`}
          src={defaultProfilePhoto}
          preview={false}
        />
        <Text className={styles['statusOnline']}></Text>
        <Text className={styles['streamerNick']}>Yayıncı Adı</Text>
      </Col>
      <Col
        onClick={() => callBackHandler(2)}
        key={2}
        className={`${styles['streamerWrapper']} border border-t-0 border-r-0 border-l-0 border-solid border-background-color dark:border-dark-mode-edit-hover-color-background`}
        span={24}
      >
        <Image
          className={`${styles['streamerOffline']} shadow-[0_0_6px] shadow-[rgb(255,0,0)]`}
          src={defaultProfilePhoto}
          preview={false}
        />
        <span className={styles['statusOffline']}></span>
        <span className={styles['streamerNick']}>Yayıncı Adı</span>
      </Col>
    </Row>
  );
};

export default Streamers;
