import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, Form, Row, Typography, Upload, message, Image } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { UtilityContext } from '../../providers/UtilityProvider';
import { REQUIRED_RULE } from '../constants/constantHelpers';

const { Text } = Typography;

const FileUploader = ({
  placeholderId,
  multiple = false,
  formName,
  placeholderInline,
  bucketCallBack,
  accept,
  split = true,
  required = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState([]);
  const { openNotification } = useContext(UtilityContext);

  const translator = useIntl();

  async function getBase64(fileList) {
    if (fileList.length === 1) {
      let reader = new FileReader();
      reader.readAsDataURL(fileList[0].originFileObj);
      reader.onload = function () {
        setImageUrl([reader.result]);
        setLoading(false);
        return reader.result;
      };
    } else if (fileList.length > 1) {
      let fileArray = [];
      for (let file of fileList) {
        const base64 = await getBase64Handler(file);
        fileArray.push(base64);
      }
      setImageUrl(fileArray);
    } else if (fileList.length === 0) {
      setImageUrl([]);
    }
  }

  const getBase64Handler = (file) => {
    let fileReader = new FileReader();
    if (file) {
      fileReader.readAsDataURL(file.originFileObj);
    }
    return new Promise((resolve, reject) => {
      fileReader.onload = function (event) {
        resolve(event.target.result);
      };
    });
  };

  const handleChange = (props) => {
    setLoading(true);
    const isError = props.fileList.filter((i) => i.status === 'error');
    const errorMessage = () => {
      openNotification('fileUploadFailed', 'error');
    };
    if (isError.length > 0) {
      setLoading(false);
      errorMessage();
      return;
    }
    getBase64(props.fileList);
    setFileList(props.fileList);
    setLoading(false);
  };

  useEffect(() => {
    if (imageUrl.length > 0) {
      if (fileList.length === 1) {
        let fileUploadObject = {
          base64: split ? imageUrl[0].split(`data:${fileList[0].type};base64,`)[1] : imageUrl[0],
          key: fileList[0].name,
          contentType: fileList[0].type,
          size: fileList[0].size,
        };
        bucketCallBack(fileUploadObject);
      } else {
        let fileArray = [];
        for (let i = 0; i < fileList.length; i++) {
          fileArray.push({
            base64: split ? imageUrl[i].split(`data:${fileList[i].type};base64,`)[1] : imageUrl[i],
            key: fileList[i].name,
            contentType: fileList[i].type,
            size: fileList[i].size,
          });
        }
        bucketCallBack(fileArray);
      }
      //TODO Burada multi dosya yüklendiğinde bucketdan gelecek olan datayı handle etmek gerek
    } else {
      bucketCallBack([]);
    }
  }, [imageUrl]);

  return (
    <Form.Item
      name={formName}
      label={placeholderId && translator.formatMessage({ id: placeholderId })}
      required={required && REQUIRED_RULE}
    >
      <Upload
        accept={accept ? accept : '-'}
        listType='picture-card'
        multiple={multiple}
        onChange={handleChange}
        fileList={fileList}
        beforeUpload={(file) => {
          if (multiple) {
            let newFileListArray = [...fileList];
            newFileListArray.push(file);
            setFileList(newFileListArray);
            return false;
          } else {
            setFileList([file]);
            return false;
          }
        }}
        style={{ width: '100%', marginRight: 0 }}
      >
        {loading ? (
          <LoadingOutlined />
        ) : (
          <Row>
            <Col span={24}>
              <PlusOutlined />
            </Col>
            <Col span={24}>
              <Text
                strong
                style={{
                  textAlign: 'center',
                  marginLeft: '0.25rem',
                }}
              >
                {placeholderInline ? (
                  <FormattedMessage id={placeholderInline} />
                ) : (
                  <FormattedMessage id={placeholderId} />
                )}
              </Text>
            </Col>
          </Row>
        )}
      </Upload>
    </Form.Item>
  );
};

export default FileUploader;
