import { Table, Typography } from 'antd';
import { useState } from 'react';

const ReusableTable = ({
  columns,
  dataSource,
  loading,
  expandable,
  onRow,
  rowClassName,
  rowKey,
  onChange,
  rowSelection,
  id,
  pagination,
  scroll,
}) => {
  const { Text } = Typography;
  return (
    <Table
      bordered
      scroll={scroll}
      columns={columns}
      dataSource={dataSource}
      pagination={pagination}
      id={id}
      rowSelection={rowSelection}
      onChange={onChange}
      rowKey={rowKey}
      loading={loading}
      rowClassName={rowClassName}
      onRow={onRow}
      expandable={expandable}
    />
  );
};

export default ReusableTable;
