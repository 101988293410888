import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import {
  answerRequest,
  followUserAction,
  getConnectionDetail,
  getFollowers,
  getNotifications,
  getPersonalInformation,
  getRequestDetail,
  getRequests,
  getUserAccessoryInformation,
  getUserConnections,
  getUserDetailsWithCode,
  getUserFollowedOrganizations,
  getUserGallery,
  getUserPlatformInformation,
  getUserSystemInformation,
  saveUserAccessoryInformation,
  saveUserPlatformInformation,
  saveUserSystemInformation,
  sendConnectionRequestAction,
  updateProfileImages,
  getChatChannels,
  getChannelMessages,
  checkConnection,
  removeConnection,
  createUserChatChannel,
  getUnreadChatChannels,
  readMessage,
  userBlockHandler,
  getUserBlockHandler,
  userUnblockHandler,
  getUserDetailsByIdHandler,
  saveUserDevice,
  deleteUserDevice,
  activatedUserHandler,
} from '../../requests/UserManager';
import { toast } from 'react-toastify';

import { changeUserPassword } from '../../requests/UserInitiatorManager';

export const useAnswerRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (requestObject) => answerRequest(requestObject),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getRequests'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getConnectionStatus'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getUserConnections'],
      });
    },
  });
};

export const useCreateChatChannel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (requestObject) => createUserChatChannel(requestObject),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getChatChannels'],
      });
    },
  });
};

export const useFollowUser = (userId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (followerObject) => followUserAction(followerObject),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`getFollowers-${userId}`],
      });
      queryClient.invalidateQueries({
        queryKey: ['getFeed'],
      });
    },
    enabled: !!userId,
  });
};

export const useGetConnectionDetails = (connection, userId) => {
  return useInfiniteQuery({
    queryKey: [`getConnectionDetails-${userId}`, connection],
    queryFn: ({ pageParam = 1 }) => getConnectionDetail(connection[pageParam - 1], pageParam),
    getNextPageParam: (res) => {
      if (connection.length > res.page) {
        return res.page + 1;
      }
    },
    enabled: connection.length > 0,
    retry: 2,
  });
};

export const useFollowers = (userId) => {
  return useQuery({
    queryKey: [`getFollowers-${userId}`],
    queryFn: () => getFollowers(userId),
    retry: 2,
    enabled: !!userId,
  });
};

export const useGetFollowerDetails = (connection, userId) => {
  return useInfiniteQuery({
    queryKey: [`getFollowerDetails-${userId}`, connection],
    queryFn: ({ pageParam = 1 }) => getConnectionDetail(connection[pageParam - 1], pageParam),
    getNextPageParam: (res) => {
      if (connection.length > res.page) {
        return res.page + 1;
      }
    },
    enabled: connection.length > 0,
    retry: 2,
  });
};

export const useChatChannels = (user) => {
  return useQuery({
    queryKey: ['getChatChannels'],
    queryFn: () => getChatChannels(),
    enabled: !!user,
    retry: 2,
  });
};

export const useGetUnreadChatChannels = (user) => {
  return useQuery({
    queryKey: ['getUnreadChatChannels'],
    queryFn: () => getUnreadChatChannels(),
    refetchInterval: 15000,
    enabled: !!user,
    retry: 2,
  });
};

export const useChannelMessages = (chatChannelId) => {
  return useQuery({
    queryKey: ['getChannelMessages', chatChannelId],
    queryFn: () => getChannelMessages(chatChannelId),
    retry: 2,
    enabled: !!chatChannelId,
  });
};

export const useReadMessage = () => {
  return useMutation({
    mutationFn: (chatChannelId) => readMessage(chatChannelId),
    retry: 2,
  });
};

export const useGetNotifications = (userId) => {
  return useQuery({
    queryKey: ['getNotifications'],
    queryFn: () => getNotifications(userId),
    retry: 2,
    refetchInterval: 300000,
    enabled: !!userId,
  });
};

export const usePersonalInformation = (userId) => {
  return useQuery({
    queryKey: [`getPersonalInformation-${userId}`],
    queryFn: () => getPersonalInformation(userId),
    retry: 2,
    enabled: !!userId,
  });
};

export const useMutatePersonalInformation = (userId, code) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => getPersonalInformation(userId),
    retry: 2,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`getPersonalInformation-${userId}`],
      });
      queryClient.invalidateQueries({
        queryKey: ['getUserDetails', code],
      });
    },
    enabled: !!userId,
  });
};

export const useGetRequestDetail = (requestId) => {
  return useQuery({
    queryKey: [`getRequestDetail-${requestId}`],
    queryFn: () => getRequestDetail(requestId),
    retry: 2,
    enabled: !!requestId,
  });
};

export const useGetRequests = (userId) => {
  return useQuery({
    queryKey: ['getRequests'],
    queryFn: () => getRequests(userId),
    refetchInterval: 300000,
    retry: 2,
    enabled: !!userId,
  });
};

export const useAccessoryInformation = (userId) => {
  return useQuery({
    queryKey: [`getAccessoryInformation-${userId}`],
    queryFn: () => getUserAccessoryInformation(userId),
    retry: 2,
    enabled: !!userId,
  });
};

export const useUserConnections = (userId) => {
  return useQuery({
    queryKey: ['getUserConnections', userId],
    queryFn: () => getUserConnections(userId),
    retry: 2,
    enabled: !!userId,
  });
};

export const useUserDetails = (code) => {
  return useQuery({
    queryKey: ['getUserDetails', code],
    queryFn: () => getUserDetailsWithCode(code),
    retry: 2,
    enabled: !!code,
  });
};

export const useUserFollowedOrganizations = (userId) => {
  return useQuery({
    queryKey: [`getUserFollowedOrganizations-${userId}`],
    queryFn: () => getUserFollowedOrganizations(userId),
    retry: 2,
    enabled: !!userId,
  });
};

export const usePlatformInformation = (userId) => {
  return useQuery({
    queryKey: [`getPlatformInformation-${userId}`],
    queryFn: () => getUserPlatformInformation(userId),
    retry: 2,
    enabled: !!userId,
  });
};

export const useSystemInformation = (userId) => {
  return useQuery({
    queryKey: [`getSystemInformation-${userId}`],
    queryFn: () => getUserSystemInformation(userId),
    retry: 2,
    enabled: !!userId,
  });
};

export const useUpdateAccessoriesInformation = (userId) => {
  const queryClient = useQueryClient();
  const translator = useIntl();

  return useMutation({
    mutationFn: (formValues) => saveUserAccessoryInformation(formValues),
    onSuccess: () => {
      toast.success(translator.formatMessage({ id: 'accessoryInformationSaved' }));
      queryClient.invalidateQueries({
        queryKey: [`getAccessoryInformation-${userId}`],
      });
    },
    onError: () => {
      toast.error(translator.formatMessage({ id: 'unknownError' }));
    },
  });
};

export const useUpdatePlatformInformation = (userId) => {
  const queryClient = useQueryClient();
  const translator = useIntl();

  return useMutation({
    mutationFn: (formValues) => saveUserPlatformInformation(formValues),
    onSuccess: () => {
      toast.success(translator.formatMessage({ id: 'platformSaved' }));
      queryClient.invalidateQueries({
        queryKey: [`getPlatformInformation-${userId}`],
      });
    },
    onError: () => {
      toast.error(translator.formatMessage({ id: 'unknownError' }));
    },
  });
};

export const useUpdateSystemInformation = (userId) => {
  const queryClient = useQueryClient();
  const translator = useIntl();

  return useMutation({
    mutationFn: (formValues) => saveUserSystemInformation(formValues),
    onSuccess: () => {
      toast.success(translator.formatMessage({ id: 'systemInformationSaved' }));
      queryClient.invalidateQueries({
        queryKey: [`getSystemInformation-${userId}`],
      });
    },
    onError: () => {
      toast.error(translator.formatMessage({ id: 'systemInformationSaveFailed' }));
    },
  });
};

export const useConnectionRequest = (userId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (followerObject) => sendConnectionRequestAction(followerObject),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUserConnections'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getFeed'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getConnectionStatus'],
      });
    },
    enabled: !!userId,
  });
};

export const useRemoveConnection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userId) => removeConnection(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUserConnections'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getConnectionStatus'],
      });
    },
  });
};

export const useCheckConnectionRequest = (profileId) => {
  return useQuery({
    queryKey: ['getConnectionStatus', profileId],
    queryFn: () => checkConnection(profileId),
    retry: 2,
    enabled: !!profileId,
  });
};

export const useUpdateUserImages = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formValues) => updateProfileImages(formValues),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUserDetails'],
      });
    },
  });
};

export const useGetUserGallery = (userId) => {
  return useInfiniteQuery({
    queryKey: ['getUserGallery', userId],
    queryFn: ({ pageParam = 1 }) => getUserGallery(userId, pageParam),
    getNextPageParam: (res) => {
      return res.page + 1;
    },
    enabled: !!userId,
    retry: 2,
  });
};

export const useChangeUserPassword = () => {
  return useMutation({
    mutationFn: (payload) => changeUserPassword(payload),
  });
};

export const useGetUserBlock = () => {
  return useQuery({
    queryKey: ['getUserBlockList'],
    queryFn: () => getUserBlockHandler(),
    retry: 2,
  });
};

export const useUserBlock = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => userBlockHandler(payload),
    retry: 2,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUserBlockList'],
      });
    },
  });
};

export const useUserUnblock = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => userUnblockHandler(payload),
    retry: 2,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getUserBlockList'],
      });
    },
  });
};

export const useGetUserDetailsById = (userIdArray) => {
  return useInfiniteQuery({
    queryKey: ['getUserDetailsById', userIdArray],
    queryFn: ({ pageParam = 1 }) => getUserDetailsByIdHandler(userIdArray, pageParam),
    getNextPageParam: (res) => {
      return res.page + 1;
    },
    enabled: !!userIdArray,
    retry: 2,
  });
};

export const useSaveUserDevice = () => {
  return useMutation({
    mutationFn: (token) => saveUserDevice(token),
    retry: 2,
  });
};

export const useDeleteUserDevice = () => {
  return useMutation({
    mutationFn: (token) => deleteUserDevice(token),
    retry: 2,
  });
};

export const useActivatedUser = () => {
  return useMutation({
    mutationFn: (userKey) => activatedUserHandler(userKey),
    retry: 2,
  });
};
