import { Col, Empty, Flex, Row, Space, Table, Tag, Typography } from 'antd';
import styles from '../../style/toolbar.module.scss';
import {
  CloseOutlined,
  FullscreenOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
  ToolTwoTone,
} from '@ant-design/icons';
import SteamFeaturedCard from '../cards/SteamFeaturedCard';
import { useIntl } from 'react-intl';
import { useGetFeaturedSteam } from '../../hooks/queries/IntegrationQueries';
import SpinWrapper from './wrappers/SpinWrapper';
import { useEffect, useRef, useState } from 'react';
import { steamFeaturedNameHandler } from '../../helpers/constants/constantHelpers';

const { Title } = Typography;

const SteamFeatured = () => {
  const { data: steamFeatured, isLoading } = useGetFeaturedSteam();
  const [groupedData, setGroupedData] = useState([]);

  const featuredRowRef = useRef([]);

  useEffect(() => {
    if (steamFeatured?.length > 0) {
      const groupedData = steamFeatured.reduce((acc, game) => {
        const featuredType = game.type;
        if (!acc[featuredType]) {
          acc[featuredType] = [];
        }
        if (acc[featuredType].filter((i) => i.steamapp_id === game.steamapp_id).length === 0) {
          acc[featuredType].push(game);
        }
        return acc;
      }, {});

      let newData = Object.keys(groupedData).map((type) => ({
        type,
        games: groupedData[type],
      }));

      setGroupedData(newData);
      featuredRowRef.current = featuredRowRef.current.slice(0, groupedData.length);
    }
  }, [steamFeatured]);

  const leftScroller = (index) => {
    const width = featuredRowRef.current[index]?.offsetWidth;

    featuredRowRef.current[index]?.scrollTo({
      left: featuredRowRef.current[index]?.scrollLeft - width,
      behavior: 'smooth',
    });
  };

  const rightScroller = (index) => {
    const width = featuredRowRef.current[index]?.offsetWidth;

    featuredRowRef.current[index]?.scrollTo({
      left: featuredRowRef.current[index]?.scrollLeft + width,
      behavior: 'smooth',
    });
  };

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <Row gutter={[16, 16]}>
      <>
        {groupedData?.map(({ type, games }, index) => {
          if (type.toString() !== '1' && type.toString() !== '4') {
            return (
              <Col span={24} key={index}>
                <Flex justify='space-between'>
                  <Title level={4}>{steamFeaturedNameHandler(type)}</Title>
                  <Flex gap='1rem'>
                    <LeftCircleOutlined
                      onClick={() => leftScroller(index)}
                      className='text-[18px] cursor-pointer hover:text-secondary'
                    />
                    <RightCircleOutlined
                      onClick={() => rightScroller(index)}
                      className='text-[18px] cursor-pointer hover:text-secondary'
                    />
                  </Flex>
                </Flex>
                <Row
                  gutter={[16, 16]}
                  className='overflow-auto flex-nowrap no-scrollbar max-w-game-statistics-width'
                  ref={(el) => (featuredRowRef.current[index] = el)}
                >
                  {games.map(
                    (
                      {
                        discount_percent,
                        header_image,
                        final_price,
                        original_price,
                        steamapp,
                        name,
                      },
                      index,
                    ) => {
                      return (
                        <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={12} key={index}>
                          <SteamFeaturedCard
                            discountPercent={discount_percent}
                            headerImage={header_image}
                            finalPrice={final_price}
                            originalPrice={original_price}
                            steamApp={steamapp}
                            name={name}
                          />
                        </Col>
                      );
                    },
                  )}
                </Row>
              </Col>
            );
          }
        })}
      </>
    </Row>
  );
};

export default SteamFeatured;
