import { Row, Col, Typography, Image, Flex, Modal, Divider } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import {
  useGetUserBlock,
  useGetUserDetailsById,
  useUserBlock,
} from '../../hooks/queries/UserQueries';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { useIntl } from 'react-intl';
import EmptyWrapper from '../utility/wrappers/EmptyWrapper';

const { Text } = Typography;
const BlockedList = () => {
  const { data: blockUserIdList } = useGetUserBlock();
  const { data: blockedUserList, fetchNextPage } = useGetUserDetailsById(blockUserIdList);
  const { mutateAsync: unBlockUser } = useUserBlock(true);
  const translator = useIntl();

  const deleteBlocked = (userId) => {
    Modal.confirm({
      title: translator.formatMessage({ id: 'unblockUserConfirm' }),
      okText: translator.formatMessage({ id: 'ok' }),
      okType: 'danger',
      cancelText: translator.formatMessage({ id: 'cancel' }),
      onOk() {
        unBlockUser(userId);
      },
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <>
        {blockedUserList && <EmptyWrapper description={translator.formatMessage({ id: 'noBlockedUser' })}/>}
        {blockedUserList?.pages.map((items, index) =>
          items?.map((item, index) => (
            <Col key={index} span={24}>
              <Flex align='center' justify='space-between'>
                <Flex align='center' gap={8}>
                  <Image
                    className='rounded-[8px] object-cover'
                    width={40}
                    height={40}
                    preview={false}
                    src={item.photoUrl ? item.photoUrl : defaultProfilePhoto}
                  />
                  <Text>{item.fullname}</Text>
                </Flex>
                <FontAwesomeIcon
                  onClick={() => deleteBlocked(item.id)}
                  className='cursor-pointer text-green-700 text-xl transition-all duration-250 rounded-full outline-none'
                  icon={faBan}
                />
              </Flex>
              <Divider />
            </Col>
          )),
        )}
      </>
    </Row>
  );
};

export default BlockedList;
