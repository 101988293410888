import dayjs from 'dayjs';
import { FormattedMessage, useIntl } from 'react-intl';

export const dateFormatter = (date) => {
  let secondDiff = dayjs().diff(dayjs(date), 'second', false);
  let returnedDate = secondDiff;
  let type = 'secondBefore';
  if (secondDiff > 60) {
    let minuteDiff = dayjs().diff(dayjs(date), 'minute', false);
    returnedDate = minuteDiff;
    type = 'minuteBefore';
    if (minuteDiff > 60) {
      let hourDiff = dayjs().diff(dayjs(date), 'hour', false);
      returnedDate = hourDiff;
      type = 'hourBefore';
      if (hourDiff > 24) {
        returnedDate = dayjs().diff(dayjs(date), 'days', false);
        type = 'dayBefore';
      }
    }
  }
  return [returnedDate, type];
};

export const getHoursAndMinutes = (minutes, full = false) => {
  const translator = useIntl();

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (full) {
    return `${hours} ${translator.formatMessage({
      id: 'hour',
    })} ${remainingMinutes} ${translator.formatMessage({ id: 'minute' })}`;
  }

  if (hours === 0) {
    return `${remainingMinutes} ${translator.formatMessage({
      id: 'minute',
    })}`;
  }

  return `${hours} ${translator.formatMessage({
    id: 'hour',
  })}`;
};

export const getMonthName = (month) => {
  switch (month) {
    case 0:
      return <FormattedMessage id='january' />;
    case 1:
      return <FormattedMessage id='february' />;
    case 2:
      return <FormattedMessage id='march' />;
    case 3:
      return <FormattedMessage id='april' />;
    case 4:
      return <FormattedMessage id='may' />;
    case 5:
      return <FormattedMessage id='june' />;
    case 6:
      return <FormattedMessage id='july' />;
    case 7:
      return <FormattedMessage id='august' />;
    case 8:
      return <FormattedMessage id='september' />;
    case 9:
      return <FormattedMessage id='october' />;
    case 10:
      return <FormattedMessage id='november' />;
    case 11:
      return <FormattedMessage id='december' />;
    default:
      return null;
  }
};

export const timeStampToDate = (stamp) => {
  let date = new Date(stamp * 1000);
  return dayjs(date).utc().format('DD.MM.YYYY HH:mm');
};
