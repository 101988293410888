const styles = {
  paddingX: 'sm:px-16 px-6',
  paddingY: 'sm:py-16 py-6',
  padding: 'sm:px-16 px-6',

  heroHeadText: 'font-black !text-[32px] ',
  heroSubText:
    'text-[#272729] font-medium lg:text-[30px] sm:text-[26px] xs:text-[20px] text-[16px]',
  heroHeadSpan: 'block text-[32px]',
  sectionHeadText:
    'text-[#000] font-black md:text-[60px] sm:text-[50px] xs:text-[40px] text-[30px]',
  sectionSubText:
    'landing-page-link font-black sm:text-[22px] text-[22px] uppercase tracking-wider',
};

export { styles };
