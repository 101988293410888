import styles from '../../style/marketingComponent.module.scss';
import { RocketTwoTone, UpCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import useIsPath from '../../hooks/useIsPath';
import { FormattedMessage } from 'react-intl';

const BoostedStamp = () => {
  const isLobby = useIsPath('lobby');
  const toolTipMessage =
    isLobby ? <FormattedMessage id='boostedLobby' /> : <FormattedMessage id='boostedPost' />;
  return (
    <div className={isLobby ? styles['boostedLobby'] : styles['boostStamp']}>
      <Tooltip title={<NavLink to='/suggested-content'>{toolTipMessage}</NavLink>}>
        {isLobby ?
          <UpCircleTwoTone twoToneColor='red' />
        : <RocketTwoTone twoToneColor='red' />}
      </Tooltip>
    </div>
  );
};
export default BoostedStamp;
