import React, { useContext, useEffect, useState } from 'react';
import { Checkbox, Flex, Form, Input, Modal, Table, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CheckCircleFilled,
  CheckOutlined,
  CloseCircleFilled,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  useCreateOrganizationMember,
  useDeleteOrganizationMember,
} from '../../../hooks/queries/OrganizationQueries';
import { UtilityContext } from '../../../providers/UtilityProvider';
import AddGameForm from '../game/AddGameForm';

const { Text } = Typography;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td key={record?.id} {...restProps}>
      {editing ? (
        <Form.Item
          valuePropName={inputType === 'text' ? 'value' : 'checked'}
          name={inputType === 'text' ? 'role' : 'isManager'}
          style={{
            margin: 0,
          }}
          initialValue={inputType === 'text' ? record?.role : record?.isManager}
          rules={[
            {
              required: inputType === 'text',
              message: <FormattedMessage id='fieldRequired' />,
            },
          ]}
        >
          {inputType === 'text' ? (
            <Input />
          ) : (
            <Checkbox>
              <FormattedMessage id='isAdmin' />
            </Checkbox>
          )}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ManageOrganizationMembers = ({ memberDetails, organizationId, callBackModalHandler }) => {
  const [form] = Form.useForm();
  const [editingMember, setEditingMember] = useState('');
  const [deletingMember, setDeletingMember] = useState('');
  const { openNotification, theme } = useContext(UtilityContext);

  const { mutate: editMember, isError, isSuccess } = useCreateOrganizationMember(organizationId);
  const {
    mutate: deleteMember,
    isError: deleteError,
    isSuccess: deleteSuccess,
  } = useDeleteOrganizationMember(organizationId);

  const translator = useIntl();

  const isEditing = (record) => record.id === editingMember.id;

  const edit = (record) => {
    form.setFieldsValue({
      role: record.role,
      isManager: record.type === 1,
    });
    record['isManager'] = record.type === 1;
    setEditingMember(record);
  };

  const cancel = () => {
    setEditingMember('');
  };

  const save = () => {
    let formValues = { ...form.getFieldsValue(), userIds: [editingMember.id], isMember: true };
    if (
      formValues['role'] !== editingMember.role ||
      formValues['isManager'] !== editingMember.isManager
    ) {
      editMember(formValues);
    }
    setEditingMember('');
  };

  const deleteMemberHandler = () => {
    let formObject = {
      userIds: [deletingMember.id],
      organizationId: organizationId,
      role: deletingMember.role,
    };
    deleteMember(formObject);
    setDeletingMember('');
  };

  const deleteHandler = (record) => {
    setDeletingMember(record);
  };

  useEffect(() => {
    if (isSuccess) {
      openNotification('updateSuccess', 'success');
    }
    if (isError) {
      openNotification('updateFailed', 'error');
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      openNotification('memberDeleteSuccess', 'success');
      callBackModalHandler();
    }
    if (deleteError) {
      openNotification('unknownError', 'error');
    }
  }, [deleteError, deleteSuccess]);

  const columns = [
    {
      title: translator.formatMessage({ id: 'name' }),
      dataIndex: 'fullname',
      width: '30%',
      editable: false,
    },
    {
      title: translator.formatMessage({ id: 'role' }),
      dataIndex: 'role',
      width: '50%',
      editable: true,
    },
    {
      title: translator.formatMessage({ id: 'admin' }),
      width: '20%',
      dataIndex: 'type',
      editable: true,
      render: (type) =>
        type ? (
          <CheckOutlined className='text-green-600' />
        ) : (
          <CloseOutlined className='text-red-600' />
        ),
    },
    {
      title: translator.formatMessage({ id: 'actions' }),
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Flex justify='space-between'>
            <Typography.Link onClick={() => save(record.id)}>
              <SaveOutlined />
            </Typography.Link>
            <Typography.Link onClick={cancel}>
              <CloseOutlined />
            </Typography.Link>
          </Flex>
        ) : (
          <Flex justify='space-between'>
            <Typography.Link disabled={editingMember !== ''} onClick={() => edit(record)}>
              <EditOutlined className='cursor-pointer text-yellow-600 hover:bg-yellow-200 p-2 rounded-full' />
            </Typography.Link>
            <Typography.Link disabled={editingMember !== ''} onClick={() => deleteHandler(record)}>
              <DeleteOutlined className='cursor-pointer text-red-600 hover:bg-red-200 p-2 rounded-full' />
            </Typography.Link>
          </Flex>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'type' ? 'boolean' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        rowKey={'id'}
        dataSource={memberDetails}
        columns={mergedColumns}
        rowClassName='editable-row'
        pagination={{
          onChange: cancel,
        }}
        scroll={{ x: 'maximum-content' }}
      />
      <Modal
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'memberDeleteWarn' })}
        okText={translator.formatMessage({ id: 'ok' })}
        onCancel={() => setDeletingMember('')}
        onOk={deleteMemberHandler}
        cancelText={translator.formatMessage({ id: 'cancel' })}
        cancelButtonProps={{ icon: <CloseCircleFilled style={{ color: '#D61C1C' }} /> }}
        okButtonProps={{ icon: <CheckCircleFilled style={{ color: '#fff' }} /> }}
        open={deletingMember?.id}
        destroyOnClose={true}
        centered={true}
      >
        <FormattedMessage id='memberRemoveMessage' />
      </Modal>
    </Form>
  );
};
export default ManageOrganizationMembers;
