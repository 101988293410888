import { Button, Col, Divider, Flex, Image, Row, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from '../../style/notifications.module.scss';
import { useAnswerRequest, useGetRequestDetail } from '../../hooks/queries/UserQueries';
import { Link } from 'react-router-dom';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { useContext } from 'react';
import { UtilityContext } from '../../providers/UtilityProvider';

const RequestNotification = ({ notification }) => {
  const { openNotification } = useContext(UtilityContext);
  const { data: requestDetail } = useGetRequestDetail(notification);
  const { mutateAsync: answerRequest } = useAnswerRequest();
  const Text = Typography;

  const requestHandler = (status) => {
    answerRequest({
      request_id: requestDetail[0].requestId,
      status: status,
    }).then((response) => {
      return response.status === 202
        ? openNotification('connectionApproved', 'success')
        : openNotification('unknownError', 'error');
    });
  };

  if (requestDetail) {
    return (
      <div className='bg-white rounded-[8px] p-4 margin-0 cursor-default'>
        <Flex gap='1rem'>
          <Link to={`/profile/${requestDetail[0].username}`}>
            <Image
              className='rounded-[8px] object-cover'
              width={55}
              height={45}
              preview={false}
              src={requestDetail[0]?.photoUrl ? requestDetail[0]?.photoUrl : defaultProfilePhoto}
            />
          </Link>
          <Link to={`/profile/${requestDetail[0].username}`}>
            <Text>
              {requestDetail[0].username} - {requestDetail[0].fullname} { }
              <FormattedMessage id='connectionRequestMessage' />
            </Text>
          </Link>
        </Flex>
        <Flex gap='1rem' align='center' justify='end' wrap='wrap' className='mt-4'>
          <Button onClick={() => requestHandler(1)} type='primary'>
            <Text className='text-inherit'>
              <FormattedMessage id='approve' />
            </Text>
          </Button>
          <Button onClick={() => requestHandler(2)} danger>
            <Text className='text-inherit'>
              <FormattedMessage id='reject' />
            </Text>
          </Button>
        </Flex>
        <Divider className='mb-0' />
      </div>
    );
  } else {
    return <SpinWrapper />;
  }
};

export default RequestNotification;
