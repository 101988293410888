import { Col, Empty, Row } from 'antd';
import styles from '../../style/toolbar.module.scss';
import Streamers from './Streamers';
import { useEffect, useRef, useState } from 'react';
import { CloseOutlined, FullscreenOutlined, ToolTwoTone } from '@ant-design/icons';
import { useIntl } from 'react-intl';
const TwitchDraggable = ({ callBackHandler }) => {
  const [streamerKey, setStreamerKey] = useState([1]);
  const translator = useIntl();

  const underMaint = true;

  return (
    <Row
      style={{ height: underMaint ? 450 * streamerKey.length : 315 * streamerKey.length }}
      className={`${styles['twitchDraggableRow']} min-w-[800px]`}
      gutter={[16, 16]}
    >
      <Col span={6}>
        <Streamers callBackHandler={setStreamerKey} />
      </Col>
      <Col className={styles['twitchIframeWrapper']} span={17}>
        <div className='flex items-center justify-center mb-4'>
          <Empty
            imageStyle={{ height: '50px' }}
            image={<ToolTwoTone style={{ fontSize: '50px' }} />}
            description={translator.formatMessage({ id: 'underMaintenance' })}
          />
        </div>
        <iframe
          width='100%'
          height='315'
          src='https://www.youtube.com/embed/UOxkGD8qRB4?si=iZD68qL6quNeH14Q'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
        ></iframe>
      </Col>
      <Col
        className={`${styles['draggableActions']} gap-4 flex items-center justify-start flex-col flex-nowrap`}
        span={1}
      >
        <strong onClick={callBackHandler} className='drag-close'>
          <CloseOutlined />
        </strong>
        <strong className='drag-cursor'>
          <FullscreenOutlined />
        </strong>
      </Col>
    </Row>
  );
};

export default TwitchDraggable;
