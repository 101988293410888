import { useChannelMessages, useReadMessage } from '../../../hooks/queries/UserQueries';
import { useContext, useEffect, useRef, useState } from 'react';
import UserMessage from '../desktop/UserMessage';
import { useIntl } from 'react-intl';
import { AuthContext } from '../../../providers/AuthProvider';
import styles from '../../../style/chat.module.scss';
import { InlineEmojiInput } from '../../form/filters/Inputs';

const ActiveMobileChat = ({ activeChannel, otherUser }) => {
  const { user } = useContext(AuthContext);
  const { mutate } = useReadMessage();
  const { data: channelMessages = [] } = useChannelMessages(activeChannel?.chatchannel?.id);

  const [chatData, setChatData] = useState([]);
  const [ws, setWs] = useState(null);

  const translator = useIntl();
  const fullChat = useRef([]);
  const chatAnchorRef = useRef();

  const updateChatRoomData = (newMessages) => {
    let updatedMessages = [...fullChat.current];
    updatedMessages.push(newMessages);
    setChatData([...updatedMessages]);
    fullChat.current = [...updatedMessages];
  };

  useEffect(() => {
    chatAnchorRef.current?.scrollIntoView({ behavior: 'instant' });
  }, [chatData]);

  useEffect(() => {
    if (activeChannel?.id > 0) {
      setWs(new WebSocket(`wss://chat.game.actor/ws?id=${activeChannel?.chatchannel?.id}`));
      mutate(activeChannel?.chatchannel?.id);
    }
  }, [activeChannel]);

  useEffect(() => {
    if (channelMessages.length > 0) {
      fullChat.current = [...channelMessages];
      setChatData([...channelMessages]);
    }
  }, [channelMessages]);

  useEffect(() => {
    if (ws !== null) {
      ws.onmessage = (event) => {
        let data = event.data;

        let newMessage = {
          userId: JSON.parse(data).user,
          contentMessage: data,
        };

        updateChatRoomData(newMessage);
      };

      ws.onclose = function (e) {
        setTimeout(function () {
          setWs(new WebSocket(`wss://chat.game.actor/ws?id=${activeChannel?.chatchannel?.id}`));
        }, 1000);
      };

      ws.onerror = function (err) {
        ws.close();
      };
    }
  }, [ws]);

  return (
    <div>
      <div className='max-h-mobile-chat min-h-mobile-chat overflow-auto pt-8'>
        {chatData.length ? (
          chatData.map((item, index) => (
            <UserMessage
              key={index}
              isMobile={true}
              otherUser={otherUser}
              content={JSON.parse(item?.contentMessage)?.content}
              owner={user?.id === item?.userId}
              user={user}
            />
          ))
        ) : (
          <div className='item '>{translator.formatMessage({ id: 'sayHi' })}</div>
        )}
        <div ref={chatAnchorRef} id='anchor'></div>
      </div>
      <div id='chatTipTapWrapper' className={`${styles['chatMessageDetailFooter']}  pt-4`}>
        <InlineEmojiInput
          placeholder='sayHi'
          chat={true}
          userId={user?.id}
          channelId={activeChannel?.chatchannel?.id}
          toolbar={false}
          widthFull={true}
        />
      </div>
    </div>
  );
};

export default ActiveMobileChat;
