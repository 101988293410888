import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  answerEventHandler,
  createEventHandler,
  deleteEventHandler,
  getEventCalendar,
  getEventParticipants,
  getEventsHandler,
  getUserEvents,
  updateEventHandler,
} from '../../requests/EventManager';

export const useGetEvents = (payload) => {
  return useQuery({
    queryKey: ['getEventQuery', payload],
    queryFn: () => getEventsHandler(payload),
    retry: 2,
  });
};

export const useCreateEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => createEventHandler(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getEventQuery'],
      });
    },
  });
};

export const useUpdateEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => updateEventHandler(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userEvents'],
      });

      queryClient.invalidateQueries({
        queryKey: ['getEventQuery'],
      });
    },
  });
};

export const useGetEventParticipants = (eventId) => {
  return useQuery({
    queryKey: ['getEventParticipantsQuery', eventId],
    queryFn: () => getEventParticipants(eventId),
    retry: 2,
    enabled: !!eventId,
  });
};

export const useAnswerEvent = (eventId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload) => answerEventHandler(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getEventParticipantsQuery', eventId],
      });
    },
  });
};

export const useGetEventCalendar = () => {
  return useQuery({
    queryKey: ['getEventCalendarQuery'],
    queryFn: () => getEventCalendar(),
    retry: 2,
  });
};

export const useGetUserEvents = () => {
  return useQuery({
    queryKey: ['userEvents'],
    queryFn: () => getUserEvents(),
    retry: 2,
  });
};

export const useDeleteEvent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (eventId) => deleteEventHandler(eventId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['userEvents'],
      });
    },
  });
};
