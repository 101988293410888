const getRelativeTime = (date) => {
  const now = new Date();
  const givenDate = new Date(date);
  const diffMs = now - givenDate;
  const diffSeconds = Math.floor(diffMs / 1000);

  if (diffSeconds < 60) {
    return `${diffSeconds} saniye önce`;
  }

  const diffMinutes = Math.floor(diffSeconds / 60);
  if (diffMinutes < 60) {
    return `${diffMinutes} dakika önce`;
  }

  const diffHours = Math.floor(diffMinutes / 60);
  if (diffHours < 24) {
    return `${diffHours} saat önce`;
  }

  const diffDays = Math.floor(diffHours / 24);
  if (diffDays < 30) {
    return `${diffDays} gün önce`;
  }

  const diffMonths = Math.floor(diffDays / 30);
  if (diffMonths < 12) {
    return `${diffMonths} ay önce`;
  }

  const diffYears = Math.floor(diffMonths / 12);
  if(diffYears > 2051) {
    return ''
  }
  else {
    return `${diffYears} yıl önce`;
  }
};

export { getRelativeTime };
