import { Button, Col, Flex, Form, Input, Modal, notification, Row, Typography } from 'antd';
import styles from '../../../style/login.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { useContext, useRef, useState } from 'react';
import {
  emailRegex,
  passwordRegex,
  REQUIRED_RULE,
} from '../../../helpers/constants/constantHelpers';
import { userRegister } from '../../../requests/UserInitiatorManager';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { LoginOutlined } from '@ant-design/icons';
import { useClientError } from '../../../hooks/useClientError';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const RegisterForm = ({ openLogin, setIsRegisterMessage }) => {
  const { openNotification } = useContext(UtilityContext);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const [registerForm] = Form.useForm();
  const intl = useIntl();
  const lang = localStorage.getItem('language');

  const onClientError = useClientError();

  const registerHandler = (registerCredentials) => {
    setLoading(true);
    userRegister({ ...registerCredentials, language: lang })
      .then((response) => {
        if (response.status === 202) {
          notification.success({
            description: intl.formatMessage({ id: 'activationMessage' }),
            className: 'errorNotification',
          });
        } else {
          notification.error({
            message: intl.formatMessage({ id: 'whops' }),
            description: intl.formatMessage({ id: 'unknownError' }),
            className: 'errorNotification',
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          notification.error({
            description: intl.formatMessage({ id: 'existingCredentials' }),
            className: 'errorNotification',
          });
        } else {
          onClientError(err);
        }
      })
      .finally(() => {
        setIsRegisterMessage(true);
        setLoading(false);
      });
  };

  const validateEmail = (_, email) => {
    if (!emailRegex.test(email)) {
      return Promise.reject(intl.formatMessage({ id: 'mailNotValid' }));
    }
    return Promise.resolve();
  };

  const validatePassword = (_, value) => {
    if (!passwordRegex.test(value) && value) {
      return Promise.reject(intl.formatMessage({ id: 'passwordNotValid' }));
    }
    return Promise.resolve();
  };

  return (
    <Form layout='vertical' form={registerForm} onFinish={registerHandler}>
      <Form.Item
        label={intl.formatMessage({ id: 'email' })}
        rules={[{ required: true, message: '' }, { validator: validateEmail }]}
        name='game_actor_email'
      >
        <Input
          placeholder={intl.formatMessage({ id: 'enterEmail' })}
          size='large'
          className='text-black !rounded-[8px] dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'username' })}
        rules={[
          { required: true, message: '' },
          { max: 30, message: intl.formatMessage({ id: 'formLenghtMessageShort' }) },
        ]}
        name='game_actor_username'
      >
        <Input
          size='large'
          className='text-black !rounded-[8px] dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={intl.formatMessage({ id: 'enterUsername' })}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'password' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'pleaseEnterPassword' }) },
          { validator: validatePassword },
        ]}
        name='game_actor_password'
      >
        <Input
          placeholder={intl.formatMessage({ id: 'enterPassword' })}
          type='password'
          size='large'
          className='text-black !rounded-[8px] dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'passwordCheck' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'pleaseEnterPassword' }) },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('game_actor_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(intl.formatMessage({ id: 'noMatchedPassword' }));
            },
          }),
        ]}
        name='passwordCheck'
      >
        <Input
          placeholder={intl.formatMessage({ id: 'enterPasswordCheck' })}
          type='password'
          size='large'
          className='text-black !rounded-[8px] dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'firstName' })}
        rules={[
          { required: true, message: '' },
          { max: 30, message: intl.formatMessage({ id: 'formLenghtMessageShort' }) },
        ]}
        name='firstName'
      >
        <Input
          size='large'
          className='text-black !rounded-[8px] dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={intl.formatMessage({ id: 'enterFirstName' })}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'lastName' })}
        rules={[
          { required: true, message: '' },
          { max: 30, message: intl.formatMessage({ id: 'formLenghtMessageShort' }) },
        ]}
        name='lastName'
      >
        <Input
          size='large'
          className='text-black !rounded-[8px] dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={intl.formatMessage({ id: 'enterLastName' })}
        />
      </Form.Item>
      {lang === 'tr' && (
        <Text className='!text-sm '>
          {'Kaydolarak; '}
          <Link to='/terms-of-service' className='!text-sm' target='_blank'>
            <Text className='hover:text-dark-mode-link-hover-color duration-500 !text-sm'>
              <FormattedMessage id='termsOfServices' />
            </Text>
          </Link>
          {' , '}
          <Link to='/privacy-policy' className='!text-sm' target='_blank'>
            <Text className='hover:text-dark-mode-link-hover-color duration-500 !text-sm'>
              <FormattedMessage id='privacyPoliciy' />
            </Text>
          </Link>
          {' ve Çerez kullanımını kabul etmiş olursun.'}
        </Text>
      )}
      {lang === 'en' && (
        <Text className='!text-sm '>
          {' By signing up, you agree to our '}
          <Link to='/terms-of-service' className='!text-sm' target='_blank'>
            <Text className='hover:text-dark-mode-link-hover-color duration-500 !text-sm'>
              <FormattedMessage id='termsOfServices' />
            </Text>
          </Link>
          {' , '}
          <Link to='/privacy-policy' className='!text-sm' target='_blank'>
            <Text className='hover:text-dark-mode-link-hover-color duration-500 !text-sm'>
              <FormattedMessage id='privacyPoliciy' />
            </Text>
          </Link>
          {' and cookie usage'}
        </Text>
      )}
      <Button
        size='large'
        className='!rounded-[8px]'
        loading={loading}
        type='primary'
        htmlType='submit'
        block
      >
        <Text className='text-inherit'>
          <FormattedMessage id='register' />
        </Text>
      </Button>
      <Button
        onClick={openLogin}
        size='large'
        className='!rounded-[8px] mt-4'
        icon={<LoginOutlined className='me-2' />}
      >
        <Text className='text-inherit'>
          <FormattedMessage id='login' />
        </Text>
      </Button>
    </Form>
  );
};

export default RegisterForm;
