import { useIntl } from 'react-intl';
import { notification } from 'antd';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { Typography } from 'antd';

const { Paragraph } = Typography;

export function useClientError() {
  const intl = useIntl();
  return (error) => {
    if (error.response) {
      const { status, data } = error.response;
      let errorDescription = intl.formatMessage({ id: 'noAuthorization' });
      const sanitizedHtml = parse(
        DOMPurify.sanitize(data?.description?.replaceAll('. ', '. <br /><br />')),
      );

      return notification.error({
        message: intl.formatMessage({ id: 'whops' }),
        description: errorDescription ? (
          errorDescription
        ) : (
          <Paragraph className='[&>p]:mb-0 !mb-0'>{sanitizedHtml}</Paragraph>
        ),
        className: 'errorNotification',
      });
    } else {
      return notification.error({
        message: intl.formatMessage({ id: 'whops' }),
        description: intl.formatMessage({ id: 'genericError' }),
        className: 'errorNotification',
      });
    }
  };
}
