import { Avatar, Card, Col, Modal, Row } from 'antd';
import styles from '../../style/profile.module.scss';
import { CheckCircleFilled, CloseCircleFilled, CloseOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToCircle, faCircleNodes, faShoePrints } from '@fortawesome/free-solid-svg-icons';
import { useContext, useEffect, useState } from 'react';
import UserConnections from './UserConnections';
import UserFollowers from './UserFollowers';
import UserOrganizations from './UserOrganizations';
import { UtilityContext } from '../../providers/UtilityProvider';
import { useFollowOrganization } from '../../hooks/queries/OrganizationQueries';

const Connections = ({
  isOwner,
  userConnections,
  userFollowers,
  userFollowedOrganizations,
  profileId,
  removeConnection,
  blockFollower,
}) => {
  const { openNotification, theme } = useContext(UtilityContext);
  const [clickedDetailConnections, setClickedDetailConnections] = useState(null);
  const [clickedDetailFollowers, setclickedDetailFollowers] = useState(null);
  const [clickedDetailOrganization, setclickedDetailOrganization] = useState(null);
  const [passedConnections, setPassedConnections] = useState([]);
  const [passedFollowers, setPassedFollowers] = useState([]);
  const [passedOrganizations, setPassedOrganizations] = useState([]);
  const { mutateAsync: followOrganization } = useFollowOrganization(profileId);

  const translator = useIntl();

  const handlePassingData = (arrayToSlice, type) => {
    function chunkArray(array, size) {
      let result = [];
      for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
      }
      return result;
    }

    let newArray = chunkArray(arrayToSlice, 10);
    switch (type) {
      case 1:
        return setPassedConnections(newArray);
      case 2:
        return setPassedFollowers(newArray);
      case 3:
        return setPassedOrganizations(newArray);
      default:
        return;
    }
  };

  useEffect(() => {
    handlePassingData(userConnections, 1);
  }, [userConnections]);

  useEffect(() => {
    handlePassingData(userFollowers, 2);
  }, [userFollowers]);

  useEffect(() => {
    handlePassingData(userFollowedOrganizations, 3);
  }, [userFollowedOrganizations]);

  const connectionRemoveModalHandler = (userInfo) => {
    setClickedDetailConnections(userInfo?.id ? userInfo : null);
  };

  const followerBlockModalHandler = (userInfo) => {
    setclickedDetailFollowers(userInfo?.id ? userInfo : null);
  };

  const unfollowOrganizationModalHandler = (organizationInfo) => {
    setclickedDetailOrganization(organizationInfo?.id ? organizationInfo : null);
  };

  const connectionRemoveHandler = () => {
    removeConnection(clickedDetailConnections?.id);
    openNotification('connectionRemoved', 'success');
    setClickedDetailConnections(null);
  };

  const followerBlockHandler = () => {
    blockFollower(clickedDetailFollowers?.id);
    openNotification('followerBlocked', 'success');
    setclickedDetailFollowers(null);
  };

  const unfollowOrganizationHandler = () => {
    followOrganization({
      organization_id: clickedDetailOrganization?.id,
      userId: profileId,
    }).then((response) => {
      if (response?.status === 202) {
        openNotification('organizationFollowStopped', 'success');
      }
    });
    setclickedDetailOrganization(null);
  };

  return (
    <Row className={styles['connectionRow']} gutter={[16, 16]}>
      <Col span={24}>
        <Card
          bordered={false}
          className={`${styles['antdCard']} card-profile-information header-solid h-full`}
          extra={
            <span className={`${styles['connectionNumber']} dark:!text-dark-mode-primary-color`}>
              {userConnections.length}
            </span>
          }
          title={
            <h6 className='font-semibold m-0'>
              <FontAwesomeIcon className='dark:text-dark-mode-primary-color' icon={faCircleNodes} />
              <FormattedMessage id='connections' />
            </h6>
          }
          styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
        >
          <UserConnections
            connections={passedConnections}
            isOwner={isOwner}
            profileId={profileId}
            connectionRemoveHandler={connectionRemoveModalHandler}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          bordered={false}
          title={
            <h6 className='font-semibold m-0'>
              <FontAwesomeIcon className='dark:text-dark-mode-primary-color' icon={faShoePrints} />
              <FormattedMessage id='followersPlural' />
            </h6>
          }
          className={`${styles['antdCard']} ${styles['followersSection']}  header-solid h-full card-profile-information`}
          extra={
            <span className={`${styles['connectionNumber']} dark:!text-dark-mode-primary-color`}>
              {userFollowers.length}
            </span>
          }
          styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
        >
          <UserFollowers
            followers={passedFollowers}
            isOwner={isOwner}
            profileId={profileId}
            followerBlockHandler={followerBlockModalHandler}
          />
        </Card>
      </Col>
      <Modal
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'removeConnectionWarningHeader' })}
        okText={translator.formatMessage({ id: 'ok' })}
        cancelText={translator.formatMessage({ id: 'cancel' })}
        cancelButtonProps={{ icon: <CloseCircleFilled style={{ color: '#D61C1C' }} /> }}
        okButtonProps={{ icon: <CheckCircleFilled style={{ color: '#fff' }} /> }}
        onOk={connectionRemoveHandler}
        onCancel={connectionRemoveModalHandler}
        open={!!clickedDetailConnections}
        destroyOnClose={true}
        centered={true}
      >
        <FormattedMessage id='removeConnectionWarning' />
      </Modal>
      <Modal
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'blockApprovalMessageHeader' })}
        okText={translator.formatMessage({ id: 'ok' })}
        cancelText={translator.formatMessage({ id: 'cancel' })}
        cancelButtonProps={{ icon: <CloseCircleFilled style={{ color: '#D61C1C' }} /> }}
        okButtonProps={{ icon: <CheckCircleFilled style={{ color: '#fff' }} /> }}
        onOk={followerBlockHandler}
        onCancel={followerBlockModalHandler}
        open={!!clickedDetailFollowers}
        destroyOnClose={true}
        centered={true}
      >
        <FormattedMessage id='blockApprovalMessage' />
      </Modal>
      <Modal
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'unfollowOrganizationWarningHeader' })}
        okText={translator.formatMessage({ id: 'ok' })}
        cancelText={translator.formatMessage({ id: 'cancel' })}
        cancelButtonProps={{ icon: <CloseCircleFilled style={{ color: '#D61C1C' }} /> }}
        okButtonProps={{ icon: <CheckCircleFilled style={{ color: '#fff' }} /> }}
        onOk={unfollowOrganizationHandler}
        onCancel={unfollowOrganizationModalHandler}
        open={!!clickedDetailOrganization}
        destroyOnClose={true}
        centered={true}
      >
        <FormattedMessage id='unfollowOrganizationWarning' />
      </Modal>
      <Col span={24}>
        <Card
          bordered={false}
          title={
            <h6 className='font-semibold m-0'>
              <FontAwesomeIcon icon={faArrowsToCircle} />
              <FormattedMessage id='organizations' />
            </h6>
          }
          className={`${styles['antdCard']} ${styles['organizationSection']} card-profile-information header-solid h-full`}
          styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
        >
          <UserOrganizations
            organizations={passedOrganizations}
            isOwner={isOwner}
            unfollowOrganizationHandler={unfollowOrganizationModalHandler}
            profileId={profileId}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Connections;
