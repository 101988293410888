import { useState, useEffect } from 'react';
import { uploadFile } from '../requests/UtilityManager';

const useFileUploader = () => {
  const handleFileUpload = async (receivedData) => {
    let arrayToSet = Array.isArray(receivedData) ? receivedData : [receivedData];

    let uploadedFilesArray = [];
    for (let file of arrayToSet) {
      let fileContentType = file.contentType.includes('image')
        ? 'image'
        : file.contentType.includes('video')
        ? 'video'
        : null;

      if (fileContentType) {
        const response = await uploadFile(file);
        if (response.status === 202) {
          uploadedFilesArray.push({
            url: response.data.data,
            type: fileContentType,
          });
        }
      }
    }
    return uploadedFilesArray;
  };

  return { handleFileUpload };
};

export default useFileUploader;
