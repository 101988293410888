import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import TableRow from '@tiptap/extension-table-row';
import Typography from '@tiptap/extension-typography';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../../style/editor.module.scss';
import '../../style/editor.module.scss';
import {
  LowerCase,
  UpperCase,
  CustomExtension,
  TipTapExtensionTableCell,
  TipTapExtensionTableHeader,
  CustomBulletList,
} from './modules/CustomExtensions';
import ToolBar from './modules/ToolBar';
import { useIntl } from 'react-intl';
import { Placeholder } from '@tiptap/extension-placeholder';
import EmojiPickerComponent from '../utility/EmojiPickerComponent';
import Mention from '@tiptap/extension-mention';
import suggestion from './Suggestion.jsx';
import { Extension } from '@tiptap/core';
import { Youtube } from '@tiptap/extension-youtube';
import GifPickerComponent from '../utility/GifPickerComponent';

const TipTap = ({
  isEmojiVisible,
  isGifVisible,
  emojiViewHandler,
  gifViewHandler,
  chat = 0,
  comment = false,
  formFinish,
  toolbar = true,
  placeholder = 'postTextareaPlaceHolder',
  forwardOnChange,
  defaultValue,
}) => {
  const EnterKeyHandler = Extension.create({
    addKeyboardShortcuts() {
      return {
        Enter: (e) => {
          if (this.editor) {
            if (!comment) {
              formFinish();
              return true;
            }
          }
          return false;
        },
      };
    },
  });
  const theme = false;

  const [focusOnEditorContent, setFocusOnEditorContent] = useState(false);
  const translator = useIntl();
  const extensions = [
    chat > 0 && EnterKeyHandler,
    StarterKit.configure({
      blockquote: {
        HTMLAttributes: {
          style: 'padding-left: 2rem; margin: 0;',
        },
      },
      bulletList: false,
    }),
    Mention.configure({
      HTMLAttributes: {
        class: 'mention',
      },
      suggestion,
      renderHTML({ options, node }) {
        return [
          'a',
          {
            href:
              node.attrs.id?.type === 1
                ? `/profile/${node.attrs.id?.name}`
                : `/organization/${node.attrs.id?.name}`,
            style: 'color : #41308f',
            target: '_self',
          },
          `${node.attrs.id?.name}`,
        ];
      },
    }),
    Link.configure({
      autolink: false,
      linkOnPaste: false,
      openOnClick: true,
    }),
    CustomBulletList,
    Table.configure({
      resizable: true,
    }),
    TableRow,
    TipTapExtensionTableCell,
    TipTapExtensionTableHeader,
    Typography,
    UpperCase,
    LowerCase,
    Highlight.configure({
      multicolor: true,
    }),
    CustomExtension,
    Placeholder.configure({
      placeholder: translator.formatMessage({ id: placeholder }),
    }),
    Youtube.configure({
      inline: true,
      width: '100%',
      height: 400,
      nocookie: true,
    }),
    Image.configure({
      inline: true,
      HTMLAttributes: {
        style: 'height: 300px; display:block; margin:0 auto;',
      },
    }),
  ];

  const editorConfigs = useEditor({
    extensions,
    editable: true,
    onUpdate: ({ editor }) => {
      forwardOnChange(editor.getHTML());
    },
  });

  const emojiClickHandler = (returnedValue) => {
    editorConfigs.commands.insertContent(returnedValue.native);
    emojiViewHandler();
  };

  const gifClickHandler = (returnedValue) => {
    editorConfigs.chain().focus().setImage({ src: returnedValue.url }).run();
    gifViewHandler();
  };

  useEffect(() => {
    editorConfigs?.chain().setContent(defaultValue).run();
  }, [editorConfigs, defaultValue]);

  useEffect(() => {
    if (chat > 0 && editorConfigs) {
      editorConfigs?.commands.focus();
    }
  }, [chat, editorConfigs]);

  return (
    <div className={styles['editorWrapper']}>
      {toolbar && <ToolBar editor={editorConfigs} />}
      <div>
        <EditorContent
          onFocus={() => {
            setFocusOnEditorContent(true);
          }}
          onBlur={() => {
            setFocusOnEditorContent(false);
          }}
          className={`border border-solid duration-300 rounded-md
  ${
    focusOnEditorContent
      ? theme
        ? 'border-dark-mode-primary-color' // focusOnEditorContent true ve theme true ise border rengi pembe
        : 'border-dark-mode-primary-color' // focusOnEditorContent true fakat theme false ise border rengi mavi
      : 'border-[#808080]' // focusOnEditorContent false ise border rengi gri kalır
  } 
  hover:border-primary-color dark:hover:border-dark-mode-primary-color`}
          editor={editorConfigs}
        />
      </div>
      {isEmojiVisible && <EmojiPickerComponent emojiClickCallBack={emojiClickHandler} />}
      {isGifVisible && <GifPickerComponent gifClickCallback={gifClickHandler} />}
    </div>
  );
};

export default TipTap;
