import React from 'react';
import { Typography } from 'antd';
import gameActorLogo from '../assets/images/game-actor-logo.png';
const PrivacyPoliciy = () => {
  const { Text, Title } = Typography;
  const getLanguageBrowser = navigator.language;

  if (getLanguageBrowser === 'tr') {
    return (
      <>
        <div className='flex items-center justify-center md:justify-between'>
          <img
            src={gameActorLogo}
            alt='logo'
            style={{ maxWidth: '240px' }}
            className='object-contain'
          />
        </div>
        <Title className='mt-12' level={2}>
          Gizlilik Politikası
        </Title>
        <Title level={3}>Yürürlük Tarihi: 26.08.2024</Title>
        <Text>
          Game Actor’a hoş geldiniz! Oyuncular için özel olarak tasarlanmış bir sosyal medya
          platformu olan Game Actor’da gizliliğiniz bizim için çok önemlidir. Bu Gizlilik
          Politikası, hizmetlerimizi kullandığınızda kişisel bilgilerinizi nasıl topladığımızı,
          kullandığımızı ve koruduğumuzu açıklar.
        </Text>
        <br />
        <br />
        <Title level={4} className='font-bold'>
          1. Topladığımız Bilgiler
        </Title>
        <Title level={5}>Aşağıdaki türlerde bilgi toplarız:</Title>
        <ul>
          <li>
            <Text>
              <strong>Kişisel Bilgiler:</strong> Game Actor’a kaydolduğunuzda, adınız, e-posta
              adresiniz, kullanıcı adınız ve profil fotoğrafınız gibi kişisel bilgilerinizi
              toplayabiliriz.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Kullanıcı İçeriği:</strong> Oyun güncellemeleri, yorumlar, beğeniler, mesajlar
              ve diğer iletişimler gibi gönderdiğiniz her türlü içerik tarafımızdan saklanır ve
              işlenir.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Cihaz Bilgileri:</strong> Game Actor’a erişim sağladığınız cihazlar hakkında
              cihaz türü, işletim sistemi ve IP adresi gibi bilgileri toplayabiliriz.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Kullanım Verileri:</strong> Game Actor ile nasıl etkileşimde bulunduğunuz
              hakkında, görüntülediğiniz sayfalar, kullandığınız özellikler ve oturumlarınızın
              süresi gibi bilgileri toplarız.
            </Text>
          </li>
        </ul>
        <br />
        <br />
        <Title level={4}>2. Bilgilerinizi Nasıl Kullanıyoruz</Title>
        <Title level={5}>Topladığımız bilgileri şu amaçlarla kullanıyoruz:</Title>
        <ul>
          <li>
            <Text>Game Actor platformunu sağlamak, sürdürmek ve iyileştirmek.</Text>
          </li>
          <li>
            <Text>İlgi alanlarınıza uygun içerikler göstererek deneyiminizi kişiselleştirmek.</Text>
          </li>
          <li>
            <Text>
              Güncellemeler, promosyonlar ve diğer ilgili bilgiler hakkında sizinle iletişime
              geçmek.
            </Text>
          </li>
          <li>
            <Text>
              Hizmetlerimizi iyileştirmek amacıyla kullanım eğilimlerini ve aktiviteleri izlemek ve
              analiz etmek.
            </Text>
          </li>
          <li>
            <Text>
              Dolandırıcılık, suistimal ve diğer zararlı faaliyetleri tespit etmek ve önlemek için
              platformumuzun güvenliğini sağlamak.
            </Text>
          </li>
        </ul>
        <br />
        <br />

        <Title level={4}>3. Bilgilerinizi Kimlerle Paylaşıyoruz</Title>
        <Title level={5}>
          Kişisel bilgilerinizi şu durumlar haricinde üçüncü taraflarla paylaşmayız:
        </Title>
        <ul>
          <li>
            <Text>
              <strong>Onayınızla:</strong> Bilgilerinizi üçüncü taraflarla paylaşmamız gerektiğinde,
              açık rızanızı alırız.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Yasal Gereklilikler:</strong> Bir mahkeme celbi, mahkeme kararı veya diğer
              yasal süreçlere uymak gibi yasal olarak gerekli olduğunda bilgilerinizi
              açıklayabiliriz.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Hizmet Sağlayıcılar:</strong> Platformumuzu işletmemize, işimizi yürütmemize
              veya size hizmet sunmamıza yardımcı olan güvenilir hizmet sağlayıcılarla,
              bilgilerinizi gizli tutmayı kabul etmeleri koşuluyla paylaşabiliriz.
            </Text>
          </li>
        </ul>
        <br />
        <br />

        <Title level={4}>4. Çerezler ve İzleme Teknolojileri</Title>
        <Text>
          Game Actor, deneyiminizi geliştirmek, kullanımı analiz etmek ve size kişiselleştirilmiş
          içerik sunmak için çerezler ve benzeri izleme teknolojileri kullanır. Çerez tercihlerinizi
          tarayıcı ayarlarınız üzerinden yönetebilirsiniz, ancak çerezleri devre dışı bırakmanın
          platformun bazı özelliklerini kullanmanızı engelleyebileceğini unutmayın.
        </Text>
        <br />
        <br />

        <Title level={4}>5. Veri Güvenliği</Title>
        <Text>
          Kişisel bilgilerinizin güvenliğini ciddiye alıyoruz. Verilerinizi korumak için sektör
          standartlarına uygun şifreleme ve güvenlik önlemleri kullanıyoruz. Ancak, internet
          üzerindeki hiçbir veri iletimi veya elektronik depolama yönteminin %100 güvenli olmadığını
          ve mutlak güvenliği garanti edemeyeceğimizi unutmayın.
        </Text>
        <br />
        <br />

        <Title level={4}>6. Haklarınız</Title>
        <Title level={5}>Aşağıdaki haklara sahipsiniz:</Title>
        <ul>
          <li>
            <Text>Sakladığımız kişisel bilgilerinize erişme ve inceleme.</Text>
          </li>

          <li>
            <Text>Bilgilerinizdeki yanlışlıkları düzeltme.</Text>
          </li>
          <li>
            <Text>Hesabınızın ve ilgili verilerinizin silinmesini talep etme.</Text>
          </li>
          <li>
            <Text>Veri işleme izninizi istediğiniz zaman geri çekme.</Text>
          </li>
        </ul>
        <br />
        <br />

        <Title level={4}>7. Çocukların Gizliliği</Title>
        <Text>
          Game Actor, 13 yaşın altındaki kullanıcılar için tasarlanmamıştır. 13 yaş altındaki
          çocuklardan bilerek kişisel bilgi toplamıyoruz. Yanlışlıkla böyle bir bilgi topladığımızı
          fark edersek, bunu en kısa sürede silmek için gerekli adımları atacağız.
        </Text>
        <br />
        <br />
        <Title level={4}>8. Bu Gizlilik Politikasındaki Değişiklikler</Title>
        <Text>
          Bu Gizlilik Politikasını zaman zaman uygulamalarımızdaki veya yasal gerekliliklerdeki
          değişiklikleri yansıtacak şekilde güncelleyebiliriz. Önemli değişiklikler yapıldığında,
          güncellenmiş politikayı Game Actor üzerinde veya diğer iletişim kanalları aracılığıyla
          size bildiririz.
        </Text>
        <br />
        <br />
        <Title level={4}>9. İletişim</Title>
        <Text>
          <strong>
            Bu Gizlilik Politikası veya veri uygulamalarımız hakkında herhangi bir sorunuz veya
            endişeniz varsa, lütfen bizimle iletişime geçin:
          </strong>
        </Text>
        <br />
        <Text>
          <strong>E-posta:</strong> info@game.actor
        </Text>
        <br />
        <Text>
          <strong>Adres:</strong> Maslak, Eski Büyükdere Cd. No:21, 34485 Sarıyer/İstanbul
        </Text>
        <br />
        <br />
      </>
    );
  } else if (getLanguageBrowser === 'en' || getLanguageBrowser === 'en-US') {
    return (
      <div>
        <div className='flex items-center justify-center md:justify-between'>
          <img
            src={gameActorLogo}
            alt='logo'
            style={{ maxWidth: '240px' }}
            className='object-contain'
          />
        </div>
        <Title className='mt-12' level={2}>
          Privacy Policy
        </Title>
        <Title level={3}>Effective Date: 26.08.2024</Title>
        <Text>
          Welcome to Game Actor, a social media platform designed specifically for gamers. Your
          privacy is very important to us. This Privacy Policy outlines how we collect, use, and
          protect your personal information when you use our services.
        </Text>
        <br />
        <br />
        <Title level={4} className='font-bold'>
          1. Information We Collect
        </Title>
        <Title level={5}>We collect the following types of information:</Title>
        <ul>
          <li>
            <Text>
              <strong>Personal Information:</strong> When you sign up for Game Actor, we may collect
              personal information such as your name, email address, username, and profile picture.
            </Text>
          </li>
          <li>
            <Text>
              <strong>User Content:</strong> Any content you post, such as gaming updates, comments,
              likes, messages, and other communications, is stored and processed by us.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Device Information:</strong> We may collect information about the devices you
              use to access Game Actor, including the device type, operating system, and IP address.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Usage Data:</strong> We collect information on how you interact with Game
              Actor, including pages viewed, features used, and the time and duration of your
              sessions.
            </Text>
          </li>
        </ul>
        <br />
        <br />
        <Title level={4}>2. How We Use Your Information</Title>
        <Title level={5}>We use the information we collect to:</Title>
        <ul>
          <li>
            <Text>Provide, maintain, and improve the Game Actor platform.</Text>
          </li>
          <li>
            <Text>Personalize your experience by showing content tailored to your interests.</Text>
          </li>
          <li>
            <Text>
              Communicate with you about updates, promotions, and other relevant information.
            </Text>
          </li>
          <li>
            <Text>Monitor and analyze usage trends and activities to improve our services.</Text>
          </li>
          <li>
            <Text>
              Ensure the safety and security of our platform by detecting and preventing fraud,
              abuse, and other harmful activities.
            </Text>
          </li>
        </ul>
        <br />
        <br />

        <Title level={4}>3. Sharing Your Information</Title>
        <Title level={5}>
          We do not share your personal information with third parties, except in the following
          circumstances:
        </Title>
        <ul>
          <li>
            <Text>
              <strong>With Your Consent:</strong> We may share your information with third parties
              when you provide explicit consent.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Legal Requirements:</strong> We may disclose your information if required by
              law, such as to comply with a subpoena, court order, or other legal processes.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Service Providers:</strong> We may share your information with trusted service
              providers who assist us in operating our platform, conducting business, or providing
              services to you, provided they agree to keep your information confidential.
            </Text>
          </li>
        </ul>
        <br />
        <br />

        <Title level={4}>4. Cookies and Tracking Technologies</Title>
        <Text>
          Game Actor uses cookies and similar tracking technologies to enhance your experience,
          analyze usage, and provide personalized content. You can manage your cookie preferences
          through your browser settings, but please note that disabling cookies may affect your
          ability to use certain features of the platform.
        </Text>
        <br />
        <br />

        <Title level={4}>5. Data Security</Title>
        <Text>
          We take the security of your personal information seriously. We use industry-standard
          encryption and security measures to protect your data. However, no method of transmission
          over the internet or electronic storage is 100% secure, and we cannot guarantee absolute
          security.
        </Text>
        <br />
        <br />

        <Title level={4}>6. Your Rights</Title>
        <Title level={5}>You have the right to:</Title>
        <ul>
          <li>
            <Text>Access and review your personal information stored by us.</Text>
          </li>

          <li>
            <Text>Correct any inaccuracies in your information.</Text>
          </li>
          <li>
            <Text>Request the deletion of your account and associated data.</Text>
          </li>
          <li>
            <Text>Withdraw consent for data processing at any time.</Text>
          </li>
        </ul>
        <br />
        <Text>To exercise these rights, please contact us at info@game.actor.</Text>
        <br />
        <br />

        <Title level={4}> 7. **Children’s Privacy</Title>
        <Text>
          Game Actor is not intended for users under the age of 13. We do not knowingly collect
          personal information from children under 13. If we become aware that we have inadvertently
          collected such information, we will take steps to delete it as soon as possible.
        </Text>
        <br />
        <br />
        <Title level={4}>8. Changes to This Privacy Policy</Title>
        <Text>
          We may update this Privacy Policy from time to time to reflect changes in our practices or
          legal requirements. We will notify you of any significant changes by posting the updated
          policy on Game Actor or through other communication channels.
        </Text>
        <br />
        <br />
        <Title level={4}>9. Contact Us</Title>
        <Text>
          <strong>
            {' '}
            If you have any questions or concerns about this Privacy Policy or our data practices,
            please contact us at:
          </strong>
        </Text>
        <br />
        <Text>
          <strong>Email:</strong> info@game.actor
        </Text>
        <Text>
          <strong>Address:</strong> Maslak, Eski Büyükdere Cd. No:21, 34485 Sarıyer/İstanbul
        </Text>
        <br />
        <br />
      </div>
    );
  }

  return <></>;
};

export default PrivacyPoliciy;
