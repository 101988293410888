import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
  const user = localStorage.getItem('access_token');
  if (!user) {
    return <Navigate to='/' />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
