import { Button, Col, Form, message, Modal, Row, Typography } from 'antd';
import bf2042 from '../../../assets/images/bf-2042-game-cover.jpg';
import eftImage from '../../../assets/images/eft-game-cover.jpeg';
import rainbow from '../../../assets/images/rainbow-six-siege.jpeg';
import lol from '../../../assets/images/league-of-legends.jpeg';
import GameCard from '../../cards/GameCard';
import styles from '../../../style/profile.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CheckCircleFilled,
  CloseCircleFilled,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import AddGameForm from './AddGameForm';
import { UtilityContext } from '../../../providers/UtilityProvider';

const { Text } = Typography;

const FavouriteGamesForm = () => {
  const [selectedGameList, setSelectedGameList] = useState([]);
  const [newGameModal, setNewGameModal] = useState(false);
  const [favouriteGamesForm] = Form.useForm();
  const translator = useIntl();
  const { theme } = useContext(UtilityContext);

  const userGameList = [
    {
      nickName: 'xenopuncher',
      playHour: '1377',
      level: '47',
      image: bf2042,
      key: 1,
    },
    {
      nickName: 'SpicyMuffin',
      playHour: '411',
      level: '89',
      image: eftImage,
      key: 2,
    },
    {
      nickName: 'Clinkot',
      playHour: '465',
      level: '161',
      image: rainbow,
      key: 3,
    },
    {
      nickName: 'TSZ TerrorTale',
      playHour: '2832',
      level: '451',
      image: lol,
      key: 4,
    },
    {
      nickName: 'xenopuncher',
      playHour: '1377',
      level: '47',
      image: bf2042,
      key: 5,
    },
  ];

  const checkBoxHandler = (event, gameKey) => {
    let selectedGamesFormItem = [...favouriteGamesForm.getFieldValue('selectedGames')];
    if (event.target.checked) {
      if (selectedGamesFormItem.length === 4) {
        return message.error(translator.formatMessage({ id: 'noMoreGameCanBeAdded' }));
      }
      selectedGamesFormItem.push(gameKey);
      favouriteGamesForm.setFieldValue('selectedGames', selectedGamesFormItem);
      setSelectedGameList(selectedGamesFormItem);
    } else {
      const filteredGamesFormItem = selectedGamesFormItem.filter((x) => x !== gameKey);
      favouriteGamesForm.setFieldValue('selectedGames', filteredGamesFormItem);
      setSelectedGameList(filteredGamesFormItem);
    }
  };

  return (
    <Form initialValues={{ selectedGames: [] }} form={favouriteGamesForm}>
      <Button
        className='mb-3'
        onClick={() => setNewGameModal(!newGameModal)}
        icon={<PlusOutlined />}
      >
        <Text className='text-inherit'>
          <FormattedMessage id='addGame' />
        </Text>
      </Button>
      <Row className={styles['personalInfoRow']} gutter={[16, 16]}>
        {userGameList.map((info) => (
          <Col key={info.key} xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
            <GameCard
              gameKey={info.key}
              isChecked={selectedGameList.includes(info.key)}
              formCheckBoxChangeHandler={checkBoxHandler}
              fromForm={true}
              imgSrc={info.image}
              imgAlt='Escape From Tarkov'
              playerInfo={info}
            />
          </Col>
        ))}
      </Row>
      <Modal
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'addGame' })}
        okText={translator.formatMessage({ id: 'ok' })}
        onCancel={() => setNewGameModal(!newGameModal)}
        cancelText={translator.formatMessage({ id: 'cancel' })}
        cancelButtonProps={{ icon: <CloseCircleFilled style={{ color: '#D61C1C' }} /> }}
        okButtonProps={{ icon: <CheckCircleFilled style={{ color: '#fff' }} /> }}
        open={newGameModal}
        destroyOnClose={true}
        centered={true}
      >
        <AddGameForm />
      </Modal>
    </Form>
  );
};

export default FavouriteGamesForm;
