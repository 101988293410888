import { createContext, useContext, useEffect, useState } from 'react';
import { notification } from 'antd';
import { useIntl } from 'react-intl';
import axiosInstance from '../lib/service/axios';
import { useGetOrganizationCategories } from '../hooks/queries/OrganizationQueries';
import { AuthContext } from './AuthProvider';

export const UtilityContext = createContext({});

const UtilityProvider = ({ children }) => {
  const translator = useIntl();
  const { user } = useContext(AuthContext);
  const [isUserKey, setIsUserKey] = useState();
  const [api, contextHolder] = notification.useNotification();
  const { data: organizationCategories } = useGetOrganizationCategories(user);
  const [theme, setTheme] = useState(() => {
    const mode = JSON.parse(localStorage.getItem('theme'));
    if (mode === null) {
      return window.matchMedia('(prefers-color-scheme:dark)').matches;
    }
    return mode;
  });

  const changeThemeHandler = () => {
    setTheme(!theme);
  };

  useEffect(() => {
    if (theme === true) {
      document.body.classList.add('dark');
    } else if (theme === false) {
      document.body.classList.remove('dark');
    }
    localStorage.setItem('theme', JSON.stringify(theme));
  }, [theme]);

  const openNotification = (notificationDescription, type) => {
    if (type === 'success') {
      api.success({
        description: translator.formatMessage({ id: notificationDescription }),
      });
    } else if (type === 'error') {
      api.error({
        message: translator.formatMessage({ id: 'whops' }),
        description: translator.formatMessage({ id: notificationDescription }),
      });
    } else if (type === 'warning') {
      api.warning({
        description: translator.formatMessage({ id: notificationDescription }),
      });
    }
  };

  const dynamicAxiosInstance = async (actionName) => {
    let formObject = {
      action: actionName,
    };
    return await axiosInstance.post('securedHandle', formObject);
  };

  return (
    <UtilityContext.Provider
      value={{
        openNotification,
        dynamicAxiosInstance,
        organizationCategories,
        changeThemeHandler,
        theme,
        setIsUserKey,
        isUserKey,
      }}
    >
      {contextHolder}
      {children}
    </UtilityContext.Provider>
  );
};
export default UtilityProvider;
