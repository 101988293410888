import React, { useState, useEffect } from 'react';
import { Wheel } from 'react-custom-roulette';
import { Button, Flex, Typography, Image } from 'antd';
import PrimaryCard from '../cards/PrimaryCard';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { FormattedMessage } from 'react-intl';

const { Paragraph, Text } = Typography;

const WheelComponent = ({ participants }) => {
  const initialData = [{ option: '0' }];
  const [data, setData] = useState(initialData);
  const [winner, setWinner] = useState(null);

  useEffect(() => {
    if (participants && participants.length > 0) {
      let dataArray = [];
      participants.forEach((x) => {
        dataArray.push({
          option: x.username,
        });
      });

      setData(dataArray);
    }
  }, [participants]);

  const handleSpinClick = () => {
    for (let index = 0; index < 25; index++) {
      setTimeout(() => {
        const newPrizeNumber = Math.floor(Math.random() * data.length);
        pickWinner(newPrizeNumber);
      }, 100 * index);
    }
  };

  const pickWinner = (prizeNumber) => {
    let luckyPerson = participants[prizeNumber];
    setWinner(luckyPerson);
  };

  return (
    <>
      <Flex vertical={true} align='center' gap={0.5}>
        <Button onClick={() => handleSpinClick()}>
          <Text className='text-inherit'>
            <FormattedMessage id='spin' />
          </Text>
        </Button>
      </Flex>
      {winner && (
        <PrimaryCard className='mt-4' grid={false}>
          <Paragraph strong={true} className='text-center !mb-2'>
            <Image
              className='rounded-[8px] object-cover'
              width={50}
              height={45}
              preview={false}
              src={winner.photoUrl ? winner.photoUrl : defaultProfilePhoto}
            />
          </Paragraph>
          <Flex vertical={true} align='center' gap='.5rem'>
            <Text>{winner?.username}</Text>
            <Text>{winner?.fullname}</Text>
          </Flex>
        </PrimaryCard>
      )}
    </>
  );
};

export default WheelComponent;
