import styles from '../../style/toolbar.module.scss';
import layoutStyles from '../../style/layout.module.scss';
import { faSteam, faTwitch } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Drawer, Typography } from 'antd';
import { CloseOutlined, FullscreenOutlined } from '@ant-design/icons';
import TwitchDraggable from './TwitchDraggable';
import Draggable from 'react-draggable';
import PrimaryCard from '../cards/PrimaryCard';
import { useContext, useState } from 'react';
import SteamFeatured from './SteamFeatured';
import SteamAchievements from '../integrations/steam/SteamAchievements';
import { DrawerProviderContext } from '../../providers/DrawerProvider';
import { useIntl } from 'react-intl';
import useWindowSize from '../../hooks/useWindowSize';
import { Tube } from '@react-three/drei';
import { UtilityContext } from '../../providers/UtilityProvider';

const Toolbar = () => {
  const { Text } = Typography;
  const { width } = useWindowSize();
  const { openDrawer } = useContext(DrawerProviderContext);
  const [twitchEnabled, setTwitchEnabled] = useState(false);
  const [stemDrawerIsOpen, setStemDrawerIsOpen] = useState(false);
  const translator = useIntl();
  const { theme } = useContext(UtilityContext);

  // const steamFeaturedHandler = () => {
  //   openDrawer({
  //     title: <Text>{translator.formatMessage({ id: 'steamFeatured' })}</Text>,
  //     content: <SteamFeatured />,
  //     drawerProps: {},
  //     footer: null,
  //     width: width > 768 ? 900 : width,
  //   });
  // };

  return (
    <div
      className={`${styles['toolbarWrapper']} p-2 bg-light-container-color dark:bg-dark-mode-light-container-color rounded-lg flex items-center justify-start flex-wrap`}
    >
      <Button
        className='border-none'
        onClick={() => {
          setStemDrawerIsOpen(true);
        }}
        ghost
        icon={
          <FontAwesomeIcon
            className={`${layoutStyles['fontAwesomeIcon']} ease-in duration-200 !mr-0 rounded-full text-[#192b3c] dark:text-white  cursor-pointer !border-none p-2 text-xl hover:!bg-[#efefef] dark:hover:!bg-dark-mode-secondary-color`}
            icon={faSteam}
          />
        }
      />
      <Button
        className='border-none'
        onClick={() => setTwitchEnabled(!twitchEnabled)}
        ghost
        icon={
          <FontAwesomeIcon
            className={`${layoutStyles['fontAwesomeIcon']} fill-lime-200 ease-in duration-200 !mr-0 rounded-full text-[#a970ff] cursor-pointer !border-none p-2 text-xl hover:!bg-[#efefef] dark:hover:!bg-dark-mode-secondary-color`}
            icon={faTwitch}
          />
        }
      />
      {twitchEnabled && (
        <Draggable
          scale={1}
          defaultPosition={{ x: -Math.floor(window.innerWidth / 2), y: 0 }}
          handle='strong'
        >
          <Card>
            <TwitchDraggable callBackHandler={() => setTwitchEnabled(false)} />
          </Card>
        </Draggable>
      )}
      <Drawer
        title={<Text>{translator.formatMessage({ id: 'steamFeatured' })}</Text>}
        width={width > 768 ? 900 : width}
        open={stemDrawerIsOpen}
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        closable={true}
        onClose={() => {
          setStemDrawerIsOpen(false);
        }}
      >
        <SteamFeatured />
      </Drawer>
    </div>
  );
};

export default Toolbar;
