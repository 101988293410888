import styles from '../../../style/layout.module.scss';
import VideoPlayerGallery from '../../galleries/VideoPlayerGallery';
import { Image } from 'antd';

const ImageWrapper = ({ onImageClick, attachmentUrl, type }) => {
  if (type === 'image') {
    return (
      <div onClick={() => onImageClick(attachmentUrl)}>
        <div className={styles['imageInnerWrapper']}>
          <div className={styles['imageLinkWrapper']}>
            <div className={styles['imageContainer']}>
              <Image src={attachmentUrl} alt='galleryImage' width='100%' preview={false}/>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div onClick={() => onImageClick(attachmentUrl)}>
        <div className={styles['imageInnerWrapper']}>
          <div className={styles['imageLinkWrapper']}>
            <div className={styles['imageContainer']}>
              <VideoPlayerGallery videoUrl={attachmentUrl} height={145} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ImageWrapper;
