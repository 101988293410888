import { Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import formStyles from '../../../style/form.module.scss';
import { SelectComponent } from '../filters/Inputs';

const AddGameForm = () => {
  const [addGameForm] = Form.useForm();

  return (
    <Form className={formStyles['gameForm']} form={addGameForm}>
      <SelectComponent
        translationId='gamePlaceHolder'
        serviceUrl='getGames'
        formName='game_list'
        required={false}
        multi={true}
      />
    </Form>
  );
};

export default AddGameForm;
