import { useEffect, useRef } from 'react';

const OutsideClickDetector = ({ onOutsideClick, children }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        onOutsideClick();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onOutsideClick]);

  return <div ref={buttonRef}>{children}</div>;
};

export default OutsideClickDetector;
