import SpinWrapper from '../../utility/wrappers/SpinWrapper';
import { useGetUserSteamGenres } from '../../../hooks/queries/IntegrationQueries';
import { Col, Flex, Row, Tooltip, Typography } from 'antd';
import { getGenreIcon, truncateText } from '../../../helpers/constants/constantHelpers';
import { getHoursAndMinutes } from '../../../helpers/utils/DateFormatter';
import PrimaryCard from '../../cards/PrimaryCard';
import { useCallback, useRef } from 'react';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const SteamGenreRow = ({ steamGenres, isLoading }) => {
  if (isLoading) {
    return <SpinWrapper />;
  }

  const genreRow = useRef(null);

  const leftScroller = () => {
    const width = genreRow.current?.offsetWidth;

    genreRow.current?.scrollTo({
      left: genreRow.current?.scrollLeft - width,
      behavior: 'smooth',
    });
  };

  const rightScroller = () => {
    const width = genreRow.current?.offsetWidth;

    genreRow.current?.scrollTo({
      left: genreRow.current?.scrollLeft + width,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <Flex className='w-full' justify='end' gap='1rem'>
        <LeftCircleOutlined
          onClick={leftScroller}
          className='text-[18px] cursor-pointer hover:text-secondary'
        />
        <RightCircleOutlined
          onClick={rightScroller}
          className='text-[18px] cursor-pointer hover:text-secondary'
        />
      </Flex>
      <Row
        ref={genreRow}
        gutter={[16, 16]}
        className='sm:!mx-auto md:!mx-0 overflow-auto flex-nowrap no-scrollbar max-w-game-statistics-width'
      >
        {steamGenres?.map(({ genre_name, time_played }, index) => (
          <Col key={index} xxl={3} xl={3} lg={3} md={4} sm={6} xs={8}>
            <PrimaryCard bodyStyle={{ padding: 8 }} grid={false}>
              <Flex align='center' vertical={true} gap='.5rem'>
                <Tooltip title={genre_name}>{getGenreIcon(genre_name, 24, 0)}</Tooltip>
                <Text className='text-sm'>{getHoursAndMinutes(time_played)}</Text>
              </Flex>
            </PrimaryCard>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default SteamGenreRow;
