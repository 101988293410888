import axiosInstance from '../lib/service/axios';

export const createEventHandler = async (payload) => {
  let formObject = {
    action: 'createEvent',
    eventPayload: payload,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const updateEventHandler = async (payload) => {
  let formObject = {
    action: 'updateEvent',
    eventPayload: payload,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getEventsHandler = async (payload) => {
  let formObject = {
    action: 'searchEvent',
    searchEventPayload: payload,
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data;
};

export const getEventParticipants = async (id) => {
  let formObject = {
    action: 'eventUsers',
    mutualEventPayload: {
      event_id: id,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data;
};

export const answerEventHandler = async (payload) => {
  let formObject = {
    action: 'answerEvent',
    answerEventPayload: payload,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getEventCalendar = async () => {
  let formObject = {
    action: 'eventCalendar',
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data;
};

export const getUserEvents = async () => {
  let formObject = {
    action: 'getMyEvents',
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data;
};

export const deleteEventHandler = async (eventId) => {
  let formObject = {
    action: 'deleteEvent',
    eventPayload: {
      id: eventId,
    },
  };

  return await axiosInstance.post('securedHandle', formObject);
};
