import { Button, Form, Image, Input, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import layoutStyles from '../../../style/layout.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faBattleNet,
  faDiscord,
  faPlaystation,
  faSteam,
  faXbox,
} from '@fortawesome/free-brands-svg-icons';
import epicImage from '../../../assets/svg/epic-games.svg';
import riotGames from '../../../assets/svg/riot-games.svg';
import styles from '../../../style/form.module.scss';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useContext } from 'react';
import { AuthContext } from '../../../providers/AuthProvider';
import { useUpdatePlatformInformation } from '../../../hooks/queries/UserQueries';
import PropChecker from '../../../helpers/utils/PropChecker';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { faGamepad } from '@fortawesome/free-solid-svg-icons';

const { Text } = Typography;

const PlatformForm = ({ modalHandler, platformProperties }) => {
  const { user } = useContext(AuthContext);
  const { mutate } = useUpdatePlatformInformation(user?.id);
  const [platformForm] = Form.useForm();
  const { openNotification } = useContext(UtilityContext);
  const translator = useIntl();

  const platformInformationHandler = (formValues) => {
    if (PropChecker(formValues)) {
      formValues['userId'] = user.id;
      mutate(formValues);
      modalHandler();
    } else {
      return openNotification('noBlankSpaceAllowed', 'error');
    }
  };

  return (
    <Form
      initialValues={platformProperties}
      onFinish={platformInformationHandler}
      layout='vertical'
      form={platformForm}
    >
      <Form.Item name='xbox' label={translator.formatMessage({ id: 'xboxFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'xboxPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faXbox}
              style={{ color: '#0f7c10', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='discord' label={translator.formatMessage({ id: 'discordFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'discordPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faDiscord}
              style={{ color: '#5765f2', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='steam' label={translator.formatMessage({ id: 'steamFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'steamPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faSteam}
              style={{ color: '#192b3c', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='battlenet' label={translator.formatMessage({ id: 'battleNetFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'battleNetPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faBattleNet}
              style={{ color: '#158eff', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='riot' label={translator.formatMessage({ id: 'riotGamesFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'riotGamesPlaceHolder' })}
          prefix={
            <Image
              preview={false}
              width={20}
              height={20}
              src={riotGames}
              alt='riot-games-launcher'
            />
          }
        />
      </Form.Item>
      <Form.Item name='psn' label={translator.formatMessage({ id: 'playStationFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'playStationPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faPlaystation}
              style={{ color: '#0070d1', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='nintendo' label={translator.formatMessage({ id: 'nintendoFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'nintendoPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faGamepad}
              style={{ color: '#e60012', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='epic' label={translator.formatMessage({ id: 'epicGamesFormLabel' })}>
        <Input
          className='text-black dark:bg-dark-mode-light-container-color dark:text-secondary-color dark:border-dark-mode-notifications-border-color'
          placeholder={translator.formatMessage({ id: 'epicGamesPlaceHolder' })}
          prefix={
            <Image
              preview={false}
              width={20}
              height={20}
              src={epicImage}
              alt='epic-games-launcher'
            />
          }
        />
      </Form.Item>
      <div className={styles['actionWrapper']}>
        <Button
          onClick={modalHandler}
          icon={<CloseCircleFilled style={{ color: '#D61C1C', marginRight: '.5rem' }} />}
        >
          <Text className='text-inherit'>
            <FormattedMessage id='cancel' />
          </Text>
        </Button>
        <Button
          htmlType='submit'
          type='primary'
          icon={<CheckCircleFilled style={{ color: '#fff', marginRight: '.5rem' }} />}
        >
          <Text className='text-inherit'>
            <FormattedMessage id='ok' />
          </Text>
        </Button>
      </div>
    </Form>
  );
};

export default PlatformForm;
