const PropChecker = (object, nonCheck) => {
  if (object) {
    for (let key in object) {
      if (key !== nonCheck && object[key].replaceAll(' ', '').length > 0) {
        return true;
      }
    }
    return false;
  }
};

export default PropChecker;
