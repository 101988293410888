import {
  Avatar,
  Button,
  Col,
  Divider,
  Drawer,
  Empty,
  Flex,
  Image,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import BgProfile from '../../assets/images/bg-profile.jpg';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import useWindowSize from '../../hooks/useWindowSize';
import PrimaryCard from '../cards/PrimaryCard';
import {
  CheckOutlined,
  ClockCircleTwoTone,
  CloseOutlined,
  LeftOutlined,
  PushpinTwoTone,
  QuestionCircleOutlined,
  RightOutlined,
  StarTwoTone,
  UserOutlined,
} from '@ant-design/icons';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import Carousel from 'nuka-carousel';
import VideoPlayer from '../post/VideoPlayer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAnswerEvent, useGetEventParticipants } from '../../hooks/queries/EventQueries';
import React, { useContext, useState, useRef } from 'react';
import { useClientError } from '../../hooks/useClientError';
import { UtilityContext } from '../../providers/UtilityProvider';
import { ModalProviderContext } from '../../providers/ModalProvider';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import ListUsers from '../feed/ListUsers';
import dayjs from 'dayjs';
import WheelComponent from './WheelComponent';
import { AuthContext } from '../../providers/AuthProvider';

const { Paragraph, Text } = Typography;

const EventDetail = ({ detail, isDrawer = false }) => {
  const { user } = useContext(AuthContext);
  const { openNotification, theme } = useContext(UtilityContext);
  const { data, isLoading } = useGetEventParticipants(detail?.id);
  const { openModal, closeModal } = useContext(ModalProviderContext);
  const { mutateAsync } = useAnswerEvent(detail?.id);
  const { width } = useWindowSize();
  const spinnerRef = useRef();

  const [answerLoading, setAnswerLoading] = useState(false);
  const [participantDrawer, setParticipantDrawer] = useState(false);
  const sanitizedHtml = parse(DOMPurify.sanitize(detail?.description));

  const onClientError = useClientError();
  const translator = useIntl();

  const eventParticipateStatus = (value) => {
    setAnswerLoading(true);
    mutateAsync({
      event_id: detail?.id,
      answer: value,
    })
      .then((response) => {
        if (response.status === 202) {
          openNotification('eventAnswered', 'success');
        }
      })
      .catch((err) => onClientError(err))
      .finally(() => {
        setAnswerLoading(false);
      });
  };

  const startGiveawayHandler = () => {
    openModal({
      title: translator.formatMessage({ id: 'Giveaway' }),
      content: <WheelComponent participants={data?.data} ref={spinnerRef} />,
      footer: null,
      onCancel: closeModal,
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={isDrawer ? 24 : 5}>
        <PrimaryCard grid={false}>
          <Paragraph strong={true} className='text-center !mb-2'>
            {detail?.name}
          </Paragraph>
          <Flex vertical={true} align='center' gap='.5rem'>
            <Text>
              <PushpinTwoTone twoToneColor='#41308f' className='mr-1' />
              {detail?.address}
            </Text>
            <Text>
              <ClockCircleTwoTone twoToneColor='#41308f' className='mr-1' />
              {dayjs(detail?.event_date).utc().format('DD.MM.YYYY HH:mm')}
            </Text>
          </Flex>
        </PrimaryCard>
        {detail?.user?.id === user?.id && (
          <PrimaryCard className='mt-4' grid={false}>
            <Paragraph strong={true} className='text-center !mb-2'>
              <FormattedMessage id='giveAway' />
            </Paragraph>
            <Flex vertical={true} align='center' gap='.5rem'>
              <Text>
                <Button onClick={startGiveawayHandler}>
                  <Text className='text-inherit'>
                    <FormattedMessage id='start' />
                  </Text>
                </Button>
              </Text>
            </Flex>
          </PrimaryCard>
        )}
      </Col>
      <Col span={isDrawer ? 24 : 14}>
        <>
          {detail?.event_attachments?.length !== 0 ? (
            <div>
              {detail?.event_attachments?.length > 1 ? (
                <div>
                  <Carousel
                    defaultControlsConfig={{
                      nextButtonText: <RightOutlined />,
                      prevButtonText: <LeftOutlined />,
                    }}
                  >
                    {detail?.event_attachments.map((attachment, index) => {
                      if (attachment.type === 'video') {
                        return (
                          <div key={index}>
                            <VideoPlayer videoUrl={attachment?.attachmentUrl} height={500} />
                          </div>
                        );
                      } else if (attachment.type === 'image') {
                        return (
                          <div
                            className='d-flex items-center justify-center rounded-[8px]'
                            key={index}
                          >
                            <Image
                              className='rounded-[8px] max-h-[500px]'
                              preview={true}
                              src={attachment.attachmentUrl}
                            />
                          </div>
                        );
                      }
                    })}
                  </Carousel>
                </div>
              ) : (
                <div>
                  {detail?.event_attachments &&
                    detail?.event_attachments.map((attachment, index) => {
                      if (attachment.type === 'video') {
                        return (
                          <div key={index}>
                            <VideoPlayer videoUrl={attachment.attachmentUrl} height={500} />
                          </div>
                        );
                      } else if (attachment.type === 'image') {
                        return (
                          <div
                            className='d-flex items-center justify-center rounded-[8px]'
                            key={index}
                          >
                            <Image
                              className='rounded-[8px] max-h-[500px]'
                              preview={true}
                              src={attachment.attachmentUrl}
                            />
                          </div>
                        );
                      }
                    })}
                </div>
              )}
              <div className='mt-8'>
                <Paragraph className='[&>p]:mb-0 !mb-0'>{sanitizedHtml}</Paragraph>
              </div>
            </div>
          ) : (
            <>
              <Image className='rounded-[8px]' preview={false} src={BgProfile} />
              <div className='mt-8'>
                <Paragraph className='[&>p]:mb-0 !mb-0'>{sanitizedHtml}</Paragraph>
              </div>
            </>
          )}
        </>
      </Col>
      <Col span={isDrawer ? 24 : 5}>
        <PrimaryCard>
          <Paragraph className='[&>p]:mb-0 !mb-0' strong={true}>
            <StarTwoTone twoToneColor='#41308f' className='mr-1' />
            <FormattedMessage id='participants' />
          </Paragraph>
          <Divider />
          {isLoading ? (
            <SpinWrapper />
          ) : (
            <Flex className='w-full' vertical={true}>
              <Flex>
                {data?.data.length > 0 && [
                  ...data?.data
                    .filter((i) => i.type === 1)
                    .slice(0, 3)
                    .map(({ fullname, photoUrl }, index) => (
                      <div key={index} className={`${index !== 0 && '-ml-4'}`}>
                        <Tooltip title={fullname} placement='top'>
                          <Image
                            width={40}
                            height={40}
                            className='object-cover rounded-[8px]'
                            src={photoUrl ? photoUrl : defaultProfilePhoto}
                            preview={false}
                          />
                        </Tooltip>
                      </div>
                    )),
                  data?.data?.filter((i) => i.type === 1).length > 3 && (
                    <div key='avatar' className='-ml-4'>
                      <Avatar
                        onClick={() => setParticipantDrawer(true)}
                        size='large'
                        shape='square'
                        className='rounded-[8px] cursor-pointer'
                        style={{
                          backgroundColor: '#403490',
                        }}
                      >
                        +{data?.data?.filter((i) => i.type === 1).length - 3}
                      </Avatar>
                    </div>
                  ),
                ]}
              </Flex>
              <Text strong={false} className='text-sm mt-2'>
                <FormattedMessage id='peopleParticipating' />:{' '}
                {data?.data?.filter((i) => i.type === 1).length}
              </Text>
              <div className='mt-4'>
                <Text className='block text-center w-full'>
                  <FormattedMessage id='participantStatus' />
                </Text>
                <Flex gap='1rem' align='center' justify='center' className='w-full mt-4'>
                  <Button
                    disabled={answerLoading}
                    onClick={() => eventParticipateStatus(1)}
                    icon={<CheckOutlined className='text-green-700' />}
                  />
                  <Button
                    disabled={answerLoading}
                    onClick={() => eventParticipateStatus(2)}
                    icon={<QuestionCircleOutlined className='text-yellow-500' />}
                  />
                  <Button
                    disabled={answerLoading}
                    onClick={() => eventParticipateStatus(3)}
                    icon={<CloseOutlined className='text-red-700' />}
                  />
                </Flex>
              </div>
            </Flex>
          )}
        </PrimaryCard>
      </Col>
      <Drawer
        styles={{
          content: {
            background: theme ? '#2A2A2A' : 'white',
          },
          header: { background: theme ? '#2A2A2A' : 'white' },
        }}
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        width={width < 480 ? '100vw' : 360}
        destroyOnClose={true}
        onClose={() => setParticipantDrawer(false)}
        open={participantDrawer}
        placement={width < 480 ? 'bottom' : 'right'}
        title={translator.formatMessage({ id: 'participants' })}
      >
        <ListUsers isInfinite={false} list={data?.data} />
      </Drawer>
    </Row>
  );
};

export default EventDetail;
