import './MentionList.scss'

import React, {
  forwardRef, useEffect, useImperativeHandle,
  useState
} from 'react'
import Icon, {
  StarTwoTone,
  HighlightOutlined,
  ItalicOutlined,
  LeftSquareOutlined,
  OrderedListOutlined,
  RedoOutlined,
  RightSquareOutlined,
  StrikethroughOutlined,
  UndoOutlined,
  UnorderedListOutlined,
  ShopTwoTone,
} from '@ant-design/icons';

const MentionList = forwardRef((props, ref) => {
    
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = index => {
    const item = props.items[index]

    if (item) {
      props.command({ id: {...item} })
    }
  }

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  return (
    <div className="items">
      {props.items.length
        ? props.items.map((item, index) => (
          <button
            className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.name} - {item.type === 1 ? <StarTwoTone twoToneColor='#41308f' className='mt-0.5'/> : <ShopTwoTone twoToneColor='41308f' className='mt-0.5'/> }
          </button>
        ))
        : <div className="item">No result</div>
      }
    </div>
  )
})

MentionList.displayName = 'MentionList';

export default MentionList;

