import { useNavigate, useParams } from 'react-router-dom';
import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useContext, useState } from 'react';
import { UtilityContext } from '../providers/UtilityProvider';
import { passwordRegex } from '../helpers/constants/constantHelpers';
import { useChangeUserPassword } from '../hooks/queries/UserQueries';

const { Title, Text } = Typography;

const ResetPassword = () => {
  const { mutateAsync } = useChangeUserPassword();
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordForm] = Form.useForm();
  const { openNotification } = useContext(UtilityContext);

  const { userKey } = useParams();
  const translator = useIntl();
  const navigate = useNavigate();

  const resetPasswordFormHandler = (formValues) => {
    setIsLoading(true);

    if (!passwordRegex.test(formValues['new_password'])) {
      return openNotification('passwordRuleMustMatch', 'error');
    }
    if (formValues['new_password'] !== formValues['confirm_new_password']) {
      return openNotification('noMatchedPassword', 'error');
    }

    formValues['change_token'] = userKey;

    mutateAsync(formValues)
      .then((response) => {
        if (response.status === 202) {
          openNotification('passwordChangedDirecting', 'success');
          navigate('/');
        } else {
          openNotification('unknownError', 'error');
        }
      })
      .catch((err) => openNotification('unknownError', 'error'))
      .finally(() => setIsLoading(false));
  };

  const validatePassword = (_, value) => {
    if (!passwordRegex.test(value)) {
      return Promise.reject(
        translator.formatMessage({ id: 'passwordNotValid'})
      );
    }
    return Promise.resolve();
  };

  return (
    <Form
      className='mx-auto mt-16 max-w-[400px]'
      layout='vertical'
      form={resetPasswordForm}
      onFinish={resetPasswordFormHandler}
    >
      <Title className='text-center' level={2}>
        <FormattedMessage id='resetPassword' />
      </Title>
      <Form.Item
        rules={[
          { required: true, message: translator.formatMessage({ id: 'pleaseEnterPassword' }) },
          { validator: validatePassword },
        ]}
        name='new_password'
        label={translator.formatMessage({ id: 'newPassword' })}
      >
        <Input.Password placeholder={translator.formatMessage({ id: 'password' })} />
      </Form.Item>
      <Form.Item
        rules={[
          { required: true, message: translator.formatMessage({ id: 'pleaseEnterPassword' }) },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(translator.formatMessage({ id: 'noMatchedPassword' }));
            },
          }),
        ]}
        name='confirm_new_password'
        label={translator.formatMessage({ id: 'newPasswordConfirm' })}
      >
        <Input.Password placeholder={translator.formatMessage({ id: 'password' })} />
      </Form.Item>
      <Button
        className='mt-2'
        htmlType='submit'
        type='primary'
        loading={isLoading}
        icon={<SaveOutlined className='me-2' />}
      >
        <Text className='text-inherit'>
          <FormattedMessage id='save' />
        </Text>
      </Button>
    </Form>
  );
};

export default ResetPassword;
