import { Card, Row } from 'antd';

const PrimaryCard = ({ children, className, grid = true, bodyStyle }) => {
  return (
    <Card styles={{ body: bodyStyle }} className={`${className} border-none`}>
      <>{grid ? <Row gutter={8}>{children}</Row> : children}</>
    </Card>
  );
};

export default PrimaryCard;
