import { Button, Col, Flex, Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { LanguageSelector } from '../form/filters/Inputs';
import { setUserLanguage } from '../../requests/UserManager';
import { useClientError } from '../../hooks/useClientError';
import { UtilityContext } from '../../providers/UtilityProvider';
import { useContext, useEffect } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { useState } from 'react';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { MoonOutlined, SunOutlined } from '@ant-design/icons';

const { Text } = Typography;

const AccountSettings = () => {
  const onClientError = useClientError();
  const { openNotification, changeThemeHandler, theme } = useContext(UtilityContext);
  const { user } = useContext(AuthContext);
  const [lang, setLang] = useState(user?.language);

  useEffect(() => setLang(user?.language), [user]);

  const languageInformationHandler = (language) => {
    setUserLanguage(language)
      .then((response) => {
        if (response.status === 202) {
          setLang(language);
          localStorage.setItem('language', language);
          location.reload(true);
        } else {
          openNotification('infoSaveError', 'error');
        }
      })
      .catch((err) => {
        onClientError(err);
      });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Text className='text-lg ml-1'>
          <FormattedMessage id='languagePreference' />
        </Text>
        <LanguageSelector onLanguageChange={languageInformationHandler} value={lang} />
      </Col>
      <Col span={24}>
        <Text className='text-lg ml-1'>
          <FormattedMessage id='theme' />
        </Text>
        <Flex gap='1rem'>
          <Button
            onClick={changeThemeHandler}
            type={!theme ? 'primary' : 'default'}
            icon={<SunOutlined />}
          />
          <Button
            onClick={changeThemeHandler}
            type={theme ? 'primary' : 'default'}
            icon={<MoonOutlined />}
          />
        </Flex>
      </Col>
    </Row>
  );
};

export default AccountSettings;
