import SteamRow from '../integrations/steam/SteamRow';
import {
  useGetLastSteamActivity,
  useGetMutualSteamGames,
  useGetUserSteamGenres,
  useGetUserSteamLibrary,
} from '../../hooks/queries/IntegrationQueries';
import { useEffect, useState } from 'react';
import { Divider, Flex } from 'antd';
import SteamGenreRow from '../integrations/steam/SteamGenreRow';
import { useIntl } from 'react-intl';

const UserGameStatistics = ({ userId, isOwner }) => {
  const { data, isLoading } = useGetUserSteamLibrary(userId);
  const { data: steamActivity = [], isLoading: steamActivityLoading } =
    useGetLastSteamActivity(userId);
  const { data: mutualGames = [], isLoading: mutualGamesLoading } = useGetMutualSteamGames(userId);
  const { data: userSteamGenres = [], isLoading: genresLoading } = useGetUserSteamGenres(userId);

  const translator = useIntl();

  useEffect(() => {
    if (data?.length > 0) {
      const groupedData = data.reduce((acc, game) => {
        game.steamapp.steamappgenres.forEach((genreInfo) => {
          const genreName = genreInfo.genre.name;
          if (!acc[genreName]) {
            acc[genreName] = [];
          }
          acc[genreName].push(game);
        });
        return acc;
      }, {});

      const newData = Object.keys(groupedData).map((genre) => ({
        genre,
        games: groupedData[genre],
      }));
    }
  }, [data]);

  return (
    <Flex vertical={true} gap='1rem'>
      <SteamGenreRow userId={userId} isLoading={genresLoading} steamGenres={userSteamGenres} />
      <Divider />
      <SteamRow
        title={translator.formatMessage({ id: 'lastPlayed' })}
        lastActivity={true}
        userId={userId}
        steamLibrary={steamActivity}
        isLoading={steamActivityLoading}
        isOwner={isOwner}
      />
      <Divider />
      <SteamRow userId={userId} steamLibrary={data} isLoading={isLoading} isOwner={isOwner} />
      <Divider />
      <>
        {!isOwner && (
          <SteamRow
            mutual={true}
            title={translator.formatMessage({ id: 'mutualGames' })}
            userId={userId}
            steamLibrary={mutualGames}
            isLoading={mutualGamesLoading}
            isOwner={isOwner}
          />
        )}
      </>
      {/*<GenreGroupedRow groupedData={genreGroupedLibrary} />*/}
    </Flex>
  );
};

export default UserGameStatistics;
