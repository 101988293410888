const FlipCard = ({ front, back, library = false }) => {
  if (library) {
    return (
      <div className='library-flip'>
        <div className='wrapper'>
          <div className='single-card'>
            <div className='front'>{front}</div>
            <div className='back'>
              <div className='content'>{back}</div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='wrapper'>
        <div className='single-card'>
          <div className='front'>{front}</div>
          <div className='back'>
            <div className='content'>{back}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default FlipCard;
