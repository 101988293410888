import { Col, Divider, Flex, Image, Row } from 'antd';
import { InlineEmojiInput } from '../form/filters/Inputs';
import { useContext } from 'react';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { AuthContext } from '../../providers/AuthProvider';
import { useGetComments } from '../../hooks/queries/PostActionQueries';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';

const EditComment = ({ margined = false, postDetails, closeModal}) => {
  const { user } = useContext(AuthContext);

  const profileAvatar = user?.userPhotos[0]?.profile_photo;

  const { isLoading } = useGetComments(postDetails?.id, 1, 10);
 
  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <Row style={{ marginTop: margined ? '1rem' : '0' }} gutter={[8, 0]}>
      <Divider />
      <Col span={24}>
        <Flex gap='1rem'>
          <Image
            className='rounded-[8px] object-cover'
            height={45}
            width={55}
            src={profileAvatar ? profileAvatar : defaultProfilePhoto}
            preview={false}
          />
          <InlineEmojiInput
            placeholder='commentTextareaPlaceHolder'
            toolbar={false}
            widthFull={true}
            commentId={postDetails?.id}
            defaultValue={postDetails?.content}
            closeModal={closeModal}
          />
        </Flex>
      </Col>
    </Row>
  );
};

export default EditComment;
