import React, { createContext, useState, useCallback } from 'react';
import useWindowSize from '../hooks/useWindowSize';

export const DrawerProviderContext = createContext();

export const DrawerProvider = ({ children }) => {
  const { width } = useWindowSize();

  const [drawerState, setDrawerState] = useState({
    open: false,
    title: '',
    content: null,
    onOk: () => {},
    onCancel: () => closeDrawer(),
    okText: 'OK',
    cancelText: 'Cancel',
    okButtonProps: {},
    cancelButtonProps: {},
    drawerProps: {},
    footer: null,
    destroyOnClose: true,
    placement: width < 480 ? 'bottom' : 'right',
  });

  const openDrawer = useCallback((config) => {
    setDrawerState({
      open: true,
      placement: width < 480 ? 'bottom' : 'right',
      destroyOnClose: true,
      onCancel: () => closeDrawer(),

      ...config,
    });
  }, []);

  const closeDrawer = useCallback(() => {
    setDrawerState({
      open: false,
      title: '',
      content: null,
      onOk: () => {},
      onCancel: () => closeDrawer(),
      okText: 'OK',
      cancelText: 'Cancel',
      okButtonProps: {},
      cancelButtonProps: {},
      drawerProps: {},
      footer: null,
      destroyOnClose: true,
      placement: width < 480 ? 'bottom' : 'right',
    });
  }, []);

  return (
    <DrawerProviderContext.Provider value={{ drawerState, openDrawer, closeDrawer }}>
      {children}
    </DrawerProviderContext.Provider>
  );
};

export default DrawerProvider;
