import axiosInstance from '../lib/service/axios';

export const createPost = async (content, feedAttachments, relatedFeedId) => {
  let formObject = {
    action: 'post',
    feedPayload: {
      content: content,
      feedAttachments: feedAttachments,
      typeId: 1,
      relatedFeedId: relatedFeedId,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const editPost = async (content, relatedFeedId) => {
  let formObject = {
    action: 'updateFeed',
    feedPayload: {
      content: content,
      typeId: 1,
      feedId: relatedFeedId,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const createOrganizationPost = async (content, feedAttachments, organizationId) => {
  let formObject = {
    action: 'post',
    feedPayload: {
      content: content,
      feedAttachments: feedAttachments,
      organizationId: organizationId,
      typeId: 1,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const createComment = async ({ content, attachments, relatedFeedId }) => {
  let formObject = {
    action: 'post',
    feedPayload: {
      content: content,
      feedAttachments: attachments,
      typeId: 2,
      relatedFeedId: relatedFeedId,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const editComment = async ({ content, relatedFeedId }) => {
  let formObject = {
    action: 'updateFeed',
    feedPayload: {
      content: content,
      typeId: 2,
      feedId: relatedFeedId,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getLikes = async (feedId, page) => {
  let formObject = {
    action: 'getLikes',
    getFeed: {
      feedId: feedId,
      page: page,
      limit: 20,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getFeed = async (page, userId, hashtag, feedType) => {
  let formObject = {
    action: 'getFeed',
    getFeed: {
      page: page,
      limit: 5,
      only_follow: feedType === 1,
    },
  };

  if (userId) {
    formObject.getFeed['userId'] = userId;
    formObject.getFeed['only_follow'] = false;
  }

  if (hashtag) {
    formObject = {
      action: 'getFeed',
      getFeed: {
        page: page,
        only_follow: false,
        search_keys: hashtag.replaceAll('$', '#').split(' '),
      },
    };
  }

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getOrganizationFeed = async (page, organizationId) => {
  let formObject = {
    action: 'getFeed',
    getFeed: {
      page: page,
      limit: 5,
      organizationId: organizationId,
      only_follow: false,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getComments = async (feedId, page, limit) => {
  let formObject = {
    action: 'getFeed',
    getFeed: {
      feedId: feedId,
      page: page,
      limit: limit,
      only_follow: false,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const likeAction = async (id) => {
  let formObject = {
    action: 'like',
    id: {
      id: id,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getFeedDetails = async (id, share) => {
  let formObject = {
    action: 'getOneFeed',
    getOneFeed: {
      id: id,
      share: share,
    },
  };
  const response = await axiosInstance.post('handle', formObject);
  return response.data.data;
};

export const deleteFeedMethod = async (id) => {
  let formObject = {
    action: 'deleteFeed',
    id: {
      id: id,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};
