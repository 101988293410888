import { useContext } from 'react';
import styles from '../style/layout.module.scss';
import { AuthContext } from '../providers/AuthProvider';
import { useEffect } from 'react';
const UserLayout = ({ children }) => {
  const { hasUnreadMessages } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    const currentDocumentTitle = document.title;
    if (hasUnreadMessages.length > 0 && user !== null) {
      document.title = `(1) ${currentDocumentTitle}`;
    } else {
      document.title = 'Game Actor';
    }
  }, [hasUnreadMessages, user]);

  return <div className={styles['welcomeLayout']}>{children}</div>;
};

export default UserLayout;
