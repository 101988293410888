import NotificationItem from './NotificationItem';
import styles from '../../style/notifications.module.scss';

const Notifications = ({ notificationList }) => {
  return (
    <div className={`${styles['notificationsWrapper']} `}>
      {notificationList.map((item, index) => (
        <NotificationItem isLast={notificationList.length === index + 1} key={index} item={item} />
      ))}
    </div>
  );
};

export default Notifications;
