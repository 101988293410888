import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: window.config.appBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

export const preRegisterAxiosInstance = axios.create({
  baseURL: window.config.preRegisterBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
});

preRegisterAxiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
//
// axiosInstance.interceptors.response.use((response) => response, (error) => {
//   if (error?.response?.status === 401 && window.location.pathname !== '/') {
//     window.location = '/';
//   }
//   return Promise.reject(error)
// });

export default axiosInstance;
