import React, { Fragment, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetConnectionDetails } from '../../hooks/queries/UserQueries';
import { useInView } from 'react-intersection-observer';
import { Image, Flex, Typography } from 'antd';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import styles from '../../style/profile.module.scss';
import { Link } from 'react-router-dom';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';

const { Text } = Typography;

const UserConnections = ({ isOwner, connections, profileId, connectionRemoveHandler }) => {
  const { data, fetchNextPage } = useGetConnectionDetails(connections, profileId);
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <Flex vertical className={styles['connectionLabel']}>
      <>
        {data?.pages?.map((page, pageIndex) => (
          <Fragment key={pageIndex}>
            {page?.followDetails?.map((connection, index) => {
              return (
                <Flex
                  ref={ref}
                  justify='space-between'
                  className={styles['connectionWrapper']}
                  key={index}
                >
                  <Link to={`/profile/${connection.username}`}>
                    <Flex>
                      <Image
                        className='rounded-[8px] object-cover'
                        width={55}
                        height={45}
                        preview={false}
                        src={connection.photoUrl ? connection.photoUrl : defaultProfilePhoto}
                      />
                      <div className={styles['connectionNameWrapper']}>
                        <Text
                          className={`${styles['connectionName']} dark:text-dark-mode-text-color`}
                        >
                          {connection.fullname}
                        </Text>
                        <span
                          className={`${styles['commonConnects']} dark:text-dark-mode-focused-primary-color`}
                        >
                          {connection.username}
                        </span>
                      </div>
                    </Flex>
                  </Link>
                  {isOwner && (
                    <div className={styles['connectControls']}>
                      <FontAwesomeIcon
                        onClick={() => connectionRemoveHandler(connection)}
                        className={styles['editIcon']}
                        icon={faMinus}
                      />
                    </div>
                  )}
                </Flex>
              );
            })}
          </Fragment>
        ))}
      </>
    </Flex>
  );
};

export default UserConnections;
