import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createComment,
  getComments,
  getFeed,
  getLikes,
  likeAction,
  editComment,
} from '../../requests/FeedManager';

export const useGetReplies = (detailId, page, limit) => {
  return useQuery({
    queryKey: ['getReplyHandler', detailId, page, limit],
    queryFn: () => getComments(detailId, page, limit),
    keepPreviousData: true,
    retry: 2,
  });
};

export const useCreateComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args) => createComment(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getCommentHandler'],
      });
    },
  });
};

export const useCreateReply = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args) => createComment(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getReplyHandler'],
      });
    },
  });
};

export const useEditComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args) => editComment(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getCommentHandler'],
      });
      queryClient.invalidateQueries({
        queryKey: ['getReplyHandler'],
      });
    },
  });
};

export const useGetComments = (postId, page, limit) => {
  return useQuery({
    queryKey: ['getCommentHandler', postId],
    queryFn: () => getComments(postId, page, limit),
    retry: 2,
  });
};

export const useGetLikes = (feedId, userId) => {
  return useInfiniteQuery({
    queryKey: ['getLikes', feedId],
    queryFn: ({ pageParam = 1 }) => getLikes(feedId, pageParam),
    getNextPageParam: (res) => {
      return res.page + 1;
    },
    retry: 2,
    enabled: !!userId,
  });
};

export const useLikeHandler = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (postId) => likeAction(postId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getLikes'],
      });
    },
  });
};
