import styles from '../../../style/chat.module.scss';
import { FormattedMessage } from 'react-intl';
import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import AnimateHeight from '../../utility/AnimateHeight';
import { useState, useContext, useEffect } from 'react';
import PartialMessage from './PartialMessage';
import ChatMessage from './ChatMessage';
import Toolbar from '../../utility/Toolbar';
import { useChatChannels } from '../../../hooks/queries/UserQueries';
import { AuthContext } from '../../../providers/AuthProvider';

const MessageBar = () => {
  const {
    chatOpen,
    activeChannel,
    activeOtherUser,
    openChat,
    closeChat,
    hasUnreadMessages,
    chatChannels = [],
  } = useContext(AuthContext);
  const [height, setHeight] = useState(40);
  const [chatChannelState, setChatChannelState] = useState(chatChannels);
  //const { data: chatChannels = [] } = useChatChannels();
  const maint = false;

  if (maint) {
    return <div>&nbsp;</div>;
  }

  useEffect(() => {
    setChatChannelState(chatChannels);
  }, [chatChannels]);

  const updateMessageList = (updatedChannel, contentMessage) => {
    const updatedChannels = chatChannels.map((channel) => {
      if (channel?.id === updatedChannel?.id) {
        return {
          ...channel,
          chatmessages: { ...channel.chatmessages, contentMessage: contentMessage },
        };
      }
      return channel;
    });

    const sortedChannels = updatedChannels.sort((x, y) =>
      x.id === updatedChannel?.id ? -1 : y.id === updatedChannel?.id ? 1 : 0,
    );

    setChatChannelState(sortedChannels);
  };

  chatChannelState.sort(
    (a, b) => new Date(b?.chatmessages?.updated_at) - new Date(a?.chatmessages?.updated_at),
  );
  return (
    <AnimateHeight
      className={`${styles['chatBox']} ${
        hasUnreadMessages?.length > 0 && height === 40 && '!bg-notification'
      } bg-white dark:bg-[#242424]`}
      duration={500}
      height={height}
    >
      <div onClick={() => setHeight(height === 40 ? 400 : 40)} className={styles['chatHeader']}>
        <FormattedMessage id='messages' />
        {height === 40 ? <UpCircleOutlined /> : <DownCircleOutlined />}
      </div>
      <div className={styles['chatBody']}>
        {chatChannelState.length > 0 ? (
          chatChannelState.map((item, index) => (
            <PartialMessage
              unread={hasUnreadMessages.includes(item.id)}
              key={index}
              onClick={() => openChat(item)}
              channel={item}
            />
          ))
        ) : (
          <div className='item'>
            Bir mesajlaşma başlatmak için bağlantınızın profiline gidebilirsiniz!
          </div>
        )}
      </div>
      {chatOpen && (
        <ChatMessage
          updateMessageList={updateMessageList}
          chatChannelList={chatChannels}
          activeChannel={activeChannel}
          onClick={closeChat}
          otherUser={activeOtherUser}
        />
      )}
    </AnimateHeight>
  );
};

export default MessageBar;
